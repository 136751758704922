

import * as React from "react";
import { useState } from "react";
// import { db } from "../firebase/firebase";

type UserContextType = {

  initState:any;
  initPath: string;
  isL:boolean;
  userP:string;
  userS:string

  user: any;
  setUser: (item:any) => void;

  path: string; 
  setPath: (item:string) => void;

  isLogged: boolean;
  setIsLogged: (item:any) => void;

  userShort: string;  
  setUserShort: (item:any) => void;

  pathUser: string;
  setPathUser: (item:string) => void;

  isLoading: boolean;
  setIsLoading: (item:any) => void;

  isError: any;
  setIsError: (item:any) => void;
 
  isSearch: boolean;
  setIsSearch: (item:boolean) => void;

  isTermsCond: boolean;
  setIsTermsCond: (item:boolean) => void;

  isTermsOfUse: boolean;
  setIsTermsOfUse: (item:boolean) => void;

  isPrivacy: boolean;
  setIsPrivacy: (item:boolean) => void;

  isSideBarRight: boolean;
  setIsSideBarRight: (item:boolean) => void;

  isSideBarLeft: boolean;
  setIsSideBarLeft: (item:boolean) => void;

  isTenancy: boolean;
  setIsTenancy: (item:boolean) => void;

};

const initVals = {
    initState: '',
    initPath: 'estate/' ,

    //  // TEST ORTAMI 1. NDA KULLANILACAK.
    // isL: true, 
    // isLogged: true,
    // userShort: 'test' ,
    // pathUser: 'estate/test/' ,

    // gerçek hayat
    isL: false, 
    isLogged: false,
    userShort: 'alka' ,
    pathUser: 'estate/alka/' ,
 

    user: {},
    path: 'estate/' ,
 
    isLoading: true,
    isError: false,

    isTermsCond: false,
    isTermsOfUse: false,
    isPrivacy: false,

    isSideBarRight: false,
    isSideBarLeft: false,

    isTenancy: false,

}

const UserContext = React.createContext<Partial<UserContextType>>(initVals);
// proProperty
type Props = {
  children: React.ReactNode;
};

export const UserProvider = ({ children }: Props) => {


  const initPath: string = 'estate/';
   

  // // TEST ORTAMI 2. NDA KULLANILACAK.
  // const [isLogged, setIsLogged] = useState(true);
  // const [pathUser, setPathUser] = useState('estate/test/');
  // const [userShort, setUserShort] = useState('test');


    // gerçek ortam ORTAMINDA KULLANILACAK.
    const [isLogged, setIsLogged] = useState(false);
    const [pathUser, setPathUser] = useState('estate/alka/');
    const [userShort, setUserShort] = useState('alka');

  const [path, setPath] = useState(initPath);
  const [user, setUser] = useState(null);  
  const [isLoading, setIsLoading] = useState(true); 
  const [isError, setIsError] = useState(false); 
  const [isSearch, setIsSearch] = useState(false);
  const [isTermsCond, setIsTermsCond] = useState(false);
  const [isTermsOfUse, setIsTermsOfUse] = useState(false);
  const [isPrivacy, setIsPrivacy] = useState(false);
  const [isSideBarRight, setIsSideBarRight] = useState(false);
  const [isSideBarLeft, setIsSideBarLeft] = useState(false);
  const [isTenancy, setIsTenancy] = useState(false);

  return (
    <UserContext.Provider value={{ user,  path, pathUser, isLogged,  isError,userShort, isLoading, isSearch , 
        setUser, setPath, setPathUser, setIsLogged, setIsLoading, setIsError,  setUserShort , setIsSearch,
        isTermsCond, setIsTermsCond, isTermsOfUse, setIsTermsOfUse, isPrivacy, setIsPrivacy,
        isSideBarRight, setIsSideBarRight, isSideBarLeft, setIsSideBarLeft, isTenancy, setIsTenancy}}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => React.useContext(UserContext);
