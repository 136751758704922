import React from 'react';
import { IonContent,  IonPage,  IonCard, IonCardHeader, 
   IonCardTitle, IonCardContent,  IonSlides, IonSlide } from '@ionic/react';
// import ExploreContainer from '../components/ExploreContainer';
import './Tab3.css';
import {  RouteComponentProps } from 'react-router-dom';
import HeaderAll from './estate/HeaderAll';
// import FooterAll from './estate/FooterAll';
// import { Geolocation} from '@capacitor/core';




const ContactUs: React.FC<RouteComponentProps> = ({match, history}) => {


  // let latitude: number = 395174;
  // let longitude: number = 7920743;

  const sliderOpts = {
    zoom: {
      maxRatio: 4
    }
  }

  const goToPage = (e: any, urlPath: any) => {
    e.preventDefault(); 
    history.push(urlPath);
  }



  return (
    <IonPage>

<HeaderAll goToPage={goToPage}/>
      <IonContent>


      <IonCard>
          <IonCardHeader>
          <IonCardTitle><h1> İletişim: alkaemlak.com </h1></IonCardTitle><br/>
          <IonCardTitle>Alka Emlak - Erdek </IonCardTitle><br/>
    
            <IonCardTitle>Adres: Yalı Mahallesi Okul Cad. no:29 Erdek</IonCardTitle>
            <IonCardTitle> Tel: +90 266 845 63 70</IonCardTitle>
            <IonCardTitle>Gsm: +90 532 661 77 54</IonCardTitle>
            <IonCardTitle>adumrul_59@hotmail.com</IonCardTitle>      

          </IonCardHeader>

          <IonCardContent>

<br/>
     <h2>
Alka Emlak olarak Erdek ve civarı emlak alım satım, Gayrimenkul danışmanlığı hizmetleri vermekteyiz. 
</h2>
<br/>
<h2>
Erdek ve civarı satılık daire,satılık yazlık,satılık işyeri,satılık arsa,satılık arazi arıyorsanız sizi de bekliyoruz.

{/* Alka Emlak İletişim Bilgileri
Adres: Yalı Mahallesi Okul Cad. no:29 Erdek

Tel: +90 266 845 63 70

Gsm: +90 532 661 77 54

adumrul_59@hotmail.com */}

</h2>
<br/>
<h3> 

Alka Emlak Erdek rehberde yer almaktadır.
 </h3><br/>

      </IonCardContent>

        </IonCard>
        <IonContent>

   
 <IonSlides options={sliderOpts}> 
 <IonSlide>
   

<img className="harita" src='assets/imgs/alkaemlakharita.png' alt="alkaemlak.com erdek harita"></img>



   
   </IonSlide>



 </IonSlides>

                   {/* <ExploreContainer name="Alka Emlak İletişim Bilgileri" /> */}


                   </IonContent>
      
        
      </IonContent>
      {/* <FooterAll goToPage={goToPage}/> */}
    </IonPage>
  );
};
// 40.395174,27.7920743,17
export default ContactUs;