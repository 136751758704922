

import * as firebase from 'firebase'
import 'firebase/storage'
// import admin from 'firebase-admin';

var firebaseConfig = {
    apiKey: "AIzaSyCPRr-rMTZMPVplsEAv3AhqLKRcdAvl84A",
    authDomain: "mbadicisendmail-1536307520002.firebaseapp.com",
    databaseURL: "https://mbadicisendmail-1536307520002.firebaseio.com",
    projectId: "mbadicisendmail-1536307520002",
    storageBucket: "mbadicisendmail-1536307520002.appspot.com",
    messagingSenderId: "720509030502",
    appId: "1:720509030502:web:44fd4c2ceab537a0bb5da7",
    measurementId: "G-KYWZVQ9MV9"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  const storage = firebase.storage()
  const db = firebase.database()

  export  {
    storage, db, firebase as default
  }

  export async function loginUser(username, password){
    const email = username;
    try {
      const res = await firebase.auth().signInWithEmailAndPassword(email, password)

      // const res2 = deepEntries(res);
      // const res3 = stringifyEntries(res2);
      // console.log(res2)
      // iterate(res, '')
      // flatten(res, null,null)
      // alert(labels)
      return res      
    } catch (error) {
      console.log(error)
      return null
    }
  }

  // export async function loginUser2(username){
  //   const email = username;
  //   try {
  //     const auth = admin.auth();
  //     const getAllUsers = (req, res) => {
  //       const maxResults = 1; // optional arg.
      
  //       auth.listUsers(maxResults).then((userRecords) => {
  //         userRecords.users.forEach((user) => console.log(user.toJSON()));
  //         res.end('Retrieved users list successfully.');
  //       }).catch((error) => console.log(error));
  //     };
  //     return null;      
  //   } catch (error) {
  //     console.log(error)
  //     return null
  //   }
  // }
