import React, {useState} from 'react';
import { IonContent,  IonPage,   IonButton, IonSegment, IonSegmentButton, IonCard, 
  IonCardHeader, IonItem, IonCardTitle, IonIcon, IonInput, IonCardContent, IonLabel } from '@ionic/react';
// import ExploreContainer from '../components/ExploreContainer';
import './Tab3.css';
import {  db } from '../firebase/firebase';
import Sayfa2 from './estate/deneme/Sayfa2';
import Sayfa1 from './estate/deneme/Sayfa1';
// import Sayfa3 from './estate/deneme/Sayfa3';
import Sayfa4Yetki from './estate/deneme/Sayfa4Yetki';
import Sayfa5Vaad from './estate/deneme/Sayfa5Vaad';
import { useTest } from '../context/TestContext';
import HeaderToolBar from './estate/HeaderToolBar';
import Upload from './estate/Upload';
import { initLocal, readFirebase } from '../utils';
import { toasterMsg } from './estate/toast/toasterMsg';
import { search,  pencilOutline, imageOutline, cameraOutline, walk, wifi, closeCircleSharp, closeCircleOutline, 
  addCircleOutline } from 'ionicons/icons';
// import { Redirect } from 'react-router';
import { useUser } from '../context/UserContext';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import './SegmentStyle.css';
import Tab2 from './Tab2Camera';

const Tab3GirisMenu: React.FC = () => {

  const {isLogged, pathUser} = useUser();
  var el: any = null;

  const { setIsLoading, setIsError,
     setParDict, parDict, dividePars, 
    setPropid,setSiraNo, siraNo, propDetay, setMsg } = useTest()!;   

    const [ekran, setEkran] = useState('1a');

    const { executeRecaptcha } = useGoogleReCaptcha();
    const [token, setToken] = React.useState("");
    const [prevControl, setPrevControl] = React.useState(el);
  
    function addClass(elem: any, className: any) {
      // if (!hasClass(elem, className)) {
          elem.className += ' ' + className;
      // }
  }
  
    const ekranSec = (e: any) => {
      e.preventDefault();
      e.stopPropagation(); 
      // let checkText = e.currentTarget as HTMLElement;
      // console.log(e.currentTarget.id);
      e.currentTarget.className =  e.currentTarget.className.replace(" active", "");
      e.currentTarget.className += " active";
      if (prevControl !== null && prevControl !== undefined ) {
        prevControl.className =  prevControl.className.replace(" active", "");
      }
      setPrevControl(e.currentTarget);
      // addClass(e, 'active');
                  var result = e.currentTarget.id;   
                  if (result==='1a' ||  result==='2a' ||  result==='3a' ||  result==='4a' ||  result==='5a' ||  result==='6a') setEkran(result);
    };

  const Sayfalar2 = (props:any) => {
    // console.log('props :  '+props.pageName+ ' is logged : '+props.isLogged);
    if (props.pageName==='2a') return  <Sayfa1/>
    else if (props.pageName==='1a' && props.isLogged) return <Sayfa2/>
    else if (props.pageName==='3a' && props.isLogged) return <Upload/>
    else if (props.pageName==='4a' && props.isLogged) return <Sayfa4Yetki/>
    else if (props.pageName==='5a' && props.isLogged) return <Sayfa5Vaad/>
    else if (props.pageName==='6a' && props.isLogged) return <Tab2/>
    else {
      // console.log(' tab3 griş else functions: '+props.pageName);
      return null;  
    }  
  };

  const inputChanged = (e:any) => {
    e.preventDefault();
    e.stopPropagation(); 
    let checkText = (e.target as HTMLInputElement).value;
    // console.log('inputChanged '+(e.target as HTMLInputElement).id +  ' : ' +checkText);
    localStorage.setItem((e.target as HTMLInputElement).id,checkText); 
    localStorage.setItem('ysirano',checkText); 
    localStorage.setItem('vsirano',checkText); 
    setSiraNo!(checkText);
    setPropid!(checkText);
    toasterMsg('Seçili sirano ..'+checkText,'primary',10);
}

const emlakGir = (e:any) => {
    e.preventDefault();
    e.stopPropagation(); 
    // console.log(' emlakGir sira no BLUR ÇALIŞTI MI??? '+siraNo);
    // console.log(' getir ');
    let siraNum = localStorage.getItem('siraNo');
    var data: any;
    propDetay.map((item: any) => {
        data = item['detail'][siraNum+''];
    }  ) 
    if (data === undefined || data === null ) {
        toasterMsg('Emlak verileri gelmedi..','danger',10);
        return;
    }
    let myKeys = Object.keys(data);
    if (myKeys === undefined || myKeys === null ) {
        toasterMsg('Emlak verileri gelmedi..','danger',10);
        return;
    }
    myKeys.map((element:any) => {
        // console.log('propDetayGetir ************** '+element+ ' : '+data[element]);
        Object.keys(data[element]).map((item:any)=>{
            
            if (item==='muhit' || item==='ulasim'  || item==='manzara' ||  item==='disOzellik' ||  item==='icOzellik'  ||  item==='engelliiyeUygun' ) {
                console.log(item+ ' : çoklu seçim : '+data[element][item]);
            } else {
                console.log(item+ ' : '+data[element][item]);
            }
            localStorage.setItem(item,data[element][item]);
        });       
    });    
}

const emlakSil = (e:any) => {
    e.preventDefault();
    e.stopPropagation(); 
    // console.log(' emlakGir sira no '+siraNo);
    // console.log(' emlak sil ');
    setEkran('2a');
}

const yeniKayitAc = () => {

    // grecaptcha.ready(function() {
    //   grecaptcha.execute('6Le6uM8ZAAAAANPDMCqCs82fHfWYOU2jYmSYqUSE', {action: 'submit'}).then(function(token) {
         
      initLocal();        
      const result = readFirebase(pathUser,setPropid,setSiraNo);
      // console.log('yeni kayıt açıldı : '+result);
      setMsg!('Yeni kayıt açıldı');
      toasterMsg('Yeni Kayıt Açıldı. Detay gir. Kaydet. Resim ekle.','primary',10);
      setEkran('2a');
    //   });
    // });
  }


      React.useEffect(() => { // param listesi gelir
        const fetchData = async () => {
                setIsLoading!(true);  
                // if (propid!==0) setSiraNo(propid);               
                try {
                  let size:any = Object.keys(parDict);
                  if (size.length<2) {
                    //console.log('parDict.length '+size.length);
                    const ref = db.ref(pathUser+"json/");
                    ref.once("value", function(data) {
                        let result = data.val();
                        let myDict = [{}];
                        if (result !== undefined && result !== null){
                            //console.log(JSON.stringify(result));
                            let objKeys = Object.keys(result);
                            objKeys.map(item=>{
                              let myItem: any = item;
                                myDict[myItem] = result[myItem];
                                // console.log(' par NEW STATE WORKİNG: '+item+' '+JSON.stringify(result[item]));
                            })
                            setParDict!(myDict);
                            dividePars!(myDict);
                        }
                        setIsError!(false);                  
                    })
                  }
                } catch (error) {
                  setIsError!(true);
                }       
          setIsLoading!(false);
        }; 
          // // init local storage
          // // initLocal();
          // console.log(' par started *********** '+new Date());
          if (isLogged) fetchData();  
          // console.log(' par ENDED *********** '+new Date()); 
          // // propGetir();                
      }, []);

      const clickHandler = async () => {
        if (!executeRecaptcha) {
          return;
        }
    
        const result = await executeRecaptcha("Upload");
    
        setToken(result);
      };

      // <ion-segment class="custom-icon" value="instagram">
      // <ion-segment-button class="segment-facebook" value="facebook" layout="icon-bottom">
        // <ion-label>Facebook</ion-label>
        // <ion-icon name="logo-facebook"></ion-icon>
      // </ion-segment-button>onIonChange={e => ekranSec(e)}
    
  return (

        <IonPage>
        <HeaderToolBar mainTitle={'Emlak'}></HeaderToolBar>
          <IonContent key="contenetpage"  className="parent-border-radius"  >
          <IonSegment value="hepsisegment1" key="ionseg"    >

{/* 
          <IonSegment value="1a" onIonChange={e => ekranSec(e)}> <IonSegmentButton value="1a"  >   GİRİŞ    </IonSegmentButton> </IonSegment>  
                <IonSegment value="2a" onIonChange={e => ekranSec(e)}> <IonSegmentButton value="2a"  >   <IonIcon name={add} />    </IonSegmentButton> </IonSegment>
                <IonSegment value="3a" onIonChange={e => ekranSec(e)}><IonSegmentButton value="3a" >  RESİM    </IonSegmentButton> </IonSegment>
                <IonSegment value="4a" onIonChange={e => ekranSec(e)}> <IonSegmentButton value="4a" >  YETKİ   </IonSegmentButton>   </IonSegment>
                <IonSegment value="5a" onIonChange={e => ekranSec(e)}> <IonSegmentButton value="5a" >   VAAD   </IonSegmentButton>  </IonSegment> */}

            
          <IonSegmentButton id="1a"  onClick={e => ekranSec(e)} >   <IonLabel> MENÜ    </IonLabel> </IonSegmentButton> 
                <IonSegmentButton id="2a"   onClick={e => ekranSec(e)}  > <IonLabel> YENİ  </IonLabel>  </IonSegmentButton> 
               <IonSegmentButton id="3a"   onClick={e => ekranSec(e)} > <IonLabel> RESİM    </IonLabel> </IonSegmentButton>
            <IonSegmentButton id="4a"   onClick={e => ekranSec(e)} > <IonLabel> YETKİ   </IonLabel> </IonSegmentButton>  
             <IonSegmentButton id="5a"  onClick={e => ekranSec(e)}  > <IonLabel>  VAAD   </IonLabel> </IonSegmentButton>  
             <IonSegmentButton id="6a"  onClick={e => ekranSec(e)}  > <IonLabel>  KAMERA   </IonLabel> </IonSegmentButton>  




        </IonSegment>

        {(ekran >= "2a") ? 
        <Sayfalar2 
        pageName={ekran} 
        isLogged={isLogged}
        setEkran={setEkran}>

        </Sayfalar2> : 
        (

                   
         <IonCard>
         <IonCardHeader>
           {/* <IonCardSubtitle>EMLAK İŞLEMLERİ PANELİ</IonCardSubtitle> */}
           <IonCardTitle>EMLAK İŞLEMLERİ PANELİ</IonCardTitle>
         </IonCardHeader>

         <IonItem>
         <IonIcon icon={search} slot="start" />
           <IonInput className="txtemlakno" id="siraNo" value={siraNo} 
           onIonChange={inputChanged} onBlur={emlakGir} />
           {/* <IonButton fill="solid"  size="default" onClick={emlakGir}
           disabled={(siraNo === 0)}           >
           <IonIcon icon={searchCircle} slot="start" />
           EMLAK GETİR</IonButton>          */}
         </IonItem>

         <IonItem>
           <IonIcon icon={pencilOutline} slot="start" />
           <IonButton fill="solid"  size="default"
             disabled={(siraNo === 0)}      onClick={()=> setEkran('2a')}         >
           <IonIcon icon={pencilOutline} slot="start" />
               SEÇİLİ EMLAK GÜNCELLENSİN</IonButton>
         </IonItem>
         <IonItem>
           <IonIcon icon={imageOutline} slot="start" />
           <IonButton fill="solid"  size="default"
             disabled={(siraNo === 0)}  onClick={()=> setEkran('3a')}    >
           <IonIcon icon={imageOutline} slot="start" />
               SEÇİLİ EMLAK RESİM EKLE</IonButton> 
         </IonItem>
         <IonItem>
           <IonIcon icon={cameraOutline} slot="start" />
           <IonButton fill="solid" size="default"
             disabled={(siraNo === 0)}       >
           <IonIcon icon={cameraOutline} slot="start" />
               SEÇİLİ EMLAK KAMERA</IonButton>  
         </IonItem>
         <IonItem>
           <IonIcon icon={walk} slot="start" />    
           <IonButton fill="solid"  size="default"
             disabled={(siraNo === 0)}           >
           <IonIcon icon={walk} slot="start" />  
               SATIŞ VAADİ</IonButton>
         </IonItem>

         <IonItem>
           <IonIcon icon={wifi} slot="start" />
           <IonButton   expand="full" fill="solid"  
           size="default"  disabled={(siraNo === 0)}           >
           <IonIcon icon={wifi} slot="start" />
               YETKİ BELGESİ</IonButton>      
         </IonItem>

         <IonItem>
           <IonIcon icon={closeCircleSharp} slot="start" />
           <IonButton  onClick={emlakSil} expand="full" 
           fill="solid"  size="default"  
           disabled={(siraNo === 0)}           >
           <IonIcon icon={closeCircleOutline} slot="start" />
              EMLAK SİL</IonButton>      
         </IonItem>


         <IonItem>
           <IonIcon icon={addCircleOutline} slot="start" />
           <IonButton  onClick={yeniKayitAc} fill="solid" 
           size="default" disabled={(siraNo !== 0)}           >
             YENİ EMLAK EKLEME
           <IonIcon icon={addCircleOutline} slot="start" />
           
           </IonButton>      
         </IonItem>

         <IonCardContent>
           Bu ekrandan emlak güncelleme işlemlerini yapabilirsiniz. 
           Yeni bir emlak giriş, girilen emlak güncellemesi,
           emlak için resim ekleme, kamera ile çekilen resimlerin anında eklenmesi,
           eklenen resimleri silme bu ekrandan yapılabilir.
        </IonCardContent>
         {/*  <div>
            <button onClick={clickHandler}>Test Recaptcha</button>
            {token && <p>Token: {token}</p>}
          </div>
          */}
        <IonButton routerLink="/">Ana Sayfa</IonButton> 

       </IonCard>

         

          
        )}   
 
      </IonContent>
      </IonPage>
  );
};

export default Tab3GirisMenu;


// import * as React from "react";
// import { useGoogleReCaptcha } from "../src/use-google-recaptcha";

// export const GoogleRecaptchaExample: React.FunctionComponent = () => {
//   const { executeRecaptcha } = useGoogleReCaptcha();
//   const [token, setToken] = React.useState("");

  // const clickHandler = async () => {
  //   if (!executeRecaptcha) {
  //     return;
  //   }

  //   const result = await executeRecaptcha("homepage");

  //   setToken(result);
  // };

//   return (
    // <div>
    //   <button onClick={clickHandler}>Test Recaptcha</button>
    //   {token && <p>Token: {token}</p>}
    // </div>
//   );
// };