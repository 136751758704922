

import React, { Fragment } from 'react';
import {  RouteComponentProps } from 'react-router-dom';
import {   IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonPage } from '@ionic/react';
import { IonContent } from '@ionic/react';

// import HeaderToolBarPublic from '../HeaderToolBarPublic';
// import {  backspaceSharp } from 'ionicons/icons';

// import { useUser } from '../../../context/UserContext';
import HeaderAll from '../HeaderAll';
// import FooterAll from '../FooterAll';


const TermsOfUse: React.FC<RouteComponentProps> = ({match, history}) => {



  const siteName = "Alkaemlak.com";


const metin = () => {  return <Fragment>

<p> <b> Kullanım Şartları - Terms Of Use</b></p>
<p> <b> Sürüm 1.0. Son Güncelleme 01.10.2020</b></p>
<p>
{siteName+' '} adresinde bulunan {siteName+' '} sitesi web sitesi, {siteName+' a  '}ait telif hakkı olan bir eserdir. 
Sitenin belirli özellikleri, bu özelliklerle bağlantılı olarak Sitede yayınlanacak olan ek kılavuzlara, 
şartlara veya kurallara tabi olabilir.
</p><p>
Bu tür tüm ek koşullar, yönergeler ve kurallar bu Koşullara referans olarak dahil edilmiştir.
</p><p>
Bu Kullanım Koşulları, Siteyi kullanımınızı denetleyen yasal olarak bağlayıcı hüküm ve koşulları açıklamaktadır. 
SİTEDE GİRİŞ YAPARAK, BU ŞARTLARA UYARSINIZ ve bu Koşullara girmek için yetki ve kapasiteye sahip olduğunuzu 
beyan edersiniz. SİTEYE ERİŞMEK İÇİN EN AZ 18 YAŞINDA OLMALISINIZ. 
BU ŞARTLARIN TÜM HÜKÜMLERİNİ KABUL ETMİYORSANIZ, SİTEYE GİRİŞ YAPMAYIN VE / VEYA SİTEYİ KULLANMAYIN.
</p><p>
Bu şartlar, anlaşmazlıkları çözmek için bireysel olarak tahkim Bölüm 10.2'nin kullanılmasını gerektirir ve 
ayrıca bir anlaşmazlık durumunda kullanabileceğiniz çözüm yollarını sınırlar. 
</p><p><b>

Siteye Erişim</b>
</p><p>
<b>Bu Koşullara tabidir.</b> Şirket, Siteye yalnızca kendi kişisel, ticari olmayan kullanımınız için erişmeniz için size 
devredilemez, münhasır olmayan, iptal edilebilir, sınırlı bir lisans verir.
</p><p>
<b>Belirli Kısıtlamalar. </b>Bu Koşullarda size onaylanan haklar aşağıdaki kısıtlamalara tabidir: <br/>
(a) Siteyi satmayacak, kiralayamayacak, kiralayamayacak, devretmeyecek, devretmeyecek, dağıtmayacak, barındırmayacak veya 
başka bir şekilde ticari olarak istismar etmeyeceksiniz; <br/>
(b) Sitenin herhangi bir bölümünü değiştirmeyecek, türemiş işler yapmayacak, sökmeyecek, ters derlemeyecek veya tersine 
mühendislik yapmayacaksınız; <br/>
(c) benzer veya rekabetçi bir web sitesi oluşturmak için Siteye erişmeyeceksiniz; ve <br/>
(d) burada açıkça belirtilmedikçe, Sitenin hiçbir bölümü, aksi belirtilmedikçe, herhangi bir biçimde veya herhangi 
bir yolla kopyalanamaz, çoğaltılamaz, dağıtılamaz, yeniden yayınlanamaz, indirilemez, görüntülenemez, postalanamaz veya iletilemez, 
gelecekteki herhangi bir sürüm, güncelleme veya Sitenin işlevselliğine diğer ilaveler bu Koşullara tabi olacaktır. 
Sitedeki tüm telif hakkı ve diğer mülkiyet bildirimleri, tüm kopyalarında saklanmalıdır.
</p><p>
Şirket size bildirimde bulunarak veya bulunmaksızın Siteyi değiştirme, askıya alma veya durdurma hakkını saklı tutar. 
Şirketin, Sitenin veya herhangi bir kısmının herhangi bir değişikliği, kesintisi veya feshi için size veya herhangi bir üçüncü 
tarafa karşı sorumlu tutulmayacağını onayladınız.
</p><p>
<b>Destek veya Bakım Yoktur.</b> Şirketin, Site ile bağlantılı olarak size herhangi bir destek sağlama yükümlülüğü olmayacağını 
kabul edersiniz.
</p><p>
Sağlayabileceğiniz herhangi bir Kullanıcı İçeriği hariç olmak üzere, Site ve içeriğindeki telif hakları, patentler, ticari markalar ve ticari sırlar dahil tüm fikri mülkiyet haklarının Şirket veya Şirket tedarikçilerine ait olduğunun farkındasınız. Bu Koşulların ve Siteye erişimin, Bölüm 2.1'de ifade edilen sınırlı erişim hakları haricinde, size herhangi bir fikri mülkiyet hakkı için herhangi bir hak, unvan veya menfaat sağlamadığını unutmayın. Şirket ve tedarikçileri, bu Koşullarda verilmeyen tüm hakları saklı tutar.
</p><p>
<b>Üçüncü Taraf Bağlantıları ve Reklamları; Diğer Kullanıcılar</b>
</p><p>
<b>Üçüncü Taraf Bağlantıları ve Reklamlar. </b>Site üçüncü şahıs web sitelerine ve hizmetlerine bağlantılar içerebilir ve / veya üçüncü şahıslar için reklamlar gösterebilir. Bu tür Üçüncü Taraf Bağlantıları ve Reklamları Şirketin kontrolü altında değildir ve Şirket, herhangi bir Üçüncü Taraf Bağlantıları ve Reklamlarından sorumlu değildir. Şirket, bu Üçüncü Taraf Bağlantılarına ve Reklamlarına yalnızca size kolaylık sağlamak amacıyla erişim sağlar ve Üçüncü Taraf Bağlantıları ve Reklamları ile ilgili herhangi bir beyanda bulunmaz, onaylamaz, izlemez, onaylamaz, garanti vermez veya beyanda bulunmaz. Tüm Üçüncü Taraf Bağlantılarını ve Reklamlarını riski size ait olmak üzere kullanırsınız ve bunu yaparken uygun düzeyde dikkat ve takdir yetkisi göstermelisiniz. Üçüncü Taraf Bağlantıları ve Reklamlarından herhangi birine tıkladığınızda, üçüncü tarafın gizliliği ve veri toplama uygulamaları dahil olmak üzere ilgili üçüncü taraf hüküm ve politikaları geçerlidir.
</p><p>
<b>Diğer Kullanıcılar.</b> Her Site kullanıcısı, kendi Kullanıcı İçeriğinin herhangi birinden veya tamamından yalnızca kendisi sorumludur. 
Kullanıcı İçeriğini kontrol etmediğimiz için, sizin tarafınızdan veya başkaları tarafından sağlanan herhangi bir Kullanıcı İçeriğinden sorumlu olmadığımızı 
kabul edersiniz. Bu tür etkileşimlerin sonucu olarak ortaya çıkan herhangi bir kayıp veya hasardan Şirketin sorumlu olmayacağını kabul edersiniz. 
Siz ve herhangi bir Site kullanıcısı arasında bir anlaşmazlık olması durumunda, dahil olma yükümlülüğümüz bulunmamaktadır.
</p><p>
Şirketi ve memurlarımızı, çalışanlarımızı, temsilcilerimizi, haleflerimizi ve devralanlarımızı, her bir geçmiş, mevcut ve gelecekteki anlaşmazlık, 
iddia, ihtilaf, talep, hak, yükümlülük, sorumluluk, Doğrudan veya dolaylı olarak Site'den doğan veya ortaya çıkan veya doğrudan veya dolaylı olarak 
Site ile ilgili olan her tür ve nitelikteki eylem ve eylem nedeni. Kaliforniya'da ikamet ediyorsanız, yukarıda belirtilenlerle bağlantılı 
olarak Kaliforniya medeni kanununun 1542 numaralı bölümünden feragat edersiniz: "Genel bir serbest bırakma, alacaklının bilmediği veya kendi 
lehine olduğundan şüphelendiği iddiaları kapsamaz. Eğer kendisi tarafından biliniyorsa, borçlu ile olan anlaşmasını maddi olarak etkilemiş 
olması gereken tahliyenin yerine getirilme süresi. "
</p><p>
<b>Tanımlama Bilgileri ve Web İşaretçileri. </b>
  Diğer tüm web siteleri gibi  {siteName+' '}  sitesi de "çerezler" kullanır. Bu çerezler, ziyaretçilerin tercihleri ​​ve ziyaretçinin eriştiği veya ziyaret ettiği web sitesindeki sayfalar dahil olmak üzere bilgileri depolamak için kullanılır. Bilgiler, web sayfası içeriğimizi ziyaretçilerin tarayıcı türüne ve / veya diğer bilgilere göre özelleştirerek kullanıcıların deneyimini optimize etmek için kullanılır.
  </p><p>
  <b>Google DoubleClick DART Çerezi. </b>
  Google, sitemizdeki üçüncü taraf satıcılardan biridir. Ayrıca, www.website.com ve internetteki diğer sitelere yaptıkları ziyaretlere dayalı olarak site ziyaretçilerimize reklam sunmak için DART çerezleri olarak bilinen çerezleri kullanır. Bununla birlikte, ziyaretçiler aşağıdaki URL'de Google reklam ve içerik ağı Gizlilik Politikası'nı ziyaret ederek DART çerezlerinin kullanımını reddetmeyi seçebilirler - https://policies.google.com/technologies/ads
  </p><p>
  <b>Sorumluluk Reddi Beyanları</b></p><p>
  Site, "olduğu gibi" ve "mevcut olduğu gibi" esasına göre sağlanmıştır ve şirket ve tedarikçilerimiz, tüm satılabilirlik garantileri veya koşulları dahil olmak üzere,
   açık, zımni veya yasal her türlü garantiyi ve koşulu açıkça reddeder. , belirli bir amaca uygunluk, unvan, sessiz eğlence, doğruluk veya ihlal etmeme. 
   Biz ve tedarikçilerimiz, sitenin gereksinimlerinizi karşılayacağını, kesintisiz, zamanında, güvenli veya hatasız olarak kullanılabilir olacağını veya doğru, 
   güvenilir, virüslerden veya diğer zararlı kodlardan arınmış, eksiksiz, yasal olacağını garanti etmiyoruz. veya güvenli. 
   Yürürlükteki yasalar siteyle ilgili herhangi bir garanti gerektiriyorsa, bu tür garantilerin tümü ilk kullanım tarihinden 
   itibaren doksan (90) gün ile sınırlıdır.
  </p><p>
  Bazı yargı bölgeleri zımni garantilerin hariç tutulmasına izin vermez, bu nedenle yukarıdaki hariç tutma sizin için geçerli olmayabilir. Bazı yargı bölgeleri, zımni garantinin ne kadar süreceği konusunda sınırlamalara izin vermez, bu nedenle yukarıdaki sınırlama sizin için geçerli olmayabilir.
  </p><p>
  <b>Sorumluluk Sınırlaması</b></p><p>
  Kanunun izin verdiği azami ölçüde, şirket veya tedarikçilerimiz hiçbir durumda size veya herhangi bir üçüncü şahsa karşı herhangi bir kar kaybı, kaybedilen veri,
   ikame ürünlerin tedarik masraflarından veya herhangi bir dolaylı, sonuçta ortaya çıkan, örnek teşkil eden, arızi, Bu şartlardan veya siteyi kullanımınızdan 
   kaynaklanan veya bunlarla ilgili olarak ortaya çıkan özel veya cezai zararlar veya bu tür zararların olasılığı şirkete bildirilmiş olsa bile siteyi kullanamamanız. 
   Siteye erişim ve sitenin kullanımı kendi takdirinize ve riskinize bağlıdır ve cihazınıza veya bilgisayar sisteminize gelebilecek her türlü hasardan veya 
   bunlardan kaynaklanan veri kaybından yalnızca siz sorumlu olacaksınız.
  </p><p>
  Yasaların izin verdiği azami ölçüde, burada yer alan aksine herhangi bir şeye bakılmaksızın, bu sözleşmeden kaynaklanan veya bu sözleşmeyle ilgili herhangi 
  bir zarar için size karşı sorumluluğumuz her zaman maksimum elli ABD doları (u.s. 50 $) ile sınırlı olacaktır. 
  Birden fazla iddianın varlığı bu sınırı genişletmeyecektir. Tedarikçilerimizin bu sözleşmeden kaynaklanan veya bu sözleşmeyle ilgili hiçbir 
  sorumluluğu olmayacağını kabul edersiniz.
  </p><p>
  Bazı yargı bölgeleri, tesadüfi veya sonuç olarak ortaya çıkan zararlar için sorumluluğun sınırlandırılmasına veya hariç tutulmasına izin vermez, 
  bu nedenle yukarıdaki sınırlama veya hariç tutma sizin için geçerli olmayabilir.
  </p><p>
  <b>Süre ve Fesih.</b> Bu Bölüme tabi olarak, Siteyi kullanırken bu Koşullar tam olarak yürürlükte kalacaktır. 
  Sitenin bu Koşulları ihlal eden herhangi bir şekilde kullanılması da dahil olmak üzere, tamamen kendi takdirimize bağlı olarak herhangi bir nedenle 
  Siteyi kullanma haklarınızı herhangi bir zamanda askıya alabilir veya feshedebiliriz. Bu Koşullar kapsamındaki haklarınızın feshedilmesi üzerine, 
  Hesabınız ve Siteye erişim ve kullanım hakkınız derhal sona erecektir. Hesabınızın herhangi bir şekilde feshedilmesinin, 
  Hesabınızla ilişkili Kullanıcı İçeriğinizin canlı veritabanlarımızdan silinmesini gerektirebileceğini anlıyorsunuz. 
  Şirket, bu Koşullar kapsamındaki haklarınızın herhangi bir şekilde sona ermesinden dolayı size karşı herhangi bir sorumluluk kabul etmeyecektir. 
  Bu Koşullar kapsamındaki haklarınız feshedildikten sonra bile, bu Koşulların aşağıdaki hükümleri yürürlükte kalacaktır: Bölüm 2 ila 2.5, Bölüm 3 ve 
  Bölüm 4 ila 10.
  </p><p>
  <b>Telif Hakkı Politikası.</b></p><p>
  Şirket, başkalarının fikri mülkiyet haklarına saygı duyar ve Sitemizin kullanıcılarının da aynısını yapmasını ister. Sitemiz ile bağlantılı olarak, 
  ihlal eden materyallerin kaldırılmasını ve telif hakları da dahil olmak üzere fikri mülkiyet haklarını tekrar tekrar ihlal eden çevrimiçi 
  Sitemizin kullanıcılarının feshini sağlayan telif hakkı yasasına saygı gösteren bir politika kabul ettik ve uyguladık. Kullanıcılarımızdan birinin, 
  Sitemizi kullanarak, bir eserdeki telif haklarını hukuka aykırı bir şekilde ihlal ettiğine inanıyorsanız ve hak ihlalinde bulunduğu iddia edilen 
  materyalin kaldırılmasını istiyorsanız, aşağıdaki bilgiler yazılı bir bildirim şeklinde (buna uygun olarak 17 USC § 512 (c)) belirlenen 
  Telif Hakkı Temsilcimize sağlanmalıdır:
  </p><p>
  fiziksel veya elektronik imzanız;</p><p>
  İhlal edildiğini iddia ettiğiniz telif hakkı alınmış çalışmanın / eserlerin tanımı;</p><p>
  Hizmetlerimizde ihlal ettiğini iddia ettiğiniz ve bizden kaldırmamızı talep ettiğiniz materyalin tanımı;</p><p>
  bu tür materyalleri bulmamıza izin verecek yeterli bilgi;</p><p>
  Adresiniz, telefon numaranız ve e-mail adresiniz;</p><p>
  sakıncalı materyalin kullanımına telif hakkı sahibi, temsilcisi veya yasalar tarafından izin verilmediğine iyi niyetle 
  inandığınıza dair bir beyan; ve</p><p>
  Bildirimdeki bilgilerin doğru olduğuna ve yalan beyanda bulunma cezası altında, ya ihlal edildiği iddia edilen telif hakkının 
  sahibi olduğunuza ya da telif hakkı sahibi adına hareket etmeye yetkili olduğunuza dair bir beyan.</p><p>
  Lütfen 17 U.S.C. § 512 (f) uyarınca, yazılı bir bildirimde maddi gerçeğin herhangi bir yanlış beyanı, şikayet eden tarafın, 
  yazılı bildirim ve telif hakkı ihlali iddiasıyla bağlantılı olarak maruz kaldığımız her türlü zarar, maliyet ve 
  avukat ücretlerinden otomatik olarak sorumlu olmasını sağlar.</p><p>
  
  <b>Genel</b>
  </p><p>
  Bu Koşullar ara sıra revizyona tabidir ve herhangi bir önemli değişiklik yaparsak, bize verdiğiniz son e-posta adresine bir e-posta göndererek ve / veya 
  değişikliklere ilişkin bildirimi göze çarpacak şekilde sitemizde yayınlayarak sizi bilgilendirebiliriz. 
  Site. Bize en güncel e-posta adresinizi vermekten sorumlusunuz. Bize sağladığınız son e-posta adresinin geçerli olmaması durumunda, 
  söz konusu bildirimi içeren e-postayı göndermemiz yine de bildirimde açıklanan değişikliklerin etkili bir şekilde bildirilmesini sağlayacaktır. 
  Bu Koşullarda yapılacak herhangi bir değişiklik, size bir e-posta bildirimi göndermemizi izleyen en erken otuz (30) takvim günü veya 
  Sitemizde değişiklik bildirimini yayınlamamızın ardından otuz (30) takvim günü sonra geçerli olacaktır. Bu değişiklikler Sitemizin yeni kullanıcıları için 
  hemen geçerli olacaktır. Bu tür değişikliklerin bildiriminin ardından Sitemizi kullanmaya devam etmeniz, bu tür değişiklikleri kabul ettiğinizi ve 
  bu değişikliklerin hüküm ve koşullarına bağlı olduğunu kabul ettiğinizi gösterecektir. Tartışmalı karar. Lütfen bu Tahkim Sözleşmesini dikkatlice okuyun. 
  Şirket ile olan sözleşmenizin bir parçasıdır ve haklarınızı etkiler. ZORUNLU BAĞLAYICI TAHKİM VE BİR GRUP DAVASI FERAGATI prosedürlerini içerir.
  </p><p>
  <b>Tahkim Anlaşmasının Uygulanabilirliği.</b> Şirket tarafından sağlanan, gayri resmi olarak veya küçük davalar mahkemesinde çözülemeyen Şartlar veya herhangi bir ürün veya hizmetin kullanımıyla ilgili tüm iddialar ve anlaşmazlıklar, bu Tahkim Sözleşmesinin şartları altında bireysel olarak bağlayıcı tahkim yoluyla çözülecektir. Aksi kararlaştırılmadıkça, tüm tahkim işlemleri İngilizce yapılacaktır. Bu Tahkim Sözleşmesi, siz ve Şirket ve Şartlar altında sağlanan hizmetlerin veya malların tüm yetkili veya yetkisiz kullanıcıları veya yararlanıcılarının yanı sıra tüm yan kuruluşlar, bağlı şirketler, temsilciler, çalışanlar, menfaat öncüsü, halefleri ve devralanları için geçerlidir.
  </p><p>
  <b> Bildirim Gereksinimi ve Gayri Resmi İhtilaf Çözümü.</b> Her iki taraf da tahkim talebinde bulunmadan önce, 
  taraf diğer tarafa iddianın veya anlaşmazlığın niteliğini ve temelini ve talep edilen tazminatı açıklayan yazılı bir Anlaşmazlık Bildirimi göndermelidir. 
  Şirkete bir Bildirim şu adrese gönderilmelidir: adresimiz. Bildirim alındıktan sonra, siz ve Şirket hak talebini veya anlaşmazlığı gayri resmi olarak 
  çözmeye çalışabilirsiniz. Siz ve Şirket iddiayı veya anlaşmazlığı Bildirimin alınmasından sonra otuz (30) gün içinde çözmezseniz, taraflardan biri 
  tahkim yargılamasına başlayabilir. Herhangi bir tarafça yapılan herhangi bir uzlaştırma teklifinin miktarı, hakemin taraflardan birinin hak kazandığı 
  karar miktarını belirleyene kadar hakeme ifşa edilemez.
  </p><p>
  <b>Tahkim Kuralları. </b>Tahkim, bu bölümde belirtildiği gibi tahkim sunan yerleşik bir alternatif uyuşmazlık çözümü sağlayıcısı olan 
  Tüketiciler Derneği aracılığıyla başlatılacaktır. AAA tahkim için uygun değilse, taraflar alternatif bir ADR Sağlayıcısı seçmeyi kabul edeceklerdir. 
  ADR Sağlayıcısının kuralları, bu tür kuralların Şartlar ile çeliştiği durumlar haricinde tahkimin tüm yönlerini yönetecektir.
  </p><p>
  <b> Görünüşe Dayalı Olmayan Tahkim için Ek Kurallar.</b> Görünüşe dayalı olmayan tahkim seçilirse, tahkim telefonla, çevrimiçi olarak ve / veya yalnızca yazılı sunumlara dayalı olarak yürütülecektir; özel tarz tahkimi başlatan tarafça seçilecektir. Taraflarca aksi kararlaştırılmadıkça tahkim, tarafların veya tanıkların herhangi bir kişisel ifadesini içermeyecektir.
  </p><p>
  <b> Zaman sınırları. </b>Siz veya Şirket tahkim peşinde koşuyorsa, tahkim davası, ilgili talep için AAA Kuralları uyarınca belirlenen herhangi bir son tarih içinde ve sınırlama statüsü dahilinde başlatılmalı ve / veya talep edilmelidir.
  </p><p>
  <b> Hakem Yetkisi. </b>Tahkim başlatılırsa, hakem sizin ve Şirketin hak ve yükümlülüklerine karar verecek ve anlaşmazlık başka herhangi bir konuyla birleştirilmeyecek veya başka herhangi bir dava veya tarafla birleştirilmeyecektir. Hakem, herhangi bir iddianın tamamı veya bir kısmı için karar verme yetkisine sahip olacaktır. Hakem, parasal tazminata hükmetme ve geçerli yasa, AAA Kuralları ve Şartlar uyarınca bir bireye sunulan herhangi bir parasal olmayan çare veya tazminat verme yetkisine sahip olacaktır. Hakem, kararın dayandığı temel bulguları ve sonuçları açıklayan yazılı bir karar ve karar beyanı yayınlayacaktır. Hakem, bir hukuk mahkemesindeki bir yargıcın sahip olacağı, bireysel temelde muafiyet kararı verme yetkisine sahiptir. Hakemin kararı nihaidir ve siz ve Şirket için bağlayıcıdır.
  </p><p>
  <b> Jüri Duruşmasından Feragat.</b> TARAFLAR BURADA MAHKEMEYE GİTMEK VE BİR HAKİM VEYA JÜRİ ÖNÜNDE DENEME YAPMAK İÇİN ANAYASAL VE YASAL HAKLARINDAN FERAGAT ETMEKTEDİR, bunun yerine tüm iddiaların ve anlaşmazlıkların bu Tahkim Sözleşmesi kapsamında tahkim yoluyla çözüleceğini seçerler. Tahkim prosedürleri tipik olarak bir mahkemede geçerli olan kurallardan daha sınırlı, daha verimli ve daha ucuzdur ve bir mahkeme tarafından çok sınırlı incelemeye tabidir. Sizinle Şirket arasında herhangi bir eyalette veya federal mahkemede bir tahkim kararını boşaltma veya uygulama davasıyla ilgili herhangi bir dava çıkması durumunda, SİZ VE ŞİRKET BİR JÜRİ DENEMESİNDEN TÜM HAKLARINDAN FERAGAT ETMEK yerine, anlaşmazlığın çözülmesini seçer. bir yargıç tarafından.
  </p><p>
  <b> Klas veya Birleştirilmiş İşlemlerden Feragat.</b> Bu tahkim sözleşmesi kapsamındaki tüm iddialar ve ihtilaflar, sınıf bazında değil, bireysel olarak tahkim veya dava edilmeli ve birden fazla müşteri veya kullanıcının iddiaları, başka bir müşterinin iddiaları ile tahkime veya müşterek olarak dava açılamaz veya konsolide edilemez. veya kullanıcı.
  </p><p>
  <b> Gizlilik.</b> Tahkim yargılamasının tüm yönleri kesinlikle gizli olacaktır. Taraflar, yasalarca aksi belirtilmedikçe gizliliği korumayı kabul eder. Bu paragraf, bir tarafın bu Sözleşmeyi uygulamak, bir tahkim kararını uygulamak veya ihtiyati tedbir veya hakkaniyete uygun bir çözüm aramak için gerekli herhangi bir bilgiyi bir mahkemeye sunmasını engellemeyecektir.
  </p><p>
  <b> Bölünebilirlik.</b> Bu Tahkim Sözleşmesinin herhangi bir kısmı veya bir kısmı kanun kapsamında yetkili bir mahkeme tarafından geçersiz veya uygulanamaz bulunursa, bu belirli kısım veya kısımlar hiçbir şekilde yürürlükte olmayacak ve Sözleşmenin geri kalanı ayrılacaktır. tam güç ve yürürlükte devam edin.
  </p><p>
  <b>Feragat Hakkı.</b> Bu Tahkim Sözleşmesinde belirtilen haklar ve sınırlamaların herhangi biri veya tümü, iddianın kendisine karşı ileri sürüldüğü tarafça feragat edilebilir. Bu tür bir feragat, bu Tahkim Sözleşmesinin herhangi bir kısmından feragat etmeyecek veya herhangi bir kısmını etkilemeyecektir.
  </p><p>
  <b> Anlaşmanın Yürürlüğü.</b> Bu Tahkim Sözleşmesi, Şirket ile ilişkinizin sona ermesinden sonra da geçerliliğini koruyacaktır.
  </p><p>
  <b>  Küçük Talepler Mahkemesi.</b> Yine de yukarıdakiler, siz veya Şirket küçük davalar mahkemesinde bireysel dava açabilirsiniz.
  </p><p>
  Acil Durumda Adil Yardım. Yukarıdakilere rağmen, taraflardan herhangi biri, tahkim bekleyen statükoyu sürdürmek için bir eyalet veya federal mahkeme önünde 
  acil durum eşitliği talep edebilir. Geçici tedbir talebi, bu Tahkim Anlaşması kapsamındaki diğer haklardan veya yükümlülüklerden feragat olarak kabul edilmeyecektir.
  </p><p>
  Tahkime Tabi Olmayan İddialar. Yukarıdakilere bakılmaksızın, hakaret iddiaları, Bilgisayar Sahtekarlığı ve Kötüye Kullanım Yasasının ihlali ve diğer 
  tarafın patentinin, telif hakkının, ticari markasının veya ticari sırlarının ihlali veya kötüye kullanılması bu Tahkim Sözleşmesine tabi olmayacaktır.
  </p><p>
  Yukarıdaki Tahkim Anlaşmasının tarafların mahkemede dava açmasına izin verdiği her durumda, taraflar bu tür amaçlar için 
  Türkiye'de bulunan mahkemelerin kişisel yargı yetkisine başvurmayı kabul eder.
  </p><p>
  Site, Türkiye ihracat kontrol yasalarına tabi olabilir ve diğer ülkelerdeki ihracat veya ithalat düzenlemelerine tabi olabilir. 
  Şirketten alınan herhangi bir Türkiye teknik verisini veya bu tür verileri kullanan herhangi bir ürünü, Türkiye devleti ihracat yasalarını veya düzenlemelerini 
  ihlal ederek doğrudan veya dolaylı olarak ihraç etmemeyi, yeniden ihraç etmemeyi veya aktarmamayı kabul etmektesiniz.
  </p><p>
  Şirket, Bölüm 10.8'deki adreste yer almaktadır. Türkiye'de ikamet ediyorsanız, şikayetlerinizi Tüketici İşleri Departmanı Tüketici Ürünleri Bölümü 
  Şikayet Yardım Birimine yazılı olarak veya telefondan arayarak bildirebilirsiniz. 
  </p><p>
  Elektronik İletişim. Siz ve Şirket arasındaki iletişim, ister Siteyi kullanıyor olun, ister bize e-posta gönderiyor olun ya da Şirketin 
  Sitede bildirimler gönderip göndermediği veya sizinle e-posta yoluyla iletişim kurup kurmadığı, elektronik araçlar kullanır. 
  Sözleşme amaçları doğrultusunda, <br/>
  (a) Şirketten elektronik biçimde iletişim almayı kabul edersiniz; ve  <br/>
  (b) Şirketin size sağladığı tüm hüküm ve koşulların, sözleşmelerin, bildirimlerin, açıklamaların ve diğer iletişimlerin, bu tür iletişimlerin basılı bir 
  kopya halinde olması durumunda karşılayacağı herhangi bir yasal yükümlülüğü elektronik olarak karşıladığını kabul edersiniz. <br/>
  </p><p>
  <b>Tüm Koşullar. </b>Bu Koşullar, Sitenin kullanımıyla ilgili olarak sizinle aramızdaki tüm sözleşmeyi oluşturur. Bu Koşulların herhangi bir hakkını veya hükmünü uygulamamamız veya uygulamamamız, bu hak veya hükümden feragat edildiği anlamına gelmez. Bu Koşullardaki bölüm başlıkları yalnızca kolaylık sağlamak içindir ve yasal veya sözleşmeyle ilgili hiçbir etkisi yoktur. "Dahil" kelimesi "sınırlama olmaksızın dahil" anlamına gelir. Bu Koşulların herhangi bir hükmünün geçersiz veya uygulanamaz olduğuna karar verilirse, bu Koşulların diğer hükümleri ihlal edilmeyecek ve geçersiz veya uygulanamaz hüküm, yasaların izin verdiği azami ölçüde geçerli ve uygulanabilir olacak şekilde değiştirilmiş sayılacaktır. Şirket ile ilişkiniz bağımsız bir yükleniciye ilişkindir ve taraflardan hiçbiri diğerinin temsilcisi veya ortağı değildir. Bu Koşullar ve buradaki haklarınız ve yükümlülükleriniz, Şirketin önceden yazılı izni olmadan tarafınızca devredilemez, taşeronlaştırılamaz, devredilemez veya başka bir şekilde devredilemez ve yukarıdakileri ihlal eden herhangi bir atama, alt sözleşme, yetki verme veya devir geçersiz olacaktır geçersiz. Şirket bu Koşulları serbestçe devredebilir. Bu Koşullarda belirtilen hüküm ve koşullar, devralanlar üzerinde bağlayıcı olacaktır.
  </p><p>
  <b> Gizliliğiniz.</b> Lütfen Gizlilik Politikamızı okuyun.
  </p><p>
  <b>Telif Hakkı / Ticari Marka Bilgileri. </b>Telif hakkı ©. Her hakkı saklıdır. Sitede görüntülenen tüm ticari markalar, 
  logolar ve hizmet markaları bizim mülkiyetimiz veya diğer üçüncü şahısların mülkiyetindedir. 
  Bu Markaları önceden yazılı iznimiz olmadan veya Markalara sahip olabilecek üçüncü şahısların rızası olmadan
   kullanmanıza izin verilmez.
  </p><p>
  <b> İletişim bilgileri  </b></p><p>
  <b> Adres:</b> Lütfen iletişim sayfasına gidiniz.
  </p><p>
  <b> Eposta: </b> Lütfen iletişim sayfasına gidiniz.
  </p>
</Fragment>
}




const goToPage = (e: any, urlPath: any) => {
  e.preventDefault(); 
  history.push(urlPath);
}
  
  
  
    return (
      <IonPage>

      <IonContent>
         <HeaderAll goToPage={goToPage}  />
  
  
        <IonCard>
                <IonCardHeader>
                  <IonCardSubtitle>Kullanım Koşulları</IonCardSubtitle>
                  <IonCardTitle>Terms Of Use</IonCardTitle>
                </IonCardHeader>
  
                <IonCardContent>
                 {metin()}
  
  
                </IonCardContent>
          </IonCard>
  

  
  
      </IonContent>
       {/* <FooterAll goToPage={goToPage}  /> */}

      </IonPage>

    );
  
  
  
  };
  
  export default TermsOfUse;
  
//   Terms of Use
//   Version 1.0. Last Updated 01.10.2020
  
//   The yarrak sitesi website located at www.yarrak.com is a copyrighted work belonging to yarrak.com. Certain features of the Site may be subject to additional guidelines, terms, or rules, which will be posted on the Site in connection with such features.
  
//   All such additional terms, guidelines, and rules are incorporated by reference into these Terms.
  
//   These Terms of Use described the legally binding terms and conditions that oversee your use of the Site. BY LOGGING INTO THE SITE, YOU ARE BEING COMPLIANT THAT THESE TERMS and you represent that you have the authority and capacity to enter into these Terms. YOU SHOULD BE AT LEAST 18 YEARS OF AGE TO ACCESS THE SITE. IF YOU DISAGREE WITH ALL OF THE PROVISION OF THESE TERMS, DO NOT LOG INTO AND/OR USE THE SITE.
  
//   These terms require the use of arbitration Section 10.2 on an individual basis to resolve disputes and also limit the remedies available to you in the event of a dispute. These Terms of Use were created with the help of the Terms Of Use Generator and the Privacy Policy Generator.
  
//   Access to the Site
//   Subject to these Terms. Company grants you a non-transferable, non-exclusive, revocable, limited license to access the Site solely for your own personal, noncommercial use.
  
//   Certain Restrictions. The rights approved to you in these Terms are subject to the following restrictions: (a) you shall not sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site; (b) you shall not change, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site; (c) you shall not access the Site in order to build a similar or competitive website; and (d) except as expressly stated herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means unless otherwise indicated, any future release, update, or other addition to functionality of the Site shall be subject to these Terms.  All copyright and other proprietary notices on the Site must be retained on all copies thereof.
  
//   Company reserves the right to change, suspend, or cease the Site with or without notice to you.  You approved that Company will not be held liable to you or any third-party for any change, interruption, or termination of the Site or any part.
  
//   No Support or Maintenance. You agree that Company will have no obligation to provide you with any support in connection with the Site.
  
//   Excluding any User Content that you may provide, you are aware that all the intellectual property rights, including copyrights, patents, trademarks, and trade secrets, in the Site and its content are owned by Company or Company’s suppliers. Note that these Terms and access to the Site do not give you any rights, title or interest in or to any intellectual property rights, except for the limited access rights expressed in Section 2.1. Company and its suppliers reserve all rights not granted in these Terms.
  
//   Third-Party Links & Ads; Other Users
//   Third-Party Links & Ads. The Site may contain links to third-party websites and services, and/or display advertisements for third-parties.  Such Third-Party Links & Ads are not under the control of Company, and Company is not responsible for any Third-Party Links & Ads.  Company provides access to these Third-Party Links & Ads only as a convenience to you, and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third-Party Links & Ads.  You use all Third-Party Links & Ads at your own risk, and should apply a suitable level of caution and discretion in doing so. When you click on any of the Third-Party Links & Ads, the applicable third party’s terms and policies apply, including the third party’s privacy and data gathering practices.
  
//   Other Users. Each Site user is solely responsible for any and all of its own User Content.  Because we do not control User Content, you acknowledge and agree that we are not responsible for any User Content, whether provided by you or by others.  You agree that Company will not be responsible for any loss or damage incurred as the result of any such interactions.  If there is a dispute between you and any Site user, we are under no obligation to become involved.
  
//   You hereby release and forever discharge the Company and our officers, employees, agents, successors, and assigns from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature, that has arisen or arises directly or indirectly out of, or that relates directly or indirectly to, the Site. If you are a California resident, you hereby waive California civil code section 1542 in connection with the foregoing, which states: "a general release does not extend to claims which the creditor does not know or suspect to exist in his or her favor at the time of executing the release, which if known by him or her must have materially affected his or her settlement with the debtor."
  
//   Cookies and Web Beacons. Like any other website, yarrak sitesi uses ‘cookies’. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and/or other information.
  
//   Google DoubleClick DART Cookie. Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – https://policies.google.com/technologies/ads
  
//   Disclaimers
//   The site is provided on an "as-is" and "as available" basis, and company and our suppliers expressly disclaim any and all warranties and conditions of any kind, whether express, implied, or statutory, including all warranties or conditions of merchantability, fitness for a particular purpose, title, quiet enjoyment, accuracy, or non-infringement.  We and our suppliers make not guarantee that the site will meet your requirements, will be available on an uninterrupted, timely, secure, or error-free basis, or will be accurate, reliable, free of viruses or other harmful code, complete, legal, or safe.  If applicable law requires any warranties with respect to the site, all such warranties are limited in duration to ninety (90) days from the date of first use.
  
//   Some jurisdictions do not allow the exclusion of implied warranties, so the above exclusion may not apply to you.  Some jurisdictions do not allow limitations on how long an implied warranty lasts, so the above limitation may not apply to you.
  
//   Limitation on Liability
//   To the maximum extent permitted by law, in no event shall company or our suppliers be liable to you or any third-party for any lost profits, lost data, costs of procurement of substitute products, or any indirect, consequential, exemplary, incidental, special or punitive damages arising from or relating to these terms or your use of, or incapability to use the site even if company has been advised of the possibility of such damages.  Access to and use of the site is at your own discretion and risk, and you will be solely responsible for any damage to your device or computer system, or loss of data resulting therefrom.
  
//   To the maximum extent permitted by law, notwithstanding anything to the contrary contained herein, our liability to you for any damages arising from or related to this agreement, will at all times be limited to a maximum of fifty U.S. dollars (u.s. $50). The existence of more than one claim will not enlarge this limit.  You agree that our suppliers will have no liability of any kind arising from or relating to this agreement.
  
//   Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so the above limitation or exclusion may not apply to you.
  
//   Term and Termination. Subject to this Section, these Terms will remain in full force and effect while you use the Site.  We may suspend or terminate your rights to use the Site at any time for any reason at our sole discretion, including for any use of the Site in violation of these Terms.  Upon termination of your rights under these Terms, your Account and right to access and use the Site will terminate immediately.  You understand that any termination of your Account may involve deletion of your User Content associated with your Account from our live databases.  Company will not have any liability whatsoever to you for any termination of your rights under these Terms.  Even after your rights under these Terms are terminated, the following provisions of these Terms will remain in effect: Sections 2 through 2.5, Section 3 and Sections 4 through 10.
  
//   Copyright Policy.
//   Company respects the intellectual property of others and asks that users of our Site do the same.  In connection with our Site, we have adopted and implemented a policy respecting copyright law that provides for the removal of any infringing materials and for the termination of users of our online Site who are repeated infringers of intellectual property rights, including copyrights.  If you believe that one of our users is, through the use of our Site, unlawfully infringing the copyright(s) in a work, and wish to have the allegedly infringing material removed, the following information in the form of a written notification (pursuant to 17 U.S.C. § 512(c)) must be provided to our designated Copyright Agent:
  
//   your physical or electronic signature;
//   identification of the copyrighted work(s) that you claim to have been infringed;
//   identification of the material on our services that you claim is infringing and that you request us to remove;
//   sufficient information to permit us to locate such material;
//   your address, telephone number, and e-mail address;
//   a statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law; and
//   a statement that the information in the notification is accurate, and under penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed or that you are authorized to act on behalf of the copyright owner.
//   Please note that, pursuant to 17 U.S.C. § 512(f), any misrepresentation of material fact in a written notification automatically subjects the complaining party to liability for any damages, costs and attorney’s fees incurred by us in connection with the written notification and allegation of copyright infringement.
  
//   General
//   These Terms are subject to occasional revision, and if we make any substantial changes, we may notify you by sending you an e-mail to the last e-mail address you provided to us and/or by prominently posting notice of the changes on our Site.  You are responsible for providing us with your most current e-mail address.  In the event that the last e-mail address that you have provided us is not valid our dispatch of the e-mail containing such notice will nonetheless constitute effective notice of the changes described in the notice.  Any changes to these Terms will be effective upon the earliest of thirty (30) calendar days following our dispatch of an e-mail notice to you or thirty (30) calendar days following our posting of notice of the changes on our Site.  These changes will be effective immediately for new users of our Site.  Continued use of our Site following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes. Dispute Resolution. Please read this Arbitration Agreement carefully. It is part of your contract with Company and affects your rights.  It contains procedures for MANDATORY BINDING ARBITRATION AND A CLASS ACTION WAIVER.
  
//   Applicability of Arbitration Agreement. All claims and disputes in connection with the Terms or the use of any product or service provided by the Company that cannot be resolved informally or in small claims court shall be resolved by binding arbitration on an individual basis under the terms of this Arbitration Agreement.  Unless otherwise agreed to, all arbitration proceedings shall be held in English.  This Arbitration Agreement applies to you and the Company, and to any subsidiaries, affiliates, agents, employees, predecessors in interest, successors, and assigns, as well as all authorized or unauthorized users or beneficiaries of services or goods provided under the Terms.
  
//   Notice Requirement and Informal Dispute Resolution. Before either party may seek arbitration, the party must first send to the other party a written Notice of Dispute describing the nature and basis of the claim or dispute, and the requested relief.  A Notice to the Company should be sent to: adresimiz. After the Notice is received, you and the Company may attempt to resolve the claim or dispute informally.  If you and the Company do not resolve the claim or dispute within thirty (30) days after the Notice is received, either party may begin an arbitration proceeding.  The amount of any settlement offer made by any party may not be disclosed to the arbitrator until after the arbitrator has determined the amount of the award to which either party is entitled.
  
//   Arbitration Rules. Arbitration shall be initiated through the American Arbitration Association, an established alternative dispute resolution provider that offers arbitration as set forth in this section.  If AAA is not available to arbitrate, the parties shall agree to select an alternative ADR Provider.  The rules of the ADR Provider shall govern all aspects of the arbitration except to the extent such rules are in conflict with the Terms.  The AAA Consumer Arbitration Rules governing the arbitration are available online at adr.org or by calling the AAA at 1-800-778-7879.  The arbitration shall be conducted by a single, neutral arbitrator.  Any claims or disputes where the total amount of the award sought is less than Ten Thousand U.S. Dollars (US $10,000.00) may be resolved through binding non-appearance-based arbitration, at the option of the party seeking relief.  For claims or disputes where the total amount of the award sought is Ten Thousand U.S. Dollars (US $10,000.00) or more, the right to a hearing will be determined by the Arbitration Rules.  Any hearing will be held in a location within 100 miles of your residence, unless you reside outside of the United States, and unless the parties agree otherwise.  If you reside outside of the U.S., the arbitrator shall give the parties reasonable notice of the date, time and place of any oral hearings. Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.  If the arbitrator grants you an award that is greater than the last settlement offer that the Company made to you prior to the initiation of arbitration, the Company will pay you the greater of the award or $2,500.00.  Each party shall bear its own costs and disbursements arising out of the arbitration and shall pay an equal share of the fees and costs of the ADR Provider.
  
//   Additional Rules for Non-Appearance Based Arbitration. If non-appearance based arbitration is elected, the arbitration shall be conducted by telephone, online and/or based solely on written submissions; the specific manner shall be chosen by the party initiating the arbitration.  The arbitration shall not involve any personal appearance by the parties or witnesses unless otherwise agreed by the parties.
  
//   Time Limits. If you or the Company pursues arbitration, the arbitration action must be initiated and/or demanded within the statute of limitations and within any deadline imposed under the AAA Rules for the pertinent claim.
  
//   Authority of Arbitrator. If arbitration is initiated, the arbitrator will decide the rights and liabilities of you and the Company, and the dispute will not be consolidated with any other matters or joined with any other cases or parties.  The arbitrator shall have the authority to grant motions dispositive of all or part of any claim.  The arbitrator shall have the authority to award monetary damages, and to grant any non-monetary remedy or relief available to an individual under applicable law, the AAA Rules, and the Terms.  The arbitrator shall issue a written award and statement of decision describing the essential findings and conclusions on which the award is based.  The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have.  The award of the arbitrator is final and binding upon you and the Company.
  
//   Waiver of Jury Trial. THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY, instead electing that all claims and disputes shall be resolved by arbitration under this Arbitration Agreement.  Arbitration procedures are typically more limited, more efficient and less expensive than rules applicable in a court and are subject to very limited review by a court.  In the event any litigation should arise between you and the Company in any state or federal court in a suit to vacate or enforce an arbitration award or otherwise, YOU AND THE COMPANY WAIVE ALL RIGHTS TO A JURY TRIAL, instead electing that the dispute be resolved by a judge.
  
//   Waiver of Class or Consolidated Actions. All claims and disputes within the scope of this arbitration agreement must be arbitrated or litigated on an individual basis and not on a class basis, and claims of more than one customer or user cannot be arbitrated or litigated jointly or consolidated with those of any other customer or user.
  
//   Confidentiality. All aspects of the arbitration proceeding shall be strictly confidential.  The parties agree to maintain confidentiality unless otherwise required by law.  This paragraph shall not prevent a party from submitting to a court of law any information necessary to enforce this Agreement, to enforce an arbitration award, or to seek injunctive or equitable relief.
  
//   Severability. If any part or parts of this Arbitration Agreement are found under the law to be invalid or unenforceable by a court of competent jurisdiction, then such specific part or parts shall be of no force and effect and shall be severed and the remainder of the Agreement shall continue in full force and effect.
  
//   Right to Waive. Any or all of the rights and limitations set forth in this Arbitration Agreement may be waived by the party against whom the claim is asserted.  Such waiver shall not waive or affect any other portion of this Arbitration Agreement.
  
//   Survival of Agreement. This Arbitration Agreement will survive the termination of your relationship with Company.
  
//   Small Claims Court. Nonetheless the foregoing, either you or the Company may bring an individual action in small claims court.
  
//   Emergency Equitable Relief. Anyhow the foregoing, either party may seek emergency equitable relief before a state or federal court in order to maintain the status quo pending arbitration.  A request for interim measures shall not be deemed a waiver of any other rights or obligations under this Arbitration Agreement.
  
//   Claims Not Subject to Arbitration. Notwithstanding the foregoing, claims of defamation, violation of the Computer Fraud and Abuse Act, and infringement or misappropriation of the other party’s patent, copyright, trademark or trade secrets shall not be subject to this Arbitration Agreement.
  
//   In any circumstances where the foregoing Arbitration Agreement permits the parties to litigate in court, the parties hereby agree to submit to the personal jurisdiction of the courts located within Netherlands County, California, for such purposes.
  
//   The Site may be subject to U.S. export control laws and may be subject to export or import regulations in other countries. You agree not to export, re-export, or transfer, directly or indirectly, any U.S. technical data acquired from Company, or any products utilizing such data, in violation of the United States export laws or regulations.
  
//   Company is located at the address in Section 10.8. If you are a California resident, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Product of the California Department of Consumer Affairs by contacting them in writing at 400 R Street, Sacramento, CA 95814, or by telephone at (800) 952-5210.
  
//   Electronic Communications. The communications between you and Company use electronic means, whether you use the Site or send us emails, or whether Company posts notices on the Site or communicates with you via email. For contractual purposes, you (a) consent to receive communications from Company in an electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Company provides to you electronically satisfy any legal obligation that such communications would satisfy if it were be in a hard copy writing.
  
//   Entire Terms. These Terms constitute the entire agreement between you and us regarding the use of the Site. Our failure to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such right or provision. The section titles in these Terms are for convenience only and have no legal or contractual effect. The word "including" means "including without limitation". If any provision of these Terms is held to be invalid or unenforceable, the other provisions of these Terms will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law.  Your relationship to Company is that of an independent contractor, and neither party is an agent or partner of the other.  These Terms, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without Company’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void.  Company may freely assign these Terms.  The terms and conditions set forth in these Terms shall be binding upon assignees.
  
//   Your Privacy. Please read our Privacy Policy.
  
//   Copyright/Trademark Information. Copyright ©. All rights reserved.  All trademarks, logos and service marks displayed on the Site are our property or the property of other third-parties. You are not permitted to use these Marks without our prior written consent or the consent of such third party which may own the Marks.
  
//   Contact Information
//   Address: adresimiz
  
//   Email: goncakioask@gmail.com



  


  