import React, { Fragment, useState } from 'react';
import {  IonHeader, IonToolbar,  IonButton,  IonIcon, IonImg, IonButtons, IonModal } from '@ionic/react'
// import { Link, RouteComponentProps } from 'react-router-dom';

import {  addCircleOutline, atCircleOutline, book,  documentsOutline, ellipsisHorizontal,
   ellipsisVertical,  home,  mapOutline, personCircle,  search } from 'ionicons/icons';
import { useUser } from '../../context/UserContext';
import Login from './Login';
// import  './../pages/estate/LoginModal.css';
// import './Tab3.css';
import ReactTooltip from 'react-tooltip';

const HeaderAll = (props: any) => {

  const [showModal, setShowModal] = useState(false);
  const {  isSearch, setIsSearch, setIsSideBarRight, isLogged} = useUser();

  // const [tooltipOpen, setTooltipOpen] = useState(false);
  // const toggle = () => setTooltipOpen(!tooltipOpen);


        const handleLogin = (e: any) => {
          e.preventDefault();
          // console.log("login...........");
          setShowModal(true);             
        }

          const handleSideMenu = (e: any) => {
            e.preventDefault();
            // console.log("handleSideMenu...........");
            // setIsSideBarRight(true);   
          }
          const handleSideMenu2 = (e: any) => {
            e.preventDefault();
            // console.log("handleSideMenu22222222222222...........");
            //history.push('/dashboard/ara');
            // setIsSearch(!isSearch); <Link to="/dashboard/users/1">User 1</Link>
           // setShowModal(true);   <p data-tip="hello world">Tooltip</p>
          }

  return (

    <Fragment>

    <IonHeader className="theader">
        <IonToolbar className="tbar" color="light">
          <IonImg className="genislogo" src='assets/imgs/alkagenis2.jpg' alt="yeni"></IonImg>
          <IonButtons slot="secondary">
                    <IonButton id="btnLogin" onClick={handleLogin} data-tip="Kullanıcı Girişi">
                      <IonIcon slot="icon-only" icon={personCircle}  onClick={handleLogin}/>
                    </IonButton>
                    </IonButtons>

                    <IonButtons slot="end">   
                    <IonButton id="cmdSideMenu" color="danger"  routerLink="/dashboard/ara">
                      <IonIcon   slot="icon-only" ios={search} md={search} />
                    </IonButton>
                  </IonButtons>          

                  <IonButtons slot="end">
                  <IonButton id="cmdSideMenu" color="danger"   routerLink="/dashboard/sidemenu1">
                    <IonIcon  slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical} />
                  </IonButton>
                </IonButtons>

      <IonButton key="btn1" onClick={e => props.goToPage(e,'/dashboard')}  data-tip="Ana Sayfa"  fill="outline">
     <IonIcon   slot="icon-only" ios={home} md={home} />   </IonButton>

     <IonButton key="btn2" onClick={e => props.goToPage(e,'/dashboard/ara') }    data-tip="Emlak Ara"   fill="outline">
     <IonIcon   slot="icon-only" ios={search} md={search} />   </IonButton>

     <IonButton key="btn3" onClick={e => props.goToPage(e,'/dashboard/terms') }    data-tip="Şartlar"   fill="outline">
     <IonIcon   slot="icon-only" ios={mapOutline} md={mapOutline} />   </IonButton>

     <IonButton key="btn6" onClick={e => props.goToPage(e,'/dashboard/tofuse') }      data-tip="Kullanma Koşulları"   fill="outline"> 
     <IonIcon   slot="icon-only" ios={book} md={book} />   </IonButton>

     <IonButton key="btn26" onClick={e => props.goToPage(e,'/dashboard/privacy') }      data-tip="Gizlilik"   fill="outline">
     <IonIcon   slot="icon-only" ios={documentsOutline} md={documentsOutline} />   </IonButton>

     <IonButton key="btn4" onClick={e => props.goToPage(e,'/dashboard/contactus') }    data-tip="Hakkımızda"   fill="outline">
     <IonIcon   slot="icon-only" ios={atCircleOutline} md={atCircleOutline} />   </IonButton>  

{(!isLogged) ? 
    <Fragment>

          <IonButton key="btn5" onClick={ handleLogin}  data-tip="Sisteme Giriş"   fill="outline">
        <IonIcon   slot="icon-only" ios={personCircle} md={personCircle} />   </IonButton>
    </Fragment>

     :
     <Fragment>
        <IonButton key="giris" onClick={e => props.goToPage(e,'/dashboard/giris') }  data-tip="Veri Giriş"   fill="outline">
        <IonIcon   slot="icon-only" ios={addCircleOutline} md={addCircleOutline} />   </IonButton>

    </Fragment>}
     

  </IonToolbar> 
</IonHeader>

<IonModal isOpen={showModal} cssClass='my-custom-class'>  
  
<Login></Login>
<IonButton size="large" onClick={() => setShowModal(false)}>KAPAT</IonButton>
</IonModal>

<ReactTooltip />
</Fragment>

 

  );
};

export default HeaderAll;

// <IonContent>           {/* <Link to="/dashboard/ara"> </Link> */}

// <Tab1EmlakList /> 

//   </IonContent>

// <IonFooter>
// <IonToolbar>

// <IonButton key="btn51" onClick={e => { e.preventDefault(); history.push('/dashboard/pages/51') }}>
// <IonIcon   slot="icon-only" ios={pencilOutline} md={pencilOutline} />   </IonButton>

// <IonButton key="btn52" onClick={e => { e.preventDefault(); history.push('/dashboard/pages/52') }}>
// <IonIcon   slot="icon-only" ios={cameraOutline} md={cameraOutline} />   </IonButton>

// <IonButton key="btn53" onClick={e => { e.preventDefault(); history.push('/dashboard/pages/53') }}>
// <IonIcon   slot="icon-only" ios={scanCircleSharp} md={scanCircleSharp} />   </IonButton>

// <IonButton key="btn54" onClick={e => { e.preventDefault(); history.push('/dashboard/pages/54') }}>
// <IonIcon   slot="icon-only" ios={help} md={help} />   </IonButton>

// <IonButton key="btn55" onClick={e => { e.preventDefault(); history.push('/dashboard/pages/55') }}>
// <IonIcon   slot="icon-only" ios={informationCircleOutline} md={informationCircleOutline} />   </IonButton>

// <IonButton key="btn56" onClick={e => { e.preventDefault(); history.push('/dashboard/pages/56') }}>
// <IonIcon   slot="icon-only" ios={funnel} md={funnel} />   </IonButton>

// <IonButton key="btn57" onClick={e => { e.preventDefault(); history.push('/dashboard/pages/57') }}>
// <IonIcon   slot="icon-only" ios={flowerOutline} md={flowerOutline} />   </IonButton>

// <IonButton key="btn58" onClick={e => { e.preventDefault(); history.push('/dashboard/pages/58') }}>
// <IonIcon   slot="icon-only" ios={folderOpenOutline} md={folderOpenOutline} />   </IonButton>

// <IonButton key="btn59" onClick={e => { e.preventDefault(); history.push('/dashboard/pages/59') }}>
// <IonIcon   slot="icon-only" ios={flashOutline} md={flashOutline} />   </IonButton>


// </IonToolbar>
// </IonFooter>   


// </IonPage>



    //  {/* 
     
     
    //  <IonButton key="btn7" onClick={e => props.goToPage(e,'/dashboard/pages/7') }>
    //  <IonIcon   slot="icon-only" ios={logoAndroid} md={logoAndroid} />   </IonButton>

    //  <IonButton key="btn8" onClick={e => props.goToPage(e,'/dashboard/pages/8') }>
    //  <IonIcon   slot="icon-only" ios={logoTwitter} md={logoTwitter} />   </IonButton>

    //  <IonButton key="btn9" onClick={e => props.goToPage(e,'/dashboard/pages/9') }>
    //  <IonIcon   slot="icon-only" ios={logoHtml5} md={logoHtml5} />   </IonButton> 
     
    //       <IonButton key="btn6" onClick={e => props.goToPage(e,'/dashboard/pages/6') }>
    //  <IonIcon   slot="icon-only" ios={add} md={add} />   </IonButton>

    //  <IonButton key="btn4" onClick={e => props.goToPage(e,'/dashboard/contactus') }>
    //  <IonIcon   slot="icon-only" ios={atCircleOutline} md={atCircleOutline} />   </IonButton>  
     
     
    //  */}
      