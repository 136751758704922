


import React, { useEffect, useLayoutEffect, useState, Fragment } from 'react';
import { IonContent,   IonLabel, IonButton, IonGrid, IonRow, IonCol, IonInput, IonTextarea, IonIcon, IonDatetime } from '@ionic/react';

import './../../Tab3.css';
import { useUser } from '../../../context/UserContext';
// import moment from 'moment';

import {  arrowDownSharp, printSharp, refreshSharp,  save, documentText } from 'ionicons/icons';
import { useTest } from '../../../context/TestContext';
import { mbFireSablonVaadSet, isNullMb } from '../../../utils';
import { toasterMsg } from '../toast/toasterMsg';
import domtoimage from 'dom-to-image';
import { jsPDF }  from "jspdf";

const Sayfa5Vaad: React.FC = () => {

  const {pathUser} = useUser();
  const {parDict, sablon, siraNo, inputChanged, propDetay} = useTest();
  const [vaadText, setVaadText] = useState('');
  const [refresh, setRefresh] = useState(false);



    const sablonVaad = () => {
      if (sablon !== undefined && sablon !== null)
      Object.keys(sablon).map((item:any) =>   setVaadText(parDict[sablon[item]]['SpareLabel']) );
    };

    const sablonVaadSablonGetir = () => {
      if (sablon !== undefined && sablon !== null) {
        let item = Object.keys(sablon).map((item:any) =>  parDict[sablon[item]]['Label']);
        if (item !== undefined && item !== null && item[0] !== undefined && item[0] !== null) {
          Object.keys(item[0]).map((field) =>   localStorage.setItem(field, item[0][field]));    
        }
      }    // console.log(' element '+JSON.stringify(field)+ ' : '+item[0][field]);
      vaadGetir();    
    };

    const vaadGetir = () => {
      let item: any;
      if (propDetay !== undefined && propDetay !== null && siraNo! > 0) {
        // console.log(' propDetay ******** : '+JSON.stringify(propDetay))
        propDetay.map((profile: any) => (
          Object.keys(profile['detail'][siraNo!][siraNo!]).map((data, index) => {
            if (data==='vaad') item = profile['detail'][siraNo!][siraNo!]['vaad']    
          })));
          if (item!==undefined && item!==null) {
            Object.keys(item).map((field:any)=>  localStorage.setItem(field, item[field]) )
          }
      }      
    };

    function  createPDF(val: any){

      let node:any = document.getElementById('my-node');
      // console.log(' node '+node);
      const options = { background: 'white', height: 1245, width: 795 };
    
      domtoimage.toPng(node,options)
          .then(function (dataUrl:any) {
              var img = new Image();
              img.src = dataUrl;
              // // console.log(' dataUrl '+dataUrl);
              document.body.appendChild(img);
              const doc = new jsPDF();   //Add image Url to PDF
              doc.addImage(dataUrl, 'png', 20,10, 172, 310);              
              doc.save('pdfDocument.pdf');
          })
          .catch(function (error: any) {
              console.error('oops, something went wrong!', error);
          });
      };

      // function handleFocus(e: any){

      // }

      function  handleKaydet(val: any){
        let mysirano = isNullMb(localStorage.getItem('vsirano'),'0');
        if (siraNo===0 || mysirano==='0') {
          toasterMsg('SıraNo=0 olamaz. Güncellemek için önce bir emlak seçmeniz lazım.','danger',10); 
          return false;
        }
        let estateObj = {
          vsirano: localStorage.getItem('vsirano'),
          vtarihi: localStorage.getItem('vtarihi'),
          vmahkemesehri: localStorage.getItem('vmahkemesehri'),
          vsatiskapora: localStorage.getItem('vsatiskapora'),
          vvazgecmecezasi: localStorage.getItem('vvazgecmecezasi'),
          vsatiskomisyon: localStorage.getItem('vsatiskomisyon'),
          vfirmaadres: localStorage.getItem('vfirmaadres'),
          vparsel: localStorage.getItem('vparsel'),
          vsatisfiyati: localStorage.getItem('vsatisfiyati'),
          vturucinsi: localStorage.getItem('vturucinsi'),
          vadisoyadi: localStorage.getItem('vadisoyadi'),
          vada: localStorage.getItem('vada'),
        };
        const result = mbFireSablonVaadSet(pathUser,'doc/',mysirano+'/'+mysirano+'/','vaad',estateObj);
        toasterMsg('Satış Vaadi Kaydedildi.'+result,'primary',10);        
      };

      function  handleKaydetSablon(val: any){
        let estateObj = {
          vsirano: localStorage.getItem('vsirano'),
          vtarihi: isNullMb(localStorage.getItem('vtarihi'),''),
          vmahkemesehri: isNullMb(localStorage.getItem('vmahkemesehri'),''),
          vsatiskapora: isNullMb(localStorage.getItem('vsatiskapora'),''),
          vvazgecmecezasi: isNullMb(localStorage.getItem('vvazgecmecezasi'),''),
          vsatiskomisyon: isNullMb(localStorage.getItem('vsatiskomisyon'),''),
          vfirmaadres: isNullMb(localStorage.getItem('vfirmaadres'),''),
          vparsel: isNullMb(localStorage.getItem('vparsel'),''),
          vsatisfiyati: isNullMb(localStorage.getItem('vsatisfiyati'),''),
          vturucinsi: isNullMb(localStorage.getItem('vturucinsi'),''),
          vadisoyadi: isNullMb(localStorage.getItem('vadisoyadi'),''),
          vada: isNullMb(localStorage.getItem('vada'),''),
        };
        const result = mbFireSablonVaadSet(pathUser,'json/','23/','Label',estateObj);
        toasterMsg('Şablon kaydetme sonucu ..','primary',10);   
      };

      function  handleYenile(val: any){
        sablonVaadSablonGetir();  
        setRefresh(true);
      }

      function  handleYenileYazi(val: any){
        sablonVaad();  
        setRefresh(true);
      }

      function  handleSablonUygula(){
        let metin = vaadText;
        let pre = "v";
        const regexp: any = /\[[a-z]*]/g;
        let indexes1 = [...metin.matchAll(regexp)];
        let words = new Array([]);
        let wordsPure = new Array([]);
        indexes1.map((element) => {
          let wrd: any = element.toString().trim();
          let wrdPure = wrd.replace('[','').replace(']','').trim();
          if (wrdPure.length) {
            words.push(wrd);
            wordsPure.push(wrdPure);   
            return null;         
          }        
        });    
        wordsPure.map((element, index) => {    // alan isimler şablandaki gibi ama parantezsiz geldi.
          if (element.length) {
            let localVal = isNullMb(localStorage.getItem(pre+element+''),'');
            if (localVal.length) {        // local storage değer var ise.
              metin = metin.replace('['+element+']',localVal);
              // console.log(' found '+index+' '+localVal+' element : '+element);
            }
            setVaadText(metin);
          } 
          return null;     
        });
       // toasterMsg('Şablon kaydetme sonucu ..'+JSON.stringify(wordsPure),40);   
        // find all values from localstorage  
      };  
      
      
      useEffect(() => {
        // console.log(' page 5 use effect ');
        sablonVaad();
        sablonVaadSablonGetir();
    },[])

    useLayoutEffect(()=>{
      if (refresh) {
        // console.log(' page 5 use useLayoutEffect ');
        setRefresh(false);    
         } 
    },[refresh])
    
        return ( 
          <Fragment>

            <IonContent id="vaadPage" >

              <IonButton onClick={handleKaydet} color="danger">Kaydet
              <IonIcon slot="start" icon={save} /></IonButton>
            
              <IonButton onClick={handleYenile} color="danger"><IonIcon icon={refreshSharp} slot="start" /></IonButton>
              <IonButton onClick={handleKaydetSablon} color="danger">Şablon Kaydet 
              <IonIcon slot="start" icon={documentText} /></IonButton>               
          
              <IonGrid>
                <IonRow>
                  <IonCol size="2" size-sm>  <IonLabel>Sıra No</IonLabel>  </IonCol>
                  <IonCol size="3" size-sm> <IonInput id="vsirano" readonly value={siraNo}   onIonChange={inputChanged}  ></IonInput>  </IonCol>
                  <IonCol size="3" size-sm>  <IonLabel>Türü/Cinsi</IonLabel>   </IonCol>
                  <IonCol size="4" size-sm><IonInput id="vturucinsi"   onIonChange={inputChanged} value={localStorage.getItem('vturucinsi')} ></IonInput>  </IonCol>
                </IonRow>
    
                <IonRow>
                  <IonCol size="2" size-sm>  <IonLabel>Sahibi</IonLabel>   </IonCol>
                  <IonCol size="4" size-sm > <IonInput id="vadisoyadi"   onIonChange={inputChanged}  value={localStorage.getItem('vadisoyadi')} className="item-has-focus" ></IonInput>   </IonCol>
                  <IonCol size="2" size-sm>   <IonLabel>Ada</IonLabel>   </IonCol>
                  <IonCol size="4" size-sm>  <IonInput id="vada"   onIonChange={inputChanged}  value={localStorage.getItem('vada')}  ></IonInput>   </IonCol>  
                </IonRow>
    
                <IonRow>  
                  <IonCol size="3" size-sm>  <IonLabel>SatışFiyat</IonLabel>   </IonCol>
                  <IonCol size="5" size-sm>  <IonInput id="vsatisfiyati"   onIonChange={inputChanged}  value={localStorage.getItem('vsatisfiyati')}  ></IonInput>   </IonCol>
                  <IonCol size="2" size-sm>  <IonLabel>Parsel</IonLabel>   </IonCol>
                  <IonCol size="2" size-sm> <IonInput id="vparsel"   onIonChange={inputChanged}  value={localStorage.getItem('vparsel')}  ></IonInput>   </IonCol>
                </IonRow>
    
                <IonRow>
                  <IonCol size="3" size-sm>  <IonLabel>Adresimiz</IonLabel>   </IonCol>
                  <IonCol size="9" size-sm>  <IonInput id="vfirmaadres"   onIonChange={inputChanged}  value={localStorage.getItem('vfirmaadres')}  ></IonInput>   </IonCol>
                </IonRow>      
    
                <IonRow>
                  <IonCol size="3" size-sm>  <IonLabel>Kom.Oran</IonLabel>   </IonCol>
                  <IonCol size="2" size-sm>  <IonInput id="vsatiskomisyon"   onIonChange={inputChanged}  value={localStorage.getItem('vsatiskomisyon')}  ></IonInput>   </IonCol>
                  <IonCol size="3" size-sm>  <IonLabel>Vazgeç.Oran</IonLabel>   </IonCol>
                  <IonCol size="4" size-sm>  <IonInput id="vvazgecmecezasi"   onIonChange={inputChanged}  value={localStorage.getItem('vvazgecmecezasi')}  ></IonInput>   </IonCol>

                </IonRow>
                <IonRow>
                  <IonCol size="2" size-sm>  <IonLabel>Kapora</IonLabel>   </IonCol>
                  <IonCol size="2" size-sm>  <IonInput id="vsatiskapora"   onIonChange={inputChanged}  value={localStorage.getItem('vsatiskapora')}  ></IonInput>   </IonCol>               
                  <IonCol size="4" size-sm>  <IonLabel>Mahkeme Şehir</IonLabel>   </IonCol>
                  <IonCol size="4" size-sm>  <IonInput id="vmahkemesehri" autocomplete="off"   onIonChange={inputChanged}  value={localStorage.getItem('vmahkemesehri')}   ></IonInput>   </IonCol>
                </IonRow>

                <IonRow>
                <IonCol size="2" size-sm>  <IonLabel>Tarihi</IonLabel>  </IonCol>
                  <IonCol size="6" size-sm> <IonDatetime id="vtarihi"  
                   onIonChange={inputChanged}  displayFormat="DD.MM.YYYY"  
                   value={localStorage.getItem('vtarihi')} 
                    monthNames={'Ocak, Şubat, Mart, Nisan, Mayıs, Haziran, Temmuz, Ağustos, Eylül, Ekim, Kasım, Aralık'} 
                     
                    pickerFormat="DD.MMMM.YYYY"     >
                   </IonDatetime>  </IonCol>
                </IonRow>  

                <IonRow>
                    <IonButton onClick={handleSablonUygula} color="danger"><IonIcon icon={arrowDownSharp} slot="start" /> </IonButton>              
                    <IonButton onClick={handleYenileYazi} color="danger"><IonIcon icon={refreshSharp} slot="start" /></IonButton>                    
                    <IonButton onClick={createPDF} color="danger"><IonIcon icon={printSharp} slot="start" /> </IonButton>
                </IonRow>  
              </IonGrid>

              <IonTextarea id="vvaadtext" rows={20} value={vaadText}   onIonChange={inputChanged} 
               ></IonTextarea>                   
 
            </IonContent>
     
     <div id="my-node">
          {(vaadText) ? 
          (vaadText.split('*').map((item, index)=><div key={"p"+index}>{(item.length<2) ? <hr/> : item}</div>) ) : 
          null }  
     </div>     
     
     </Fragment>
        );    
    };    

export default Sayfa5Vaad;

// vtarihi,,,vmahkemesehri,,,,vsatiskapora,,,,,,vvazgecmecezasi,,
// ,vsatiskomisyon,,vfirmaadres,vparsel,vsatisfiyati,vada, vadisoyadi, vturucinsi.

// Get the PDF as base64 data 
// const pdfDocGenerator = pdfMake.createPdf(docDefinition);
// pdfDocGenerator.getBase64((data) => {
// 	alert(data);
// });

// vtarihi,,vturucinsi, vada  vparsel  vsatisfiyati  vadisoyadi   vfirmaadres    vsatiskomisyon vvazgecmecezasi
//  vmahkemesehri,,,,vsatiskapora,,,,,,vvazgecmecezasi,,vsirano
// ,,,,,,, , .

            // console.log(' vaad sonunda bulundu : '+JSON.stringify(item) + '  : ');
            // Object.keys(item).map((field:any)=> console.log(' vaad sonunda bulundu : ' + item[field] + '  : '+field));
            // Object.keys(item[0]).map((field) =>   localStorage.setItem(field, item[0][field]));
        // let item = Object.keys(sablon).map((item:any) =>  parDict[sablon[item]]['Label']);
        // if (item !== undefined && item !== null && item[0] !== undefined && item[0] !== null) {
        //   Object.keys(item[0]).map((field) => {
        //     console.log(' element '+JSON.stringify(field)+ ' : '+item[0][field]);
        //     localStorage.setItem(field, item[0][field]);
        //   });
        // }
             
        //               {/* <div id="divall">
        //                 DİV ALL BURADA şşşŞŞ
        //                 <img id="imgnew" src="" alt=""/>
        //  </div> */}