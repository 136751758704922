


import React, {  Fragment } from 'react';
import { IonContent,     IonLabel, IonButton, IonGrid, IonRow, IonCol, IonInput, IonIcon, IonDatetime, IonRadioGroup, IonRadio, IonCheckbox } from '@ionic/react';

import './../../Tab3.css';
import { useTest } from '../../../context/TestContext';
import { closeCircle } from 'ionicons/icons';
import { isNullMb, mbFireSet, delPropDetay, initLocal } from '../../../utils';
import { toasterMsg } from '../toast/toasterMsg';
import { toaster } from '../toast/toaster';
import { useUser } from '../../../context/UserContext';

const Sayfa1: React.FC = () => {   

  const { pathUser } = useUser();  
  
  const { siraNo, setSiraNo, imageAsUrlDict , parDict,
          dataKiralikSatilikAll, 
          dataKategoriAll,
          dataBinaYasiAll, 
          dataTapuDurumuAll,
          dataCepheAll, 
          dataIsitmaTipiAll, 
          dataKonutTipiAll, 
          dataOdaSayisiAll, 
          dataKatDetayAll, 
          dataKatSayisiAll,
          dataBulunduguKatAll,
          dataManzaraAll, 
          dataIcOzellikAll,
          dataDisOzellikAll, 
          dataMuhitAll,
          dataUlasimAll, 
          dataEngelliyeUygunAll, 

          dataKiralikSatilikTitle,
          dataKategoriTitle,
          dataBinaYasiTitle, 
          dataTapuDurumuTitle,
          dataCepheTitle, 
          dataIsitmaTipiTitle, 
          dataKonutTipiTitle, 
          dataOdaSayisiTitle, 
          dataKatDetayTitle, 
          dataKatSayisiTitle,
          dataBulunduguKatTitle,
          dataManzaraTitle, 
          dataIcOzellikTitle,
          dataDisOzellikTitle, 
          dataMuhitTitle,
          dataUlasimTitle, 
          dataEngelliyeUygunTitle, 
          saveStateVals,
  } = useTest();


    // useEffect(() => {
    //     console.log(' page 1 use effect ');
    //    // if (propDetay===undefined || propDetay === null) return null;
       

    // },[]);

    // useLayoutEffect(()=>{
    //     console.log(' page 1 use useLayoutEffect ');
    // },[])

 const submitProperty = (e:any) => {
     // Console.log('submit '+e);
     let emlakID = localStorage.getItem('siraNo');
     if (emlakID?.length===0) {
        toasterMsg('Yeni emlak aç düğmesini tıklamalı veya ilk alana bir emlak no yazıp bul düğmesine basmalısınız..','danger',10);
        return false;
     }
     let estateObj = {
        sira: emlakID,
        baslik: localStorage.getItem('baslik'),
        detay: localStorage.getItem('detay'),
        propid: localStorage.getItem('siraNo'),
        fiyat: localStorage.getItem('fiyat'),
        aidat: localStorage.getItem('aidat'),
        durumu: localStorage.getItem('durumu'),
        netm2: localStorage.getItem('netm2'),
        brutm2: localStorage.getItem('brutm2'),
        depozito: localStorage.getItem('depozito'),
        mahalle: localStorage.getItem('mahalle'),
        sehir: localStorage.getItem('sehir'),
        adresi: localStorage.getItem('adresi'),
        sahibi: localStorage.getItem('sahibi'),
        bostarih: localStorage.getItem('bostarih'),
        kiralikSatilik: localStorage.getItem('kiralikSatilik'),
        kategori: localStorage.getItem('kategori'),
        binaYasi: localStorage.getItem('binaYasi'),
        tapuDurumu: localStorage.getItem('tapuDurumu'),
        cephe: localStorage.getItem('cephe'),
        isitmaTipi: localStorage.getItem('isitmaTipi'),
        konutTipi: localStorage.getItem('konutTipi'),
        odaSayisi: localStorage.getItem('odaSayisi'),
        manzara: localStorage.getItem('manzara'),
        katDetay: localStorage.getItem('katDetay'),
        katSayisi: localStorage.getItem('katSayisi'),
        bulunduguKat: localStorage.getItem('bulunduguKat'),
        engelliyeUygun: localStorage.getItem('engelliyeUygun'),
        muhit: localStorage.getItem('muhit'),
        ulasim: localStorage.getItem('ulasim'),
        disOzellik: localStorage.getItem('disOzellik'),
        icOzellik: localStorage.getItem('icOzellik'),
      }
      const outCome = mbFireSet(pathUser!,emlakID,'doc/'+emlakID+'/',estateObj) 
      // Console.log(outCome);
      toasterMsg('Kaydetme işlemi sonucu:'+outCome + 'başarılı ise. Lütfen resim ekleyiniz.','primary',10);
    };
//  const yeniKayitAc = (e:any) => {
//     // Console.log('yeniKayitAc '+e)
// }

const inputChanged = (e:any) => {
    e.preventDefault();
    e.stopPropagation(); 
    let checkText = (e.target as HTMLInputElement).value;
    // Console.log('inputChanged '+(e.target as HTMLInputElement).id +  ' : ' +checkText);
    localStorage.setItem((e.target as HTMLInputElement).id,checkText);    
}


// const propDetayGetir = (e:any) => {
//     // Console.log(' getir ');
//     let siraNum = localStorage.getItem('siraNo');
//     var data: any;
//     propDetay.map((item: any) => {
//         data = item['detail'][siraNum+''];
//     }  ) 

//     Object.keys(data).map((element:any) => {
//         console.log('propDetayGetir ************** '+element+ ' : '+data[element]);
//         Object.keys(data[element]).map((item:any)=>{
            
//             if (item==='muhit' || item==='ulasim'  || item==='manzara' ||  item==='disOzellik' ||  item==='icOzellik'  ||  item==='engelliiyeUygun' ) {
//                 console.log(item+ ' : çoklu seçim : '+data[element][item]);
//             } else {
//                 console.log(item+ ' : '+data[element][item]);
//             }
//             localStorage.setItem(item,data[element][item]);
//         });
       
//     });




   
   
    // if (propDetay!==undefined && propDetay !== null) {['detail'])


        // propDetay.map((profile: any, index: any) => {
        //   console.log(' *** keys *** : '+JSON.stringify(Object.keys(profile['detail'])));
        // })
    //   }  }

function propSil(e: any){
  e.preventDefault();
  e.stopPropagation();  
  if (siraNo === undefined || siraNo === null) {
    toasterMsg('önce bir emlak seçiniz','danger',10);
    return;
  }
  if (siraNo === 0) {
    toasterMsg('önce bir emlak seçiniz','danger',10);
    return;
  }
  setToastMb();
}

function func(){
  toasterMsg('Silme işlemi başladı.','primary',10);
  console.log('callback from ts');
  delPropDetay(siraNo, pathUser, imageAsUrlDict![siraNo!]);
  toasterMsg('Silme işlemi yapıldı.','primary',10);
  initLocal();
  setSiraNo!(0);
  toasterMsg('Silme işlemi yapıldı.','primary',10);
}

function setToastMb(){
  let msg = 'Lütfen seçili emlak ve resimlerini silme işlemi için onay veriniz.';
  console.log('toaster');
  let result = toaster(msg, 6000, func);
}

const blurFired = (e:any) => {    
    let id = (e.target as HTMLInputElement).id;
    let val = (e.target as HTMLInputElement).value;
    let siraNoVal = localStorage.getItem('siraNo');
    console.log(' blur fired '+id+ ' val '+val);
    const result = saveStateVals!(id, val);
}

const focusFired = (e:any) => {
    console.log('focusFired '+e)
}

function ListItem(props:any) {
    const itemSay = props.Sayim;
    let chkVal = props.groupID+"_"+props.id+"_"+props.label+"_"+props.spareBool;
  
   return (       
      <IonCol key={"divtab3"+props.id+props.label} color="orange">         
    <IonRadio   key={'iro'+props.groupID+"_"+props.id+"_"+props.label}   
    name={'iro'+props.groupID+"_"+props.id+"_"+props.label}    
    id={'iro'+props.groupID+"_"+props.id+"_"+props.label}    
    value={chkVal}   
    className="radio radio-inline radio-blue"  onIonBlur={blurFired} onIonFocus={focusFired} 
    onChange={e=>{
          e.preventDefault();
          e.stopPropagation();
          console.log('current target'+e.currentTarget.value);
        } }   
         onClick={e=>{
          e.preventDefault();
          e.stopPropagation();
          console.log('current target clicked '+e.currentTarget);
        } }  >
      </IonRadio> 
    <IonLabel   className="radioLabel"  key={'il1'+props.id+props.label} color="violet"  >{props.label}</IonLabel>    
  </IonCol>  
  
    )
  }

  function ListItemCB(props: { Accessor: any; Sayim: any; id: string; label: {} | null | undefined; groupID: string; spareBool: string; }) {
    const itemSay = props.Sayim;
    const labeli = props.label+''.trim()+',';
    var cbChecked = false;
    const engUygun = isNullMb(localStorage.getItem(props.Accessor),'');
    cbChecked = (engUygun.includes(props.label));
    
    return ( 
      <IonCol key={"divtab3"+props.id+props.label}>         
    <IonCheckbox   key={'icx'+props.id+props.label}  
    id={'icx'+props.groupID + " "+ props.id+props.label} 
    name={'icx_'+props.groupID + "_"+ props.id+"_"+props.label+"_"+props.spareBool} 
    className="radio radio-inline radio-teal" 
    value={props.groupID+"_"+props.id+"_"+props.label+"_"+props.spareBool}
    checked={cbChecked}
  
    onIonChange={e=>{
        e.preventDefault();
        e.stopPropagation(); 
        if (e.detail.checked){
          console.log(' item checked '+e.detail.value);
          changeStateValueCheckbox(props.Accessor,e.detail.value, true);
        } else {
          console.log(' item NOT checked '+e.detail.value);
          changeStateValueCheckbox(props.Accessor,e.detail.value, false);
        }      
    }}
        ></IonCheckbox> 
    <IonLabel   key={'il1'+props.id+props.label} 
      className={'cbLabel'}>{props.label}
    </IonLabel>    
  </IonCol>
    )
  }

  function changeStateValueCheckbox(accessor:any, value:any, checked: boolean) {
    var resultStr: string =  " ";
    let val = localStorage.getItem(accessor);
    if (val!==undefined && val !== null && val.length>1) resultStr = val + '';
    let valArr = value.split('_');  
    var valNew = valArr[2]+',';
    resultStr = resultStr.replace(valNew, '').replace('undefined','');  
    if (checked) resultStr = resultStr + valNew;
    localStorage.setItem(accessor, resultStr);
    console.log('oldFieldVal : '+localStorage.getItem(accessor));
      return resultStr; 
  }

  return (  

      <IonContent>
          <IonButton onClick={submitProperty} color="danger">Kaydet</IonButton>
          {/* <IonButton onClick={yeniKayitAc} color="danger">Yeni Kayıt Aç</IonButton> */}
          <IonGrid>

          <IonRow key='ipctweab32342'>
          <IonCol size="4"   key='ipctarrb313422'>
            <IonInput readonly placeholder="SıraNo" 
            id="siraNo" onIonChange={inputChanged} key={"newonee"}  
            value={siraNo}  onIonBlur={blurFired} onIonFocus={focusFired} />
             </IonCol>
            
            {/* <IonCol size="2"  key='ipctawerb33422'> 
            <IonButton onClick={propDetayGetir} id="propidsearch" color="danger"  key={"newoeenee"}><IonIcon slot="icon-only" icon={search} />    </IonButton>
             </IonCol> */}

            <IonCol size="2"  key='ipctawerb322'> 
            <IonButton onClick={propSil} id="propidsearch" color="danger"  key={"newoeenee"}>    <IonIcon slot="icon-only" icon={closeCircle} />  Sil</IonButton>
            </IonCol>    
         
            <IonCol  key={"newonffee"} size="3">   
            <IonInput placeholder="Kiralık 2+1" onIonChange={inputChanged}   id="baslik"   value={localStorage.getItem('baslik')}   onIonBlur={blurFired} onIonFocus={focusFired}  > </IonInput>       
            </IonCol>

            <IonCol size="3"> 
            <IonInput placeholder="1000 Tl. Ultra Lüks" onIonChange={inputChanged}  id="detay"   key={"nedwodddnee"}    value={localStorage.getItem('detay')}    onIonBlur={blurFired} onIonFocus={focusFired}  > </IonInput>       
            </IonCol>
          </IonRow>
          <IonRow key='ipctaqweb32ıuy2'>
                <IonCol size="2" size-sm>  <IonLabel>Fiyat</IonLabel>   </IonCol>
                <IonCol size="2" size-sm>  <IonInput id="fiyat" value={localStorage.getItem('fiyat')} key={'fiyat'}    onIonBlur={blurFired} onIonFocus={focusFired}  onIonChange={inputChanged} ></IonInput>  </IonCol>
                <IonCol size="2" size-sm>  <IonLabel>Aidat</IonLabel>   </IonCol>
                <IonCol size="2" size-sm>  <IonInput id="aidat" value={localStorage.getItem('aidat')} key={'aidat'}    onIonBlur={blurFired} onIonFocus={focusFired}  onIonChange={inputChanged} ></IonInput>  </IonCol>
                <IonCol size="2" size-sm>  <IonLabel>Durum</IonLabel>   </IonCol>
                <IonCol size="2" size-sm>  <IonInput id="durumu" value={localStorage.getItem('durumu')} key={'durumu'}    onIonBlur={blurFired} onIonFocus={focusFired}  onIonChange={inputChanged} ></IonInput>  </IonCol>
          </IonRow>
          <IonRow key='ipctaqweb32ıuy2m2'>
                <IonCol size="2" size-sm>  <IonLabel>B.m2</IonLabel>   </IonCol>
                <IonCol size="2" size-sm>  <IonInput id="brutm2" value={localStorage.getItem('brutm2')} key={'brutm2'}    onIonBlur={blurFired} onIonFocus={focusFired}  onIonChange={inputChanged} ></IonInput>  </IonCol>
                <IonCol size="2" size-sm>  <IonLabel>Netm2</IonLabel>   </IonCol>
                <IonCol size="2" size-sm>  <IonInput id="netm2" value={localStorage.getItem('netm2')} key={'netm2'}    onIonBlur={blurFired} onIonFocus={focusFired}  onIonChange={inputChanged} ></IonInput>  </IonCol>
                <IonCol size="2" size-sm>  <IonLabel>Depozit</IonLabel>   </IonCol>
                <IonCol size="2" size-sm>  <IonInput id="depozito" value={localStorage.getItem('depozito')} key={'depozito'}    onIonBlur={blurFired} onIonFocus={focusFired}  onIonChange={inputChanged} ></IonInput>  </IonCol>
          </IonRow>
          <IonRow key='ipctaqweb32ıuy2tarih'>
                <IonCol size="2" size-sm>  <IonLabel>Mahalle</IonLabel>   </IonCol>
                <IonCol size="4" size-sm>  <IonInput id="mahalle" value={localStorage.getItem('mahalle')} key={'mahalle'}    onIonBlur={blurFired} onIonFocus={focusFired}  onIonChange={inputChanged} ></IonInput>  </IonCol>
                            
                <IonCol size="2" size-sm>  <IonLabel>Şehir</IonLabel>   </IonCol>
                <IonCol size="4" size-sm>  <IonInput id="sehir" value={localStorage.getItem('sehir')} key={'sehir'}    onIonBlur={blurFired} onIonFocus={focusFired}  onIonChange={inputChanged} ></IonInput>  </IonCol>
         </IonRow>
         <IonRow key='ipctaqweb32ıuy2mah'>
                 <IonCol size="2" size-sm>  <IonLabel>Adresi</IonLabel>   </IonCol>
                <IonCol size="6" size-sm>  <IonInput id="adresi" value={localStorage.getItem('adresi')} key={'adresi'}    onIonBlur={blurFired} onIonFocus={focusFired}  onIonChange={inputChanged} ></IonInput>  </IonCol>
                <IonCol size="2" size-sm>  <IonLabel>Sahibi</IonLabel>   </IonCol>
                <IonCol size="2" size-sm>  <IonInput id="sahibi" value={localStorage.getItem('sahibi')} key={'sahibi'}    onIonBlur={blurFired} onIonFocus={focusFired}  onIonChange={inputChanged} ></IonInput>  </IonCol>
          </IonRow>

          <IonRow key='ipctaqweb32ıuy2tarihkk'>
                <IonCol size="3" size-sm>  <IonLabel>Boş Tarih</IonLabel>   </IonCol>
                <IonCol size="5" size-sm>  <IonDatetime id="bostarih" value={localStorage.getItem('bostarih')} key={'bostarih'}    onIonBlur={blurFired} onIonFocus={focusFired}  onIonChange={inputChanged} ></IonDatetime>  </IonCol>
          </IonRow>

          {(parDict != undefined && parDict != null && dataKategoriTitle!.length > 1 
           && parDict[dataKategoriTitle!]['GroupName'] != undefined && parDict[dataKategoriTitle!]['GroupName'] != null) ? 
          (<Fragment>

            <IonRow key='r_KiralikSatilik' id='r_KiralikSatilik' > <IonCol size="4"  key='ip2cta2'><IonLabel  className="propHeader">{parDict[dataKiralikSatilikTitle!]['GroupName']}</IonLabel></IonCol>
            <IonRadioGroup key="kiralikSatilik" id="kiralikSatilik" value={localStorage.getItem('kiralikSatilik')}  onIonChange={inputChanged}  >
            {Object.keys(dataKiralikSatilikAll).map((item:any) =>  
                 <ListItem   key={item+''} 
                 id={item+''}  
                 label={parDict[dataKiralikSatilikAll[item]]['Label']}  
                 Sayim={dataKiralikSatilikAll.length} 
                 groupID={parDict[dataKiralikSatilikAll[item]]['GroupID']}  
                 selData = {'dataKiralikSatilikSel'}
                 spareBool={parDict[dataKiralikSatilikAll[item]]['SpareBool']}   />                 
                )}                 
                </IonRadioGroup> 
            </IonRow>
              
            <IonRow key='r_Kategori' id='r_Kategori' > <IonCol size="3"  key='ipcta2'><IonLabel  className="propHeader">{parDict[dataKategoriTitle!]['GroupName']}</IonLabel></IonCol>
            <IonRadioGroup key="kategori" id="kategori" value={localStorage.getItem('kategori')}  onIonChange={inputChanged}  >
            {Object.keys(dataKategoriAll).map((item:any) =>  
                 <ListItem   key={item+''} 
                 id={item+''}  
                 label={parDict[dataKategoriAll[item]]['Label']}  
                 Sayim={dataKategoriAll.length} 
                 groupID={parDict[dataKategoriAll[item]]['GroupID']}  
                 selData = {'dataKategoriSel'}
                 spareBool={parDict[dataKategoriAll[item]]['SpareBool']}   />                 
                )}                 
                </IonRadioGroup> 
            </IonRow>

            {(parDict[dataBinaYasiTitle!] === undefined || parDict[dataBinaYasiTitle!] === null) ? <IonLabel>Bina Yaşı Yok</IonLabel> : (
            <IonRow key='r_BinaYasi' id='r_BinaYasi' > <IonCol size="3"  key='c_binaYasi'><IonLabel  className="propHeader">{parDict[dataBinaYasiTitle!]['GroupName']}</IonLabel></IonCol>
            <IonRadioGroup key="binaYasi" id="binaYasi" value={localStorage.getItem('binaYasi')}  onIonChange={inputChanged}  >
            {Object.keys(dataBinaYasiAll).map((item:any) =>  
                 <ListItem   key={item+''} 
                 id={item+''}  
                 label={parDict[dataBinaYasiAll[item]]['Label']}  
                 Sayim={dataBinaYasiAll.length} 
                 groupID={parDict[dataBinaYasiAll[item]]['GroupID']}  
                 selData = {'dataBinaYasiSel'}
                 spareBool={parDict[dataBinaYasiAll[item]]['SpareBool']}   />                 
                )}                 
                </IonRadioGroup> 
            </IonRow>)}

            {(parDict[dataTapuDurumuTitle!] === undefined || parDict[dataTapuDurumuTitle!] === null) ? <IonLabel>Bina Yaşı Yok</IonLabel> : (
            <IonRow key='r_TapuDurumu' id='r_TapuDurumu' > <IonCol size="4"  key='c_TapuDurumu'><IonLabel  className="propHeader">{parDict[dataTapuDurumuTitle!]['GroupName']}</IonLabel></IonCol>
            <IonRadioGroup key="tapuDurumu" id="tapuDurumu" value={localStorage.getItem('tapuDurumu')}  onIonChange={inputChanged}  >
            {Object.keys(dataTapuDurumuAll).map((item:any) =>  
                 <ListItem   key={item+''} 
                 id={item+''}  
                 label={parDict[dataTapuDurumuAll[item]]['Label']}  
                 Sayim={dataTapuDurumuAll.length} 
                 groupID={parDict[dataTapuDurumuAll[item]]['GroupID']}  
                 selData = {'dataTapuDurumuSel'}
                 spareBool={parDict[dataTapuDurumuAll[item]]['SpareBool']}   />                 
                )}                 
                </IonRadioGroup> 
            </IonRow>)}

            {(parDict[dataCepheTitle!] === undefined || parDict[dataCepheTitle!] === null) ? <IonLabel>Bina Yaşı Yok</IonLabel> : (
            <IonRow key='r_Cephe' id='r_Cephe' > <IonCol size="3"  key='c_Cephe'><IonLabel  className="propHeader">{parDict[dataCepheTitle!]['GroupName']}</IonLabel></IonCol>
            <IonRadioGroup key="cephe" id="cephe" value={localStorage.getItem('cephe')}  onIonChange={inputChanged}  >
            {Object.keys(dataCepheAll).map((item:any) =>  
                 <ListItem   key={item+''} 
                 id={item+''}  
                 label={parDict[dataCepheAll[item]]['Label']}  
                 Sayim={dataCepheAll.length} 
                 groupID={parDict[dataCepheAll[item]]['GroupID']}  
                 selData = {'dataCepheSel'}
                 spareBool={parDict[dataCepheAll[item]]['SpareBool']}   />                 
                )}                 
                </IonRadioGroup> 
            </IonRow>)}

            {(parDict[dataIsitmaTipiTitle!] === undefined || parDict[dataIsitmaTipiTitle!] === null) ? <IonLabel>Bina Yaşı Yok</IonLabel> : (
            <IonRow key='r_IsitmaTipi' id='r_IsitmaTipi' > <IonCol size="3"  key='c_IsitmaTipi'><IonLabel  className="propHeader">{parDict[dataIsitmaTipiTitle!]['GroupName']}</IonLabel></IonCol>
            <IonRadioGroup key="isitmaTipi" id="isitmaTipi" value={localStorage.getItem('isitmaTipi')}  onIonChange={inputChanged}  >
            {Object.keys(dataIsitmaTipiAll).map((item:any) =>  
                 <ListItem   key={item+''} 
                 id={item+''}  
                 label={parDict[dataIsitmaTipiAll[item]]['Label']}  
                 Sayim={dataIsitmaTipiAll.length} 
                 groupID={parDict[dataIsitmaTipiAll[item]]['GroupID']}  
                 selData = {'dataIsitmaTipiSel'}
                 spareBool={parDict[dataIsitmaTipiAll[item]]['SpareBool']}   />                 
                )}                 
                </IonRadioGroup> 
            </IonRow>)}

            {(parDict[dataKonutTipiTitle!] === undefined || parDict[dataKonutTipiTitle!] === null) ? <IonLabel>Bina Yaşı Yok</IonLabel> : (
            <IonRow key='r_KonutTipi' id='r_KonutTipi' > <IonCol size="3"  key='c_KonutTipi'><IonLabel  className="propHeader">{parDict[dataKonutTipiTitle!]['GroupName']}</IonLabel></IonCol>
            <IonRadioGroup key="konutTipi" id="konutTipi" value={localStorage.getItem('konutTipi')}  onIonChange={inputChanged}  >
            {Object.keys(dataKonutTipiAll).map((item:any) =>  
                 <ListItem   key={item+''} 
                 id={item+''}  
                 label={parDict[dataKonutTipiAll[item]]['Label']}  
                 Sayim={dataKonutTipiAll.length} 
                 groupID={parDict[dataKonutTipiAll[item]]['GroupID']}  
                 selData = {'dataKonutTipiSel'}
                 spareBool={parDict[dataKonutTipiAll[item]]['SpareBool']}   />                 
                )}                 
                </IonRadioGroup> 
            </IonRow>)}

            {(parDict[dataOdaSayisiTitle!] === undefined || parDict[dataOdaSayisiTitle!] === null) ? <IonLabel>Bina Yaşı Yok</IonLabel> : (
            <IonRow key='r_OdaSayisi' id='r_OdaSayisi' > <IonCol size="4"  key='c_OdaSayisi'><IonLabel  className="propHeader">{parDict[dataOdaSayisiTitle!]['GroupName']}</IonLabel></IonCol>
            <IonRadioGroup key="odaSayisi" id="odaSayisi" value={localStorage.getItem('odaSayisi')}  onIonChange={inputChanged}  >
            {Object.keys(dataOdaSayisiAll).map((item:any) =>  
                 <ListItem   key={item+''} 
                 id={item+''}  
                 label={parDict[dataOdaSayisiAll[item]]['Label']}  
                 Sayim={dataOdaSayisiAll.length} 
                 groupID={parDict[dataOdaSayisiAll[item]]['GroupID']}  
                 selData = {'dataOdaSayisiSel'}
                 spareBool={parDict[dataOdaSayisiAll[item]]['SpareBool']}   />                 
                )}                 
                </IonRadioGroup> 
            </IonRow>)}

            {(parDict[dataKatSayisiTitle!] === undefined || parDict[dataKatSayisiTitle!] === null) ? <IonLabel>Bina Yaşı Yok</IonLabel> : (
            <IonRow key='r_KatSayisi' id='r_KatSayisi' > <IonCol size="3"  key='c_KatSayisi'><IonLabel  className="propHeader">{parDict[dataKatSayisiTitle!]['GroupName']}</IonLabel></IonCol>
            <IonRadioGroup key="katSayisi" id="katSayisi" value={localStorage.getItem('katSayisi')}  onIonChange={inputChanged}  >
            {Object.keys(dataKatSayisiAll).map((item:any) =>  
                 <ListItem   key={item+''} 
                 id={item+''}  
                 label={parDict[dataKatSayisiAll[item]]['Label']}  
                 Sayim={dataKatSayisiAll.length} 
                 groupID={parDict[dataKatSayisiAll[item]]['GroupID']}  
                 selData = {'dataKatSayisiSel'}
                 spareBool={parDict[dataKatSayisiAll[item]]['SpareBool']}   />                 
                )}                 
                </IonRadioGroup> 
            </IonRow>)}

            {(parDict[dataKatDetayTitle!] === undefined || parDict[dataKatDetayTitle!] === null) ? <IonLabel>Bina Yaşı Yok</IonLabel> : (
            <IonRow key='r_KatDetay' id='r_KatDetay' > <IonCol size="3"  key='c_KatDetay'><IonLabel  className="propHeader">{parDict[dataKatDetayTitle!]['GroupName']}</IonLabel></IonCol>
            <IonRadioGroup key="katDetay" id="katDetay" value={localStorage.getItem('katDetay')}  onIonChange={inputChanged}  >
            {Object.keys(dataKatDetayAll).map((item:any) =>  
                 <ListItem   key={item+''} 
                 id={item+''}  
                 label={parDict[dataKatDetayAll[item]]['Label']}  
                 Sayim={dataKatDetayAll.length} 
                 groupID={parDict[dataKatDetayAll[item]]['GroupID']}  
                 selData = {'dataKatDetaySel'}
                 spareBool={parDict[dataKatDetayAll[item]]['SpareBool']}   />                 
                )}                 
                </IonRadioGroup> 
            </IonRow>)}

            {(parDict[dataBulunduguKatTitle!] === undefined || parDict[dataBulunduguKatTitle!] === null) ? <IonLabel>Bina Yaşı Yok</IonLabel> : (
            <IonRow key='r_BulunduguKat' id='r_BulunduguKat' > <IonCol size="5"  key='c_BulunduguKat'><IonLabel  className="propHeader">{parDict[dataBulunduguKatTitle!]['GroupName']}</IonLabel></IonCol>
            <IonRadioGroup key="bulunduguKat" id="bulunduguKat" value={localStorage.getItem('bulunduguKat')}  onIonChange={inputChanged}  >
            {Object.keys(dataBulunduguKatAll).map((item:any) =>  
                 <ListItem   key={item+''} 
                 id={item+''}  
                 label={parDict[dataBulunduguKatAll[item]]['Label']}  
                 Sayim={dataBulunduguKatAll.length} 
                 groupID={parDict[dataBulunduguKatAll[item]]['GroupID']}  
                 selData = {'dataBulunduguKatSel'}
                 spareBool={parDict[dataBulunduguKatAll[item]]['SpareBool']}   />                 
                )}                 
                </IonRadioGroup> 
            </IonRow>)}

            {(parDict[dataEngelliyeUygunTitle!] === undefined || parDict[dataEngelliyeUygunTitle!] === null) ? <IonLabel>Bina Yaşı Yok</IonLabel> : (
            <IonRow key='r_EngelliyeUygun' id='engelliyeUygun' > <IonCol size="3"  key='c_EngelliyeUygun'><IonLabel  className="propHeader">{parDict[dataEngelliyeUygunTitle!]['GroupName']}</IonLabel></IonCol> 
            {Object.keys(dataEngelliyeUygunAll).map((item:any) =>  
                 <ListItemCB   key={item+''} 
                 id={item+''}  
                 label={parDict[dataEngelliyeUygunAll[item]]['Label']}  
                 Sayim={dataEngelliyeUygunAll.length} 
                 groupID={parDict[dataEngelliyeUygunAll[item]]['GroupID']} 
                 spareBool={parDict[dataEngelliyeUygunAll[item]]['SpareBool']} 
                 Accessor={'engelliyeUygun'}        />           
                )} 
            </IonRow>)}

            {(parDict[dataManzaraTitle!] === undefined || parDict[dataManzaraTitle!] === null) ? <IonLabel>Bina Yaşı Yok</IonLabel> : (
            <IonRow key='Manzara' id='Manzara' > <IonCol size="3"  key='c_Manzara'><IonLabel  className="propHeader">{parDict[dataManzaraTitle!]['GroupName']}</IonLabel></IonCol> 
            {Object.keys(dataManzaraAll).map((item:any) =>  
                 <ListItemCB   key={item+''} 
                 id={item+''}  
                 label={parDict[dataManzaraAll[item]]['Label']}  
                 Sayim={dataManzaraAll.length} 
                 groupID={parDict[dataManzaraAll[item]]['GroupID']} 
                 spareBool={parDict[dataManzaraAll[item]]['SpareBool']} 
                 Accessor={'manzara'}        />           
                )} 
            </IonRow>)}

            {(parDict[dataMuhitTitle!] === undefined || parDict[dataMuhitTitle!] === null) ? <IonLabel>Bina Yaşı Yok</IonLabel> : (
            <IonRow key='r_Muhit' id='Muhit' > <IonCol size="3"  key='c_Muhit'><IonLabel  className="propHeader">{parDict[dataMuhitTitle!]['GroupName']}</IonLabel></IonCol> 
            {Object.keys(dataMuhitAll).map((item:any) =>  
                 <ListItemCB   key={item+''} 
                 id={item+''}  
                 label={parDict[dataMuhitAll[item]]['Label']}  
                 Sayim={dataMuhitAll.length} 
                 groupID={parDict[dataMuhitAll[item]]['GroupID']} 
                 spareBool={parDict[dataMuhitAll[item]]['SpareBool']} 
                 Accessor={'muhit'}        />           
                )} 
            </IonRow>)}

            {(parDict[dataUlasimTitle!] === undefined || parDict[dataUlasimTitle!] === null) ? <IonLabel>Bina Yaşı Yok</IonLabel> : (
            <IonRow key='r_Ulasim' id='Ulasim' > <IonCol size="3"  key='c_Ulasim'><IonLabel  className="propHeader">{parDict[dataUlasimTitle!]['GroupName']}</IonLabel></IonCol> 
            {Object.keys(dataUlasimAll).map((item:any) =>  
                 <ListItemCB   key={item+''} 
                 id={item+''}  
                 label={parDict[dataUlasimAll[item]]['Label']}  
                 Sayim={dataUlasimAll.length} 
                 groupID={parDict[dataUlasimAll[item]]['GroupID']} 
                 spareBool={parDict[dataUlasimAll[item]]['SpareBool']} 
                 Accessor={'ulasim'}        />           
                )} 
            </IonRow>)}

            {(parDict[dataIcOzellikTitle!] === undefined || parDict[dataIcOzellikTitle!] === null) ? <IonLabel>Bina Yaşı Yok</IonLabel> : (
            <IonRow key='r_IcOzellik' id='IcOzellik' > <IonCol size="3"  key='c_IcOzellik'><IonLabel  className="propHeader">{parDict[dataIcOzellikTitle!]['GroupName']}</IonLabel></IonCol> 
            {Object.keys(dataIcOzellikAll).map((item:any) =>  
                 <ListItemCB   key={item+''} 
                 id={item+''}  
                 label={parDict[dataIcOzellikAll[item]]['Label']}  
                 Sayim={dataIcOzellikAll.length} 
                 groupID={parDict[dataIcOzellikAll[item]]['GroupID']} 
                 spareBool={parDict[dataIcOzellikAll[item]]['SpareBool']} 
                 Accessor={'icOzellik'}        />           
                )} 
            </IonRow>)}

            {(parDict[dataDisOzellikTitle!] === undefined || parDict[dataDisOzellikTitle!] === null) ? <IonLabel>Bina Yaşı Yok</IonLabel> : (
            <IonRow key='r_DisOzellik' id='DisOzellik' > <IonCol size="3"  key='c_DisOzellik'><IonLabel  className="propHeader">{parDict[dataDisOzellikTitle!]['GroupName']}</IonLabel></IonCol> 
            {Object.keys(dataDisOzellikAll).map((item:any) =>  
                 <ListItemCB   key={item+''} 
                 id={item+''}  
                 label={parDict[dataDisOzellikAll[item]]['Label']}  
                 Sayim={dataDisOzellikAll.length} 
                 groupID={parDict[dataDisOzellikAll[item]]['GroupID']} 
                 spareBool={parDict[dataDisOzellikAll[item]]['SpareBool']} 
                 Accessor={'disOzellik'}        />           
                )} 
            </IonRow>)}


            </Fragment>) : <IonLabel>Parametre yok</IonLabel>}

        
              
          </IonGrid>
      </IonContent> 

    
  );
};

export default Sayfa1;


// const { baslik, detay, siraNo, 
//   fiyat, aidat, durumu, brutm2, netm2, depozito,bostarih,
//   sehir,mahalle,adresi, sahibi,
//   dataKiralikSatilikSel,
//   dataKategoriSel,
//   dataBinaYasiSel, 
//   dataTapuDurumuSel,
//   dataCepheSel, 
//   dataIsitmaTipiSel, 
//   dataKonutTipiSel, 
//   dataOdaSayisiSel, 
//   dataKatDetaySel, 
//   dataKatSayisiSel,
//   dataBulunduguKatSel,
//   dataManzaraSel, 
//   dataIcOzellikSel,
//   dataDisOzellikSel, 
//   dataMuhitSel,
//   dataUlasimSel, 
//   dataEngelliyeUygunSel, 

//   dataKiralikSatilikTitle,
//   dataKategoriTitle,
//   dataBinaYasiTitle, 
//   dataTapuDurumuTitle,
//   dataCepheTitle, 
//   dataIsitmaTipiTitle, 
//   dataKonutTipiTitle, 
//   dataOdaSayisiTitle, 
//   dataKatDetayTitle, 
//   dataKatSayisiTitle,
//   dataBulunduguKatTitle,
//   dataManzaraTitle, 
//   dataIcOzellikTitle,
//   dataDisOzellikTitle, 
//   dataMuhitTitle,
//   dataUlasimTitle, 
//   dataEngelliyeUygunTitle, 
//   vaadiTextTitle, 
//   yetkiTextTitle,    

// dataKiralikSatilikAll, 
// dataKategoriAll,
// dataBinaYasiAll, 
// dataTapuDurumuAll,
// dataCepheAll, 
// dataIsitmaTipiAll, 
// dataKonutTipiAll, 
// dataOdaSayisiAll, 
// dataKatDetayAll, 
// dataKatSayisiAll,
// dataBulunduguKatAll,
// dataManzaraAll, 
// dataIcOzellikAll,
// dataDisOzellikAll, 
// dataMuhitAll,
// dataUlasimAll, 
// dataEngelliyeUygunAll, 


// vaadiTextAll, 
// yetkiTextAll,      
// setDataKiralikSatilikTitle,
// setDataKategoriTitle,
// setDataBinaYasiTitle, 
// setDataTapuDurumuTitle,
// setDataCepheTitle, 
// setDataIsitmaTipiTitle, 
// setDataKonutTipiTitle, 
// setDataOdaSayisiTitle, 
// setDataKatDetayTitle, 
// setDataKatSayisiTitle,
// setDataBulunduguKatTitle,
// setDataManzaraTitle, 
// setDataIcOzellikTitle,
// setDataDisOzellikTitle, 
// setDataMuhitTitle,
// setDataUlasimTitle, 
// setDataEngelliyeUygunTitle, 
// setVaadiTextTitle, 
// setYetkiTextTitle,       
// setDataKiralikSatilikAll, 
// setDataKategoriAll,
// setDataBinaYasiAll, 
// setDataTapuDurumuAll,
// setDataCepheAll, 
// setDataIsitmaTipiAll, 
// setDataKonutTipiAll, 
// setDataOdaSayisiAll, 
// setDataKatDetayAll, 
// setDataKatSayisiAll,
// setDataBulunduguKatAll,
// setDataManzaraAll, 
// setDataIcOzellikAll,
// setDataDisOzellikAll, 
// setDataMuhitAll,
// setDataUlasimAll, 
// setDataEngelliyeUygunAll, 
// setVaadiTextAll, 
// setYetkiTextAll,  propDetay,propDetayDict,imageAsUrlDict, parDict, saveStateVals, setSiraNo} = useTest();

