import React from 'react';
import { IonContent } from '@ionic/react';




const GirisYap: React.FC = () => {
  return (

      <IonContent>
           <br/>
    <p className="mesaj">1. EMLAK SEÇ. Lütfen önce [GİRİŞ / YENİ] menüsünden sırano girip bir emlak seçiniz</p>
  
    <p className="mesaj">veya [GİRİŞ / YENİ / YENİ KAYIT AÇ] düğmesin kullanınız.</p>
   
    <p className="mesaj">2. GİRİŞ YAP. Sisteme giriş yapmadı iseniz [GİRİŞ / GİRİŞ] giriş yapınız.</p>
  

      </IonContent>
   
  );
};

export default GirisYap;