import React from 'react';
import { IonPage,  IonContent} from '@ionic/react'
import {  RouteComponentProps } from 'react-router-dom';

// import { add, atCircleOutline, cameraOutline, flashOutline, flowerOutline, folderOpenOutline, 
//   funnel, help, home, images, informationCircleOutline, logoAndroid, logoHtml5, logoTwitter, mapOutline, 
//   pencilOutline, personCircle, scanCircleSharp, search, square, squareOutline, triangle } from 'ionicons/icons';
import  './../pages/estate/LoginModal.css';
import './Tab3.css';
import Tab1EmlakList from './Tab1EmlakList';
import HeaderAll from './estate/HeaderAll';
import FooterAll from './estate/FooterAll';

const pagesListPage: React.FC<RouteComponentProps> = ({history}) => {


  const goToPage = (e: any, urlPath: any) => {
    e.preventDefault(); 
    history.push(urlPath);
  }



  return (
  
          <IonPage>
      <HeaderAll goToPage={goToPage}/>
      <IonContent>

          <Tab1EmlakList /> 

        </IonContent>

       <FooterAll goToPage={goToPage}/>  

    </IonPage>

  );
};

export default pagesListPage;





// {/*    <Fragment >  </Fragment>
//                   <IonButtons slot="secondary">
//                     <IonButton id="btnLogin" onClick={handleLogin}>
//                       <IonIcon slot="icon-only" icon={personCircle}  onClick={handleLogin}/>
//                     </IonButton>
//                     </IonButtons>


//                   <IonButtons slot="end">
//                     <IonButton id="cmdSideMenu" color="danger" onClick={handleSideMenu2}>
//                       <IonIcon  onClick={handleSideMenu2} slot="icon-only" ios={search} md={search} />
//                     </IonButton>
//                   </IonButtons>

//                   <IonButtons slot="end">
//                   <IonButton id="cmdSideMenu" color="danger" onClick={handleSideMenu}>
//                     <IonIcon  onClick={handleSideMenu} slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical} />
//                   </IonButton>
//                 </IonButtons> */}



        // {/* <IonItem routerLink="/dashboard/pages/1">
        //   <IonLabel>User 1</IonLabel>
        // </IonItem>

        // <IonItem>
        //   <Link to="/dashboard/pages/2">  <IonLabel>User 2</IonLabel>  </Link>
        // </IonItem>

        // <IonItem>
        //   <IonButton onClick={e => {
        //     e.preventDefault();
        //     history.push('/dashboard/pages/3')
        //   }}>
        //     <IonLabel>User 3</IonLabel>
        //   </IonButton>
        // </IonItem> */}
      

      //   <IonHeader className="theader">
      //   <IonToolbar className="tbar" color="light">
      //           <IonImg className="genislogo" src='assets/imgs/alkagenis2.jpg' alt="yeni"></IonImg>
      //       <IonButton key="btn1" onClick={e => { e.preventDefault(); history.push('/dashboard') }}>
      //      <IonIcon   slot="icon-only" ios={home} md={home} />   </IonButton>
      
      //      <IonButton key="btn2" onClick={e => { e.preventDefault(); history.push('/dashboard/pages/2') }}>
      //      <IonIcon   slot="icon-only" ios={search} md={search} />   </IonButton>
      
      //      <IonButton key="btn3" onClick={e => { e.preventDefault(); history.push('/dashboard/privacy') }}>
      //      <IonIcon   slot="icon-only" ios={mapOutline} md={mapOutline} />   </IonButton>
      
      //      <IonButton key="btn4" onClick={e => { e.preventDefault(); history.push('/dashboard/pages/4') }}>
      //      <IonIcon   slot="icon-only" ios={atCircleOutline} md={atCircleOutline} />   </IonButton>    
      //      <IonButton key="btn5" onClick={e => { e.preventDefault(); history.push('/dashboard/pages/5') }}>
      //      <IonIcon   slot="icon-only" ios={personCircle} md={personCircle} />   </IonButton>
      
      //      <IonButton key="btn6" onClick={e => { e.preventDefault(); history.push('/dashboard/pages/6') }}>
      //      <IonIcon   slot="icon-only" ios={add} md={add} />   </IonButton>
      
      //      <IonButton key="btn7" onClick={e => { e.preventDefault(); history.push('/dashboard/pages/7') }}>
      //      <IonIcon   slot="icon-only" ios={logoAndroid} md={logoAndroid} />   </IonButton>
      
      //      <IonButton key="btn8" onClick={e => { e.preventDefault(); history.push('/dashboard/pages/8') }}>
      //      <IonIcon   slot="icon-only" ios={logoTwitter} md={logoTwitter} />   </IonButton>
      
      //      <IonButton key="btn9" onClick={e => { e.preventDefault(); history.push('/dashboard/pages/9') }}>
      //      <IonIcon   slot="icon-only" ios={logoHtml5} md={logoHtml5} />   </IonButton>
      //   </IonToolbar> 
      // </IonHeader>