import React, { Fragment } from 'react';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonBackButton, IonButtons } from '@ionic/react'
import { RouteComponentProps } from 'react-router';
// import Tab1EmlakList from './Tab1EmlakList';
// import Privacy from './estate/privacy/Privacy';


interface UserDetailPageProps extends RouteComponentProps<{
  id: string;
}> {}

const showPage = (id: string) => {
  switch (id) {
    case '1':
      return (<Fragment>     </Fragment>);
      case '2':
        return (<Fragment>   pages {id}   </Fragment>);
        case '3':
          return <Fragment>  pages {id}    </Fragment>;
    default:
      return (<Fragment>  pages {id}    </Fragment>);
  } 
}

const showPageTitle = (id: string) => {
  switch (id) {
    case '1':
      return (<Fragment> </Fragment>);
      case '2':
        return (<Fragment>  pages {id}    </Fragment>);
        case '3':
          return (<Fragment>  Privacy  </Fragment>);
    default:
      return (<Fragment> pages {id}   </Fragment>);
  } 
}

const UserDetailPage: React.FC<UserDetailPageProps> = ({match, history}) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
          <IonBackButton />
          </IonButtons>
          <IonTitle>{showPageTitle(match.params.id)}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
         
        {showPage(match.params.id)}
        
      </IonContent>
     </IonPage>

  );
};

export default UserDetailPage;