




import React, { Fragment } from 'react';
import {  RouteComponentProps } from 'react-router-dom';
import {  IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonPage } from '@ionic/react';
import { IonContent } from '@ionic/react';

// import HeaderToolBarPublic from '../HeaderToolBarPublic';
// import {  backspaceSharp } from 'ionicons/icons';

// import { useUser } from '../../../context/UserContext';
import HeaderAll from '../HeaderAll';
// import FooterAll from '../FooterAll';


const Privacy: React.FC<RouteComponentProps> = ({match, history}) => {

  const siteName = "Alkaemlak.com";


const metin = () => {  return <Fragment>

<p> <b> Sürüm 1.0. Son Güncelleme 01.10.2020</b></p>
      <p>
      {siteName + 'dan '} erişilebilen  {siteName + 'da '} ana önceliklerimizden biri ziyaretçilerimizin mahremiyetidir. 
      Bu Gizlilik Politikası belgesi, {siteName + ' '} tarafından toplanan ve kaydedilen bilgi türlerini ve bunları nasıl 
      kullandığımızı içerir.
     </p><p>

  Ek sorularınız varsa veya Gizlilik Politikamız hakkında daha fazla bilgiye ihtiyacınız varsa, bizimle iletişime geçmekten çekinmeyin.
  </p><p>
  Bu Gizlilik Politikası sadece online faaliyetlerimiz için geçerlidir ve {siteName + 'da '} paylaştıkları ve / veya topladıkları bilgilerle ilgili olarak 
  web sitemizi ziyaret edenler için geçerlidir. 
  Bu politika, çevrimdışı olarak veya bu web sitesi dışındaki kanallar aracılığıyla toplanan hiçbir bilgi için geçerli değildir. 
 
  </p><p>
  <b>Razı olmak </b></p><p>
  Web sitemizi kullanarak, Gizlilik Politikamıza izin veriyor ve şartlarını kabul ediyorsunuz.
  </p><p>
  <b> Topladığımız bilgiler  </b></p><p>
  Sizden vermeniz istenen kişisel bilgiler ve vermenizin istenmesinin nedenleri, sizden kişisel bilgilerinizi vermenizi istediğimiz noktada size açıklanacaktır.
  </p><p>
  Bizimle doğrudan iletişime geçerseniz, adınız, e-posta adresiniz, telefon numaranız, bize gönderebileceğiniz mesaj içeriği ve / veya ekleri ve 
  vermeyi seçebileceğiniz diğer bilgiler gibi sizinle ilgili ek bilgiler alabiliriz.
  </p><p>
  Bir Hesap için kaydolduğunuzda, ad, şirket adı, adres, e-posta adresi ve telefon numarası gibi öğeler dahil olmak üzere iletişim bilgilerinizi isteyebiliriz.
  </p><p>
  <b>Bilgilerinizi nasıl kullanıyoruz  </b></p><p>
  Topladığımız bilgileri aşağıdakiler dahil olmak üzere çeşitli şekillerde kullanırız:
  </p><p>
  Web sitemizi sağlayın, çalıştırın ve bakımını yapın<br/>
  Web sitemizi geliştirin, kişiselleştirin ve genişletin<br/>
  Web sitemizi nasıl kullandığınızı anlayın ve analiz edin<br/>
  Yeni ürünler, hizmetler, özellikler ve işlevler geliştirin<br/>
  Size web sitesine ilişkin güncellemeleri ve diğer bilgileri sağlamak ve pazarlama ve promosyon amaçları için müşteri hizmetleri dahil olmak üzere 
  doğrudan veya ortaklarımızdan biri aracılığıyla sizinle iletişim kurmak<br/>
  Sana e-posta gönderimi<br/>
  Dolandırıcılığı bulun ve önleyin<br/>
  Log dosyaları<br/>
  {siteName + ' '}, günlük dosyalarını kullanma konusunda standart bir prosedür izler. Bu dosyalar, ziyaretçileri web sitelerini ziyaret ettiklerinde kaydeder. 
  Tüm barındırma şirketleri bunu yapar ve barındırma hizmetlerinin analizinin bir parçasıdır. Günlük dosyaları tarafından toplanan bilgiler arasında 
  internet protokolü (IP) adresleri, tarayıcı türü, İnternet Servis Sağlayıcısı (ISP), tarih ve saat damgası, yönlendirme / çıkış sayfaları ve muhtemelen 
  tıklama sayısı bulunur. Bunlar kişisel olarak tanımlanabilir herhangi bir bilgi ile bağlantılı değildir. Bilgilerin amacı eğilimleri analiz etmek, 
  siteyi yönetmek, kullanıcıların web sitesindeki hareketlerini izlemek ve demografik bilgi toplamaktır.
  </p><p>
  <b>Çerezler ve Web İşaretçileri </b></p><p>
  Diğer tüm web siteleri gibi, {siteName + 'da '} 'çerezler' kullanır. Bu çerezler, ziyaretçilerin tercihleri ​​ve ziyaretçinin eriştiği veya
   ziyaret ettiği web sitesindeki sayfalar dahil olmak üzere bilgileri depolamak için kullanılır. Bilgiler, web sayfası içeriğimizi ziyaretçilerin 
   tarayıcı türüne ve / veya diğer bilgilere göre özelleştirerek kullanıcıların deneyimini optimize etmek için kullanılır.
   </p><p>
  Çerezler hakkında daha genel bilgi için lütfen "Çerez Nedir" bölümünü okuyun.
  </p><p>
  <b>Google DoubleClick DART Çerezi </b></p><p>
  Google, sitemizdeki üçüncü taraf satıcılardan biridir. Ayrıca, www.website.com ve internetteki diğer sitelere yaptıkları ziyaretlere dayalı olarak 
  site ziyaretçilerimize reklam sunmak için DART çerezleri olarak bilinen çerezleri kullanır. Bununla birlikte, ziyaretçiler aşağıdaki URL'de Google 
  reklam ve içerik ağı Gizlilik Politikası'nı ziyaret ederek DART çerezlerinin kullanımını reddetmeyi seçebilirler - 
  https://policies.google.com/technologies/ads
  </p><p>
  <b>Reklam Ortakları Gizlilik Politikaları </b></p><p>
  {siteName + 'un '} her reklam ortağının Gizlilik Politikasını bulmak için bu listeye başvurabilirsiniz.
  </p><p>
  Üçüncü taraf reklam sunucuları veya reklam ağları, doğrudan kullanıcıların tarayıcısına gönderilen {siteName + 'da '} görünen ilgili reklamlarında 
  ve bağlantılarında kullanılan çerezler, JavaScript veya Web İşaretçileri gibi teknolojileri kullanır. 
  Bu gerçekleştiğinde IP adresinizi otomatik olarak alırlar. Bu teknolojiler, reklam kampanyalarının etkinliğini ölçmek ve / veya ziyaret ettiğiniz 
  web sitelerinde gördüğünüz reklam içeriğini kişiselleştirmek için kullanılır.
  </p><p>
  {siteName + 'un '} üçüncü taraf reklamcılar tarafından kullanılan bu çerezlere erişimi veya kontrolü olmadığını unutmayın.
  </p><p>
  <b>Üçüncü Taraf Gizlilik Politikaları </b></p><p>
  {siteName + 'un '}Gizlilik Politikası diğer reklam verenler veya web siteleri için geçerli değildir. Bu nedenle, daha ayrıntılı bilgi için bu üçüncü 
  taraf reklam sunucularının ilgili Gizlilik Politikalarına başvurmanızı tavsiye ediyoruz. Onların uygulamalarını ve belirli seçeneklerden nasıl 
  vazgeçileceğine dair talimatları içerebilir.
  </p><p>
  Kişisel tarayıcı seçenekleriniz aracılığıyla çerezleri devre dışı bırakmayı seçebilirsiniz. Belirli web tarayıcıları ile çerez yönetimi hakkında 
  daha ayrıntılı bilgi edinmek için, tarayıcıların ilgili web sitelerinde bulunabilir.
  </p><p>
  <b>CCPA Gizlilik Hakları (Kişisel Bilgilerimi Satmayın)</b> </p><p>
  CCPA kapsamında, diğer hakların yanı sıra, Kaliforniya tüketicileri şu haklara sahiptir:
  </p><p>
  Tüketicinin kişisel verilerini toplayan bir işletmenin, bir işletmenin tüketiciler hakkında topladığı kategorileri ve belirli kişisel 
  veri parçalarını ifşa etmesini isteyin.
  </p><p>
  Bir işletmenin, bir işletmenin topladığı tüketici hakkındaki tüm kişisel verileri silmesini isteyin.
  </p><p>
  Tüketicinin kişisel verilerini satan bir işletmenin, tüketicinin kişisel verilerini satmamasını talep edin.
  </p><p>
  Bir istekte bulunursanız, size yanıt vermek için bir ayımız var. Bu haklardan herhangi birini kullanmak isterseniz, lütfen bizimle iletişime geçin.
  </p><p>
  <b>GDPR Veri Koruma Hakları </b></p><p>
  Tüm veri koruma haklarınızdan tam olarak haberdar olduğunuzdan emin olmak istiyoruz. Her kullanıcı aşağıdaki haklara sahiptir:
  </p><p>
  <b>Erişim hakkı </b>- Kişisel verilerinizin kopyalarını talep etme hakkına sahipsiniz. Bu hizmet için sizden küçük bir ücret alabiliriz.
  </p><p>
  <b>Düzeltme hakkı </b>- Yanlış olduğuna inandığınız herhangi bir bilgiyi düzeltmemizi talep etme hakkına sahipsiniz. Ayrıca, eksik olduğuna inandığınız 
  bilgileri tamamlamamızı talep etme hakkına da sahipsiniz.
  </p><p>
  <b>Silme hakkı </b>- Belirli koşullar altında kişisel verilerinizi silmemizi talep etme hakkına sahipsiniz.
  </p><p>
  <b>İşlemeyi kısıtlama hakkı </b>- Belirli koşullar altında kişisel verilerinizin işlenmesini kısıtlamamızı talep etme hakkına sahipsiniz.
  </p><p>
  <b>İşlemeye itiraz etme hakkı </b>- Belirli koşullar altında kişisel verilerinizi işlememize itiraz etme hakkına sahipsiniz.
  </p><p>
 <b> Veri taşınabilirliği hakkı </b>- Topladığımız verileri başka bir kuruluşa veya belirli koşullar altında doğrudan size aktarmamızı talep etme hakkına sahipsiniz.
  </p><p>
  Bir istekte bulunursanız, size yanıt vermek için bir ayımız var. Bu haklardan herhangi birini kullanmak isterseniz, lütfen bizimle iletişime geçin.
  </p><p>
  <b>Çocuk Bilgileri </b></p><p>
  Önceliğimizin bir diğer parçası da interneti kullanırken çocuklar için koruma sağlamak. Ebeveynleri ve velileri çevrimiçi etkinliklerini gözlemlemeye, 
  katılmaya ve / veya izlemeye ve yönlendirmeye teşvik ediyoruz.
  </p><p>
  {siteName + ' '}, 13 yaşın altındaki çocuklardan bilerek Kişisel Tanımlanabilir Bilgi toplamaz. Çocuğunuzun web sitemizde bu tür bilgileri sağladığını 
  düşünüyorsanız, derhal bizimle iletişime geçmenizi şiddetle tavsiye ederiz ve elimizden gelenin en iyisini yapacağız. bu tür bilgileri kayıtlarımızdan 
  derhal kaldırılıcaktır.
</p>


</Fragment>
}




const goToPage = (e: any, urlPath: any) => {
  e.preventDefault(); 
  history.push(urlPath);
}
  
  
  return (
<IonPage>
 <HeaderAll goToPage={goToPage}  /> 
<IonContent>
  
  
        <IonCard>
                <IonCardHeader>
                  <IonCardSubtitle>Gizlilik</IonCardSubtitle>
                  <IonCardTitle>Privacy</IonCardTitle>
                </IonCardHeader>
  
                <IonCardContent>
                 {metin()}  
  
                </IonCardContent>
          </IonCard> 
  
      </IonContent>
      {/* <FooterAll goToPage={goToPage}  /> */}

</IonPage>
    );
  
  
  
  };
  
  export default Privacy;
