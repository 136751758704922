import React from 'react';
import { IonContent,  IonPage, IonCard, IonCardHeader, 
   IonCardTitle, IonCardContent,  IonSlides, IonSlide, IonLabel } from '@ionic/react';
// import ExploreContainer from '../components/ExploreContainer';
import './../../Tab3.css';
import {  RouteComponentProps } from 'react-router-dom';
import HeaderAll from '../HeaderAll';
// import FooterAll from '../FooterAll';
// import { Geolocation} from '@capacitor/core';




const SideMenu2: React.FC<RouteComponentProps> = ({match, history}) => {


  // let latitude: number = 395174;
  // let longitude: number = 7920743;

  const sliderOpts = {
    zoom: {
      maxRatio: 4
    }
  }

  const goToPage = (e: any, urlPath: any) => {
    e.preventDefault(); 
    history.push(urlPath);
  }



  return (
    <IonPage>

<HeaderAll goToPage={goToPage}/>
      <IonContent>


      <IonCard>
          <IonCardHeader>
          <IonCardTitle><h1> İletişim: alkaemlak.com </h1></IonCardTitle><br/>
          <IonCardTitle>Alka Emlak - Erdek </IonCardTitle><br/>
    
  

          </IonCardHeader>

          <IonCardContent>

<IonLabel>sidemenu2</IonLabel>

      </IonCardContent>

        </IonCard>
        <IonContent>

   
 <IonSlides options={sliderOpts}> 
 <IonSlide>
   

<img className="harita" src='assets/imgs/alkaemlakharita.png' alt="alkaemlak.com erdek harita"></img>



   
   </IonSlide>



 </IonSlides>

                   {/* <ExploreContainer name="Alka Emlak İletişim Bilgileri" /> */}


                   </IonContent>
      
        
      </IonContent>
      {/* <FooterAll goToPage={goToPage}/> */}
    </IonPage>
  );
};
// 40.395174,27.7920743,17
export default SideMenu2;