


import { IonContent, IonButton, IonCard, IonCardHeader,  IonCardTitle, IonCardContent} from '@ionic/react';

// import { personCircle,  search, ellipsisHorizontal, ellipsisVertical,helpCircle,create, star } from 'ionicons/icons';

import './../Tab1.css';
import  './LoginModal.css';
// import HeaderToolBar from './HeaderToolBar';

import React, { useState, useEffect } from 'react';
import {    IonInput, IonItem, IonLabel, IonList } from '@ionic/react';
//  import {storage} from "./../../firebase/firebase";
import {loginUser} from  "../../firebase/firebase";

// import { isNull } from 'util';
import { useUser } from '../../context/UserContext';


const Login = () => {

  // const [* as store] = useContext(AppContext);

  const [text, setText] = useState('');
  // const [number, setNumber] = useState<number>();
  // // const [input, setInput] = useState<string>('');
  const [email, setEmail] = useState('');
  const [password, setPassword] =useState('');
  const { setUser, setUserShort, setPathUser, setIsLogged} = useUser();

  useEffect(() => {
      // console.log(email);
      // console.log(auth);
      return () => {
          // cleanup
      }
  }, [email]);

  function setEmailState(e){
    setEmail(e.target.value);
  }
  function setPasswordState(e){
    setPassword(e.target.value);
  }

  async function login(){
    const res = await loginUser(email, password);  
    if (res !== null){
        let userShort = email.substr(0,email.indexOf('@'));
        setUserShort(userShort);
        setUser(email);
        setPathUser('estate/'+userShort+'/');
        setIsLogged(true);
        localStorage.setItem('iletisimail',email);
        alert('*** SİSTEME GİRİŞ BAŞARILI ***')
    }else{
      setUser('');
      setUserShort('test')
      setPathUser('estate/test/')
      setIsLogged(false);
      alert('EPOSTA VEYA ŞİFRE YANLIŞ. TEKRAR DENEYİNİZ.')
    }        
    // console.log(res);
  }
// too logout <IonInput value={email}  setPasswordState  onIonChange={(e)=> setEmail(e.target.value)}></IonInput>
  return (   
    // <IonPage>
    //   <HeaderToolBar mainTitle={'Login'}></HeaderToolBar>value={email} 
      <IonContent>

      <IonCard className="LoginCard">
      <IonCardHeader>

        <IonCardTitle>SİSTEME GİRİŞ</IonCardTitle>
        <IonCardTitle>EMAİL VE ŞİFRE GİRİNİZ</IonCardTitle>
      </IonCardHeader>

      <IonCardContent>

        <IonList>
        <IonItem> 
        </IonItem>
        <IonItem>
            <IonLabel position="fixed">Email</IonLabel>
            <IonInput  onIonChange={setEmailState} 
            className="logininputozel"
            value={email}
            ></IonInput>
          </IonItem>

          <IonItem> 
          </IonItem>
          <IonItem>
            <IonLabel position="fixed">Şifre</IonLabel>
            <IonInput onIonChange={setPasswordState} className="logininputozel" type="password"
            value={password}
            ></IonInput>
          </IonItem>


          <IonItem> 
          </IonItem>

          <IonItem> 
          <IonButton id="cmdGiris" onClick={login} size="large" color="danger">Giriş</IonButton>
         
         
          </IonItem>

        </IonList>




  </IonCardContent>
    </IonCard>

    


      </IonContent>
    // </IonPage>
  );
};


export default Login;

//<IonLabel position="fixed">{JSON.stringify(user)}</IonLabel>  <IonButton /*onClick={createPDF} */ color="danger">İptal</IonButton>
// <IonItemDivider>Default Input with Placeholder</IonItemDivider>
// <IonItem>
//   <IonInput value={text} placeholder="Enter Input" onIonChange={e => setText(e.detail.value!)}></IonInput>
// </IonItem>

// <IonItemDivider>Input with clear button when there is a value</IonItemDivider>
// <IonItem>
//   <IonInput value={text} placeholder="Enter Input" onIonChange={e => setText(e.detail.value!)} clearInput></IonInput>
// </IonItem>

// <IonItemDivider>Number type input</IonItemDivider>
// <IonItem>
//   <IonInput type="number" value={number} placeholder="Enter Number" onIonChange={e => setNumber(parseInt(e.detail.value!, 10))}></IonInput>
// </IonItem>

// <IonItemDivider>Disabled input</IonItemDivider>
// <IonItem>
//   <IonInput value={text} disabled></IonInput>
// </IonItem>

// <IonItemDivider>Readonly input</IonItemDivider>
// <IonItem>
//   <IonInput value={text} readonly></IonInput>
// </IonItem>

// <IonItemDivider>Inputs with labels</IonItemDivider>

// <IonItem>
//   <IonLabel>Default Label</IonLabel>
//   <IonInput></IonInput>
// </IonItem>

// <IonItem>
//   <IonLabel position="floating">Floating Label</IonLabel>
//   <IonInput value={text}></IonInput>
// </IonItem>

// <IonItem>
//   <IonLabel position="fixed">Fixed Label</IonLabel>
//   <IonInput value={text}></IonInput>
// </IonItem>

// <IonItem>
//   <IonLabel position="stacked">Stacked Label</IonLabel>
//   <IonInput value={text}> </IonInput>
// </IonItem>