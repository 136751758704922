

import React  from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import {  IonRouterOutlet } from '@ionic/react';

import UserDetailPage from './UserDetailPage';
import UsersListPage from './UsersListPage';


import TermsAndConditions from './estate/privacy/TermsAndConditions';

import ContactUs from './ContactUs';

import SearchPage from './estate/deneme/SearchPage';
import Privacy from './estate/privacy/Privacy';
import TermsOfUse from './estate/privacy/TermsOfUse';
import Tab3GirisMenu from './Tab3GirisMenu';
import GuardedRoute from '../GuardedRoute';
import { useUser } from '../context/UserContext';
import Tab2 from './Tab2Camera';
import SideMenu1 from './estate/sidemenu/SideMenu1';
import SideMenu2 from './estate/sidemenu/SideMenu2';
import SideMenu3 from './estate/sidemenu/SideMenu3';



const DashboardPage: React.FC<RouteComponentProps> = ({match, history}) => {

  const {isLogged, pathUser} = useUser();

  return (


    <IonRouterOutlet>

        <GuardedRoute  exact path={match.url+'/camera'} component={Tab2} auth={isLogged} />
        <GuardedRoute  exact path={match.url+'/giris'} component={Tab3GirisMenu} auth={isLogged} />

        <Route path={match.url+"/sideMenu1"} component={SideMenu1} exact />
      <Route path={match.url+"/sideMenu2"} component={SideMenu2} exact />
      <Route path={match.url+"/sideMenu3"} component={SideMenu3} exact />

       <Route exact path={match.url+'/tofuse'} component={TermsOfUse} />
       <Route exact path={match.url+'/privacy'} component={Privacy} />
      <Route exact path={match.url+'/ara'} component={SearchPage} />
      <Route exact path={match.url+'/contactus'} component={ContactUs} />
      <Route exact path={match.url+'/terms'} component={TermsAndConditions} />
      <Route exact path={match.url} component={UsersListPage} />
      <Route path={`${match.url}/pages/:id`} component={UserDetailPage} />
    </IonRouterOutlet>




  );
};

export default DashboardPage


// {/* <IonTabs>
// <IonTabBar slot="bottom">

// <IonTabButton tab="tab1" href="/dashboard/users/6">
//   <IonIcon icon={triangle} />
//   <IonLabel>AnaSayfa</IonLabel>
// </IonTabButton>

// <IonTabButton tab="tab3" href="/dashboard/users/7">
//   <IonIcon icon={square} />
//   <IonLabel>Giriş</IonLabel>
// </IonTabButton>

// <IonTabButton tab="tab2" href="/dashboard/users/8">
//   <IonIcon icon={images} />
//   <IonLabel>Kamera</IonLabel>
// </IonTabButton>

// <IonTabButton tab="tab4" href="/dashboard/users/9">
//   <IonIcon icon={squareOutline} />
//   <IonLabel>İletişim</IonLabel>
// </IonTabButton>

// </IonTabBar></IonTabs> */}
