import React, { useState, useEffect } from 'react';
import {  RouteComponentProps } from 'react-router-dom';
import {  IonPage, IonButton, IonIcon, IonButtons} from '@ionic/react';
import { IonSearchbar,  IonContent } from '@ionic/react';
// import UserDetailPage from './UserDetailPage';
// import UsersListPage from './UsersListPage';
// import HeaderToolBarPublic from '../HeaderToolBarPublic';
import { searchCircleSharp } from 'ionicons/icons';
import   './Dashboard.css';
import { useTest } from '../../../context/TestContext';
// import { useUser } from '../../../context/UserContext';
import DList from './DList';
// import { ItemSlidingExample } from './Expandable';
import HeaderAll from '../HeaderAll';
// import FooterAll from '../FooterAll';

const SearchPage: React.FC<RouteComponentProps> = ({match, history}) => {

  const {propDetayDict,  propDetay} = useTest();
  // const {setIsSearch } = useUser();

  const [search, setSearch] = useState(''); 
  const [refresh, setRefresh] = useState(true);
  const [propList, setPropList] = useState(null);



  function thumbClicked(e: any){
    let alt = e.target.alt;
    let name = e.target.id;
    let ext = 'mainpic'+name.substr(0,name.indexOf('_'));
    // console.log(' thumbClicked '+name+' '+alt+' '+ext);    

    let control = document.querySelector('ion-img[name="'+ext+'"]')
    if (control!==undefined && control!==null) {
        // control.src = alt;
        // console.log(' mainpic '+control.id);
    }    
  };


  const filterFilter = (kriter: string) => {
    // let tempArr: [];
    // return tempArr?;
    // propDetay.map((profile: any) =>  {
          
    //           Object.keys(profile['detail']).map((item: any) =>  {
    //             let pro = {'siraNo': item, {

    //             }};
    //                   // Object.keys(imageAsUrlDict[item]).map((data: any, index: number) => (
    //                   //         (index===0) ?         
    //                   //   <IonItem key={data+'iii'}>

    //                   //   <IonAvatar key={data} className="ionthumbavatar">
    //                   //     <IonImg src={imageAsUrlDict[item][data].projectlink} 
    //                   //     alt={imageAsUrlDict[item][data].projectlink}
    //                   //     onClick={props.thumbClicked}
    //                   //     id={imageAsUrlDict[item][data].propid+'_'+data+'_thumb'}  className="ionthumbpic"
    //                   //     />
    //                   //   </IonAvatar>

    //                   // <IonLabel key={data+'il'}> {' ('+item + ') : '}
    //                   // {propDetayDict[item][item].baslik + ' : ' + propDetayDict[item][item].detay+' : '+propDetayDict[item][item].sehir}
    //                   // </IonLabel>

    //                   // </IonItem> : null

    //                   // ))
    //                   // tempArr.push(pro?);  
    //           });
    //         //  
    //       }  
    // )
  }

  function handleSearch(e: any){
    e.preventDefault();
    // console.log(' ion change ');
    // let sBar = document.getElementById('mainsearchbar');
    let inputValue: any;
    e.target.getInputElement().then((input: any) => {
          inputValue = input.value; // Value inputted by user in the searchbox        
          inputValue = inputValue.trim(); // To remove all whitespace characters
        // console.log(inputValue);
        setSearch(inputValue);
      })
      if (inputValue !== null && inputValue !==undefined) { // set filter
        // setRefresh(true);
        setSearch('');
      } else { // remove filter
        // setRefresh(true);
      }    
  };

  function handleAra(e:any){
  e.preventDefault();
  // console.log(' ion handle ara ');
  setRefresh(true);
  };

  const goToPage = (e: any, urlPath: any) => {
    e.preventDefault(); 
    history.push(urlPath);
  }

  useEffect(() => {
    // console.log(' dashboard use effect FALSE');
    if (refresh) {
      // console.log(' dashboard use effect TRUE search '+JSON.stringify(search));
      (propDetay && setPropList(propDetayDict));
      setRefresh(false);
    }
  },[])

  return (
    <IonPage>
      <HeaderAll goToPage={goToPage}/> 
    <IonContent>

    <IonButtons className="searchbarplus">

      <IonSearchbar  id="mainsearchbar" showCancelButton="focus" color="danger"   
          cancelButtonText="Temizle"  /* value="kira 2"*/  debounce={500} animated
          placeholder=" Kira 2 / Sat 4 / Proje    gibi bir kaç kelime giriniz" value={search} onIonChange={handleSearch}
          className="searchbarplus2" /*onKeyUpCapture={handleSearch}     */    
          ></IonSearchbar>
      <IonButton  color="danger"   id="searchbarplus3"  onClick={handleAra} >   <IonIcon slot="start" icon={searchCircleSharp} />    Ara   </IonButton>
      </IonButtons>   
      <IonContent>
        <DList thumbClicked={thumbClicked} setRefresh={setRefresh} refresh={refresh}  search={search} 
        propList={propList} />
      </IonContent>
    </IonContent>
 

      {/* <FooterAll goToPage={goToPage}/>  */}
    </IonPage>
 );
};

export default SearchPage;


  //  {/*    <IonRouterOutlet>
  //     <Route exact path={match.url} component={UsersListPage} />
  //     <Route path={`${match.url}/users/:id`} component={UserDetailPage} />
  //   </IonRouterOutlet> */}

  // <IonList>
  // {Object.keys(propDetayDict).map((item: any)=> 
  //     <IonItem key={item+'iii'}>


//   <IonList>
//   {propDetay.map((profile: any) =>       

//             Object.keys(profile['detail']).map((item: any) => (   

//                     Object.keys(imageAsUrlDict[item]).map((data: any, index: number) => (
//                             (index===0) ?         
//                       <IonItem key={data+'iii'}>

//                       <IonAvatar key={data} className="ionthumbavatar">
//                         <IonImg src={imageAsUrlDict[item][data].projectlink} 
//                         alt={imageAsUrlDict[item][data].projectlink}
//                         onClick={thumbClicked}
//                         id={imageAsUrlDict[item][data].propid+'_'+data+'_thumb'}  className="ionthumbpic"
//                         />
//                       </IonAvatar>

//                     <IonLabel key={data+'il'}> {' ('+item + ') : '}
//                     {propDetayDict[item][item].baslik + ' : ' + propDetayDict[item][item].detay+' : '+propDetayDict[item][item].sehir}
//                      </IonLabel>

//                     </IonItem> : null

//                     ))
//           ))        
//     )}
// </IonList> 


  //       {/* <IonAvatar slot="start"  key={item+'av'}>
  //           <IonImg src={imageAsUrlDict[item][item].projectlink} 
  //               alt={imageAsUrlDict[item][item].projectlink}
  //               onClick={thumbClicked}
  //               id={imageAsUrlDict[item][item].propid+'_'+item+'_thumb'}
  //               className="ionthumbpic"
  //               />
  //     </IonAvatar> */}
  //   <IonLabel key={item+'il'}>{'('+item+') : '+JSON.stringify(imageAsUrlDict[item][0])}  
  //   {propDetayDict[item][item].baslik} 
  //   {propDetayDict[item][item].detay}{+' - '+propDetayDict[item][item].sehir}
  //   </IonLabel>
  // </IonItem>   
  //   )}      </IonList> 


//   <IonThumbnail key={data} className="ionthumb">
//   <IonImg src={imageAsUrlDict[item][data].projectlink} 
//   alt={imageAsUrlDict[item][data].projectlink}
//   onClick={thumbClicked}
//   id={imageAsUrlDict[item][data].propid+'_'+data+'_thumb'}
//   className="ionthumbpic"
//   />
// </IonThumbnail>

// <IonAvatar key={data} className="ionthumbavatar">
//   <IonImg src={imageAsUrlDict[item][data].projectlink} 
//   alt={imageAsUrlDict[item][data].projectlink}
//   onClick={thumbClicked}
//   id={imageAsUrlDict[item][data].propid+'_'+data+'_thumb'}
//   className="ionthumbpic"
//   />
// </IonAvatar>


                    //  <IonItem key={profile+'iii'}>
                      // <IonLabel key={profile+'il'}> {JSON.stringify( profile['detail'] )}</IonLabel>
                      // </IonItem>

                      // tends React.Component {
                      //   constructor() {
                      //     super();
                      //     this.state = {
                      //       data: ["Bulbasaur","Ivysaur","Venusaur","Charmander","Charmeleon","Charizard","Squirtle","Wartortle","Blastoise","Caterpie","Metapod","Butterfree","Weedle","Kakuna","Beedrill","Pidgey","Pidgeotto","Pidgeot","Rattata","Raticate","Spearow","Fearow","Ekans","Arbok","Pikachu","Raichu","Sandshrew","Sandslash","Nidoran♀","Nidorina","Nidoqueen","Nidoran♂","Nidorino","Nidoking","Clefairy","Clefable","Vulpix","Ninetales","Jigglypuff","Wigglytuff","Zubat","Golbat","Oddish","Gloom","Vileplume","Paras","Parasect","Venonat","Venomoth","Diglett","Dugtrio","Meowth","Persian","Psyduck","Golduck","Mankey","Primeape","Growlithe","Arcanine","Poliwag","Poliwhirl","Poliwrath","Abra","Kadabra","Alakazam","Machop","Machoke","Machamp","Bellsprout","Weepinbell","Victreebel","Tentacool","Tentacruel","Geodude","Graveler","Golem","Ponyta","Rapidash","Slowpoke","Slowbro","Magnemite","Magneton","Farfetch'd","Doduo","Dodrio","Seel","Dewgong","Grimer","Muk","Shellder","Cloyster","Gastly","Haunter","Gengar","Onix","Drowzee","Hypno","Krabby","Kingler","Voltorb","Electrode","Exeggcute","Exeggutor","Cubone","Marowak","Hitmonlee","Hitmonchan","Lickitung","Koffing","Weezing","Rhyhorn","Rhydon","Chansey","Tangela","Kangaskhan","Horsea","Seadra","Goldeen","Seaking","Staryu","Starmie","Mr. Mime","Scyther","Jynx","Electabuzz","Magmar","Pinsir","Tauros","Magikarp","Gyarados","Lapras","Ditto","Eevee","Vaporeon","Jolteon","Flareon","Porygon","Omanyte","Omastar","Kabuto","Kabutops","Aerodactyl","Snorlax","Articuno","Zapdos","Moltres","Dratini","Dragonair","Dragonite","Mewtwo","Mew",
                      //       ],
                      //       appName: 'React Search Bar',
                      //       list: undefined,
                      //     }
                      //   }
                      //   searchData(e) {
                      //     var queryData = [];
                      //     if(e.target.value != '') {
                      //       this.state.data.forEach(function(person) {
                      
                      //           if(person.toLowerCase().indexOf(e.target.value)!=-1) {
                      //             if(queryData.length < 10) {
                      //               queryData.push(person);
                      //             }
                      //           }
                      //       });
                      //     }
                      //     this.setState({list: queryData});
                      //   }
                      //   render() {
                      //     return(
                      //       <div>
                      //         <Header name={this.state.appName} />
                      //         <SearchBar search={this.searchData.bind(this)} />
                      //         {(this.state.list) ? <SearchResult data={this.state.list} /> : null  }
                      //       </div>


                      // function testFunction(evt: any){
                      //   const  addClass = (elem: any, className: any) => {
                      //     if (elem !== null && elem !==undefined) {
                      //       elem.classList.add((!elem.classList.contains(className)) ? className : null);
                      //     }
                      //   };
                        
                      //   const removeClass = (elem: any, className: any) => {
                      //     if (elem !== null && elem !==undefined) {
                      //       elem.classList.remove(elem.classList.contains(className) ? className : null);
                      //     }
                      //   };
                        
                      //   const resetFilter = () => {
                      //     // Find all <ion-item> elements that exist in the HTML
                          
                      //     // Check each <ion-item> element individually and 
                      //     // IF such element contains a class called "hidden"
                      //     // THEN remove the class reference from that element
                      //   };
                        
                      //   const filterDataset = (filterKeyword: any) => {
                      //     // filterKeyword is the text typed by the user in the searchbox control
                          
                      //     // Check if filterKeyword is empty
                          
                      //     // IF empty THEN trigger the "resetFilter" method 
                      //     //   After triggering that method proceed by stopping further logic 
                      //     //   within the filterDataset( ) method 
                      //     //   (Hint: Use "return" keyword for this)
                          
                      //     // ELSE should filterKeyword contain a value
                      //     // Find all <ion-item> elements that exist in the HTML
                      //     // Check each <ion-item> element individually against the filterKeyword and 
                      //     // IF element in question contains a reference to that filterKeyword
                      //     //    THEN Remove "hidden" class reference from element (if currently exists)
                      //     // ELSE 
                      //     //    Add "hidden" class reference to element (if does not exist yet)
                          
                          
                      //     // Hint: Make sure that you eliminate case sensitivity while comparing values
                      //     // Hint: You can use "forEach" OR "for" loop procedures to perform 
                      //     // an iteration on a given collection
                      //   }
                        
                      //   var inputValue = null;
                        
                      //   var searchEvts = {
                      //     ionChange: (evt: any) => {
                      //       // Using ionic "getInputElement( )" method to get value
                      //       // of the inner HTML input
                      //       evt.target.getInputElement().then((input: any) => {
                      //         inputValue = input.value; // Value inputted by user in the searchbox
                              
                      //         inputValue = inputValue.trim(); // To remove all whitespace characters
                            
                      //         if (inputValue == "") { 
                      //           inputValue = "empty" 
                      //         }
                              
                      //         alert("A value CHANGE was triggered.\n\nSearchbox value = " + inputValue);
                              
                      //         // Proceed with filtering a given dataset
                      //         // Hint: A method already exists that you can call
                      //       } );
                      //     },
                      //     ionClear: (evt: any) => {
                      //       alert("CLEAR event triggered");
                            
                      //       // Proceed with resetting filter of the searchbar control
                      //       // Hint: A method already exists that you can call
                      //     }
                      //   };
                      // }
                      