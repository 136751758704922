

import React from 'react';
import { IonToolbar, IonButton, IonFooter,  IonIcon } from '@ionic/react'
// import { Link, RouteComponentProps } from 'react-router-dom';

import {  cameraOutline, flashOutline, flowerOutline, folderOpenOutline, funnel, help, 
   informationCircleOutline,   pencilOutline,  scanCircleSharp } from 'ionicons/icons';
// import  './../pages/estate/LoginModal.css';
// import './Tab3.css';


const FooterAll = (props: any) => {
  return (

<IonFooter>
{/* <IonToolbar>

<IonButton key="btn51" onClick={e => props.goToPage(e,'/dashboard/pages/51') } className="ionBtnFooter"  fill="outline">
<IonIcon   slot="icon-only" ios={pencilOutline} md={pencilOutline} />   </IonButton>

<IonButton key="btn52" onClick={e => props.goToPage(e,'/dashboard/pages/52') }  fill="outline">
<IonIcon   slot="icon-only" ios={cameraOutline} md={cameraOutline} />   </IonButton>

<IonButton key="btn53" onClick={e =>props.goToPage(e,'/dashboard/pages/53') }  fill="outline">
<IonIcon   slot="icon-only" ios={scanCircleSharp} md={scanCircleSharp} />   </IonButton>

<IonButton key="btn54" onClick={e => props.goToPage(e,'/dashboard/pages/54') }  fill="outline">
<IonIcon   slot="icon-only" ios={help} md={help} />   </IonButton>

<IonButton key="btn55" onClick={e =>props.goToPage(e,'/dashboard/pages/55') }  fill="outline">
<IonIcon   slot="icon-only" ios={informationCircleOutline} md={informationCircleOutline} />   </IonButton>

<IonButton key="btn56" onClick={e => props.goToPage(e,'/dashboard/pages/56') }  fill="outline">
<IonIcon   slot="icon-only" ios={funnel} md={funnel} />   </IonButton>

<IonButton key="btn57" onClick={e => props.goToPage(e,'/dashboard/pages/57') }  fill="outline">
<IonIcon   slot="icon-only" ios={flowerOutline} md={flowerOutline} />   </IonButton>

<IonButton key="btn58" onClick={e => props.goToPage(e,'/dashboard/pages/58') }  fill="outline">
<IonIcon   slot="icon-only" ios={folderOpenOutline} md={folderOpenOutline} />   </IonButton>

<IonButton key="btn59" onClick={e => props.goToPage(e,'/dashboard/pages/59') }  fill="outline">
<IonIcon   slot="icon-only" ios={flashOutline} md={flashOutline} />   </IonButton>


</IonToolbar> */}



</IonFooter>   


  

 

  );
};

export default FooterAll;

// <IonContent>

// <Tab1EmlakList /> 

//   </IonContent>




// </IonPage>



      