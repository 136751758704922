

export function toaster(message: string, duration = 14000, func: any) {
    const toast = document.createElement('ion-toast');
    toast.message = message;
    toast.duration = duration;
    toast.position = 'top';
    toast.color = "primary";
  toast.buttons = [
    {
      side: 'start',
      icon: 'star',
      text: 'EVET',
      handler: () => {
        // console.log('Favorite clicked');
        func();
        return true;
      }
    }, {
      text: 'İptal',
      role: 'cancel',
      handler: () => {
        // console.log('Cancel clicked');
        return false;
      }
    }]

    document.body.appendChild(toast);
    return toast.present();
  }

//   "primary", "secondary", "tertiary", "success", "warning", "danger", "light", "medium", and "dark"