import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
// import { square, triangle, images, squareOutline } from 'ionicons/icons';
// // import Tab1 from './pages/Tab1';
// import Tab2 from './pages/Tab2Camera';
// import Tab3GirisMenu from './pages/Tab3GirisMenu';
// import Details from './pages/Details';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { TestProvider } from './context/TestContext';
/* Global CSS */
import './global.css';
// import ContactUs from './pages/ContactUs';
// import Tab1EmlakList from './pages/Tab1EmlakList';
// // import { TestProvider } from './context/TestContext';
// import Sayfa1 from './pages/estate/deneme/Sayfa1';
import { useUser } from './context/UserContext';
// import Tab1EmlakAra from './pages/Tab1EmlakAra';
import DashboardPage from './pages/DashboardPage';
// import TermsAndConditions from './pages/estate/privacy/TermsAndConditions';
// import SideBarRight from './pages/estate/privacy/SideBarRight';
// import TermsOfUse from './pages/estate/privacy/TermsOfUse';
// import Privacy from './pages/estate/privacy/Privacy';
// import Tenancy from './pages/estate/privacy/Tenancy';

// import firebase from './firebase/firebase';
// import { Console } from 'console';

const App: React.FC = () => {

  
  const {isLogged, isSearch, isSideBarRight, isTermsCond, isTermsOfUse, isPrivacy, isTenancy, 
    setUserShort, setUser, setPathUser, setIsLogged } = useUser()!;  


  React.useEffect(() => {
    if (!isLogged) {
      let mail: any = localStorage.getItem('iletisimail');
      if (mail !== undefined && mail !==null ) {
        console.log(' mail var ');
        if (mail.indexOf('@')>0) {
          console.log(' mail var ve MAİLE BENZİYOR ');
          let userShort2 = mail.substr(0,mail.indexOf('@'));
            setUserShort!(userShort2);
            setUser!(mail);
            setPathUser!('estate/'+userShort2+'/');
            setIsLogged!(true);        }
      } else {
        console.log(' mail yok ********** ');
      }
    }
  }, []);



  return (
<TestProvider>
  <IonApp>
    <IonReactRouter>
    
        <IonRouterOutlet>         
        
        <Route path="/dashboard" render={props => <DashboardPage {...props} />} />
          <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                  
        </IonRouterOutlet>

    </IonReactRouter>
  </IonApp>
  </TestProvider>
)
  };

export default App;

          // {/* <IonTabButton tab="tab5" href="/Sayfa1">
          //   <IonIcon icon={squareOutline} />
          //   <IonLabel>Yeni</IonLabel>
          // </IonTabButton> */}
          // <Route path="/" component={Tab1EmlakList}  exact={true}  />


      //              <IonTabBar slot="bottom">
      //     <IonTabButton tab="tab1" href="/tab1">
      //       <IonIcon icon={triangle} />
      //       <IonLabel>AnaSayfa</IonLabel>
      //     </IonTabButton>

      //     <IonTabButton tab="tab3" href="/tab3">
      //       <IonIcon icon={square} />
      //       <IonLabel>Giriş</IonLabel>
      //     </IonTabButton>

      //     <IonTabButton tab="tab2" href="/tab2">
      //       <IonIcon icon={images} />
      //       <IonLabel>Kamera</IonLabel>
      //     </IonTabButton>

      //     <IonTabButton tab="tab4" href="/tab4">
      //       <IonIcon icon={squareOutline} />
      //       <IonLabel>İletişim</IonLabel>
      //     </IonTabButton>


      //   </IonTabBar>
      // </IonTabs> 

// <Route path="/tab1" render={props => replaceForm(props)} />
// <Route path="/privacy" render={props => <Privacy {...props} />} />
//           <Route path="/tab3" component={Tab3GirisMenu}   exact={true} />
//           <Route path="/tab2" component={Tab2}  exact={true} />
//           <Route path="/tab2/details" component={Details}  exact={true} />       
//           <Route path="/ara" render={props => <Tab1EmlakAra {...props} />} />
//           <Route path="/ara/details" component={Details}  exact={true} />

//           <Route path="/tab4" component={ContactUs}   exact={true}  />
//             <Route path="/tab5" component={Tab1EmlakList}   exact={true} />
//             <Route path="/Sayfa1" component={Sayfa1}   exact={true} />
//             <Route path="/dashboard" render={props => <DashboardPage {...props} />} />
//             <Route path="/sidebarright" render={props => <SideBarRight {...props} />} />
//             <Route path="/termsandconditons" render={props => <TermsAndConditions {...props} />} />
//             <Route path="/termsofuse" render={props => <TermsOfUse {...props} />} />
//             <Route path="/privacy" render={props => <Privacy {...props} />} />
//             <Route path="/tenancy" render={props => <Tenancy {...props} />} />
//           <Route path="/" render={() => <Redirect to="/tab1" />} exact={true} /> 


  // const replaceForm = (props: any) => {
  //    if (isSearch) 
  //      return <DashboardPage {...props}  />
  //   else if (isSideBarRight)
  //     return  <SideBarRight {...props}  />
  //   else if (isTermsCond)
  //     return  <TermsAndConditions {...props}  />
  //   else if (isTermsOfUse)
  //     return  <TermsOfUse {...props}  />
  //   else if (isPrivacy)
  //     return  <Privacy {...props}  />
  //   else if (isTenancy)
  //       return  <Tenancy {...props}  />
  //   else
  //     return <Tab1EmlakList/>
  // }


    // return (!isSearch) ? <Tab1EmlakList/> : <DashboardPage {...props} />
    

