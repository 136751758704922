
// import React, {Component,useContext,useEffect, useReducer} from 'react';
// // import {useQuery} from 'react-query';
// import { RSA_NO_PADDING } from 'constants';
// import { MainContext } from './context/MainContext';
import {storage, db} from './../firebase/firebase';



export const sleep = (n) => new Promise(r => setTimeout(r, n));

export const img = (path) => `https://ionic-react-demos.s3.amazonaws.com/${path}`;

export const msToTime = (d) => {
  var seconds = Math.floor((d / 1000) % 60),
    minutes = Math.floor((d / (1000 * 60)) % 60);
  return minutes + ":" + (seconds < 10 ? `0${seconds}` : seconds);
}


function uploadOne( pathUser, propid, path){
  let estateObj = {
      sira: 0,
      marks: "lol",
      propid: 0
  }
  // todo ${propid}/
  db.ref(`${pathUser}${path}${propid}/`).child(`${propid}`).set(estateObj)
  .then(function() {
      console.log("writing done...");
      return "ok";
    })
    .catch(function(error) {
      console.log("writing error : "+error);
      return error;
    });
 } 

export const readFirebase = (pathUser,setPropid,setSiraNo,setStateSiraNo) => { 
    setPropid(1);
    localStorage.setItem('propid',1);
    localStorage.setItem('sira',1);
    localStorage.setItem('siraNo',1);
      const ref = db.ref(pathUser+"doc/");
      ref.once("value", function(data) {
        var siraNoMax = 0
        var oldProp = 0;
        let result = data.val();
          if (result !== undefined && result !== null){
               //  console.log(JSON.stringify(result));
                Object.keys(result).map(item=>{
               //  console.log('projectlink: item > '+item);
               //  console.log('projectlink: propid > '+result[item].propid);
               //  console.log('all keys '+JSON.stringify(Object.keys(result)));
               //  console.log('oldProp KEYS GET PROP***** '+item);
               siraNoMax = parseInt(item);
                // todo [item]
                oldProp = parseInt(result[item][item].propid);
                //  console.log('oldProp ***** '+oldProp);
              });
              let maxSayi = parseInt(siraNoMax);
              if (oldProp!==0) {
                maxSayi = parseInt(maxSayi) + 1
                uploadOne(pathUser, maxSayi,'doc/') 
              } 
                //  console.log('siraNoMax ****** '+maxSayi)
                setPropid(maxSayi);  
                setSiraNo(maxSayi);
                //      
                localStorage.setItem('propid',maxSayi);
                localStorage.setItem('sira',maxSayi);
                localStorage.setItem('siraNo',maxSayi);
              return result; // ilk defa gelişte id 1 atanır ve yazılır

            } else {
              uploadOne(pathUser, 1,'doc/')
              setPropid(1);  
              setSiraNo(1);
              //      
              localStorage.setItem('propid',1);
              localStorage.setItem('sira',1);
              localStorage.setItem('siraNo',1);
            }
});
}

export const mbFireSet = ( pathUser, propid, path, estateObj) => {
  db.ref(`${pathUser}${path}`).child(`${propid}`).set(estateObj)
  .then(function() {
      //  console.log("writing done...");
      return "ok";
    })
    .catch(function(error) {
      //  console.log("writing error : "+error);
      return error;
    });
 }

 export const mbFireSablonVaadSet = ( pathUser, path, propid, field, estateObj) => {
  db.ref(`${pathUser}${path}${propid}`).child(`${field}`).set(estateObj)
  .then(function() {
      console.log(" mbFireSablonVaadSet writing done...");
      return "ok";
    })
    .catch(function(error) {
      //  console.log("mbFireSablonVaadSet writing error : "+error);
      return error;
    });
 }

// vtarihi,,,vmahkemesehri,,,,vsatiskapora,,,,,,vvazgecmecezasi,,
// ,vsatiskomisyon,,vfirmaadres,vparsel,vsatisfiyati,vada, vadisoyadi, vturucinsi.

export function getPropResim(siraNo, pathUser,setImageAsUrl){
        var urls = []
        let sira = parseInt(siraNo);
         //  console.log('sira leng '+sira.length);
        const ref = db.ref(`${pathUser}lnk/${sira}/`) // ("estate/emr/lnk/1"); ${file.name}
            // Attach an asynchronous callback to read the data at our posts reference
            //  console.log(ref + 'REF REF')
            ref.on("value", function(snapshot) {
              if (snapshot!==undefined) {
                let result = snapshot.val();
                    //  console.log('geldi mi? '+result); 
                    if (result !== undefined && result !== null) {   
                      Object.keys(result).map(item=>{
                         //  console.log(result[item].projectlink);
                        urls.push(result[item].projectlink); 
                      });
                    }
                  if (urls.length>0) {               
                      setImageAsUrl(urls);                   
                  } else {
                  console.log("veri gelmedi... ");
                  return ""; 
                }  
              }
            }, function (errorObject) {
              console.log("The read failed: " + errorObject.code);
              return errorObject.code;
            });
          return urls;  
}

                      // var totalRecord =  snapshot.numChildren();
                      // console.log("Total Record : "+totalRecord) 

export async function getPropDetay(siraNo, pathUser,setPropDetay, setState){
        let sira = parseInt(siraNo);        
        const ref = db.ref(`${pathUser}doc/${sira}/`) // ("estate/emr/lnk/1"); ${file.name}
            ref.on("value", function(snapshot) {
              if (snapshot!==undefined) {
                let result = snapshot.val();  
                    if (result !== undefined && result !== null) {   
                      var dict = {};  
                      initLocal(); 
                      Object.keys(result).map(item=>{
                        Object.keys(result[item]).map(subItem=>{
                          dict[subItem] = result[item][subItem]
                         //  console.log(' item bulundu mu : '+subItem+' '+result[item][subItem])
                        })
                        setState(
                          dict['baslik'],
                        dict['detay'],
                        dict['sira'],
                        dict['fiyat'],
                        dict['aidat'],
                        dict['durumu'],
                        dict['netm2'],
                        dict['brutm2'],
                        dict['depozito'],
                        dict['mahalle'],
                        dict['sehir'],
                        dict['adresi'],
                        dict['sahibi'],
                        dict['bostarih'],
                        dict['kiralikSatilik'],
                        dict['kategori']  ,
                        dict['binaYasi'],
                        dict['tapuDurumu'],
                        dict['cephe'],
                        dict['isitmaTipi'],
                        dict['konutTipi'],
                        dict['odaSayisi'],
                        dict['manzara'],
                        dict['katDetay'],
                        dict['katSayisi'],
                        dict['bulunduguKat'],
                        dict['engelliyeUygun'],
                        
                        );     

                        // setPropDetay(dict);  

                        localStorage.setItem('baslik', dict['baslik']);
                        localStorage.setItem('detay', dict['detay']);
                        localStorage.setItem('sira', dict['sira']);
                        localStorage.setItem('fiyat', dict['fiyat']);
                        localStorage.setItem('aidat', dict['aidat']);
                        localStorage.setItem('durumu', dict['durumu']);
                        localStorage.setItem('netm2', dict['netm2']);
                        localStorage.setItem('brutm2', dict['brutm2']);
                        localStorage.setItem('depozito', dict['depozito']);
                        localStorage.setItem('mahalle', dict['mahalle']);
                        localStorage.setItem('sehir', dict['sehir']);
                        localStorage.setItem('adresi', dict['adresi']);
                        localStorage.setItem('sahibi', dict['sahibi']);
                        localStorage.setItem('bostarih', dict['bostarih']);
                         
                        localStorage.setItem('dataKiralikSatilikSel', dict['kiralikSatilik']);
                        localStorage.setItem('dataKategoriSel', dict['kategori']);
                        
                        localStorage.setItem('dataBinaYasiSel', dict['binaYasi']);
                        localStorage.setItem('dataTapuDurumuSel', dict['tapuDurumu']);
                        localStorage.setItem('dataCepheSel', dict['cephe']);
                        localStorage.setItem('dataIsitmaTipiSel', dict['isitmaTipi']);
                        localStorage.setItem('dataKonutTipiSel', dict['konutTipi']);
                        localStorage.setItem('dataOdaSayisiSel', dict['odaSayisi']);
                        localStorage.setItem('dataManzaraSel', dict['manzara']);
                        localStorage.setItem('dataKatDetaySel', dict['katDetay']);
                        localStorage.setItem('dataKatSayisiSel', dict['katSayisi']);
                        localStorage.setItem('dataBulunduguKatSel', dict['bulunduguKat']);

                        localStorage.setItem('dataEngelliyeUygunSel', dict['engelliyeUygun']);

                       return dict;
                      });
                    }
              }
            }, function (errorObject) {
              console.log("The read failed: " + errorObject.code);
            });         
}  

export function checkVal(item, itemSub, defVal) {
  if (item!==undefined && item!==null) {
    if (item[itemSub]!==undefined && item[itemSub]!==null) {
      return item[itemSub];
    }
  }
    return defVal;  
}

export function isNullMb(item, defVal) {
  if (item!==undefined && item!==null) {
    if (item.length===0) return defVal;
    return item
  }   else {
    return defVal;  
  }   
}
export function isNullMbField(item, defVal, fldName) {
  if (item!==undefined && item!==null) {
    if (item.length===0) return defVal;
    if (fldName===undefined || fldName===null)
     return item
    else
    return fldName+': '+item+'. ';
  }   else {
    return defVal;  
  }   
}

export function isNullMbArray(item, defVal) {
  if (item!==undefined && item!==null) {
    if (item.length===0) return defVal;
    let itemNew = item.split('_');
     return itemNew[2];
  }   else {
    return defVal;  
  }   
}
// sira no 0 ise genel, yoksa tek property
export function getPropResimGenel(siraNo, pathUser,setImageAsUrl,setStatePropResim){
  var urls = [];
  let sira = parseInt(siraNo);
  /// console.log('sira leng '+sira.length);
  var ref = db.ref(`${pathUser}lnk/${sira}/`) 
     if (sira === 0 ) ref = db.ref(`${pathUser}lnk/`) 
      ref.on("value", function(snapshot) {
        if (snapshot!==undefined) {
          let result = snapshot.val();
              /// console.log('geldi mi? '+result); 
              if (result !== undefined && result !== null) {   
                Object.keys(result).map(item=>{
                  Object.keys(result[item]).map(itemSub=>{
                    urls.push({item: item, projectlink: result[item][itemSub].projectlink});
                    /// console.log(item, itemSub,result[item][itemSub].projectlink);
                  }); 
                });
              }
            if (urls.length>0) {               
                setImageAsUrl(urls);  
                setStatePropResim(urls);                 
            } else {
            console.log("veri gelmedi... ") ;
            return "";
          }  
        }
      }, function (errorObject) {
        console.log("The read failed: " + errorObject.code);
        return errorObject.code;
      });
    return urls;  
}

export async function delPropDetay(siraNo, pathUser, imgDict){
  let sira = parseInt(siraNo);        
  const ref = db.ref(`${pathUser}doc/${sira}/`) // ("estate/emr/lnk/1"); ${file.name}
  const refResim = storage.ref(`${pathUser}img/${siraNo}`);
  const refLnk = db.ref(`${pathUser}lnk/${sira}/`);  // ("estate/emr/lnk/1"); ${file.name}
  

 //  console.log(' objects keys here delPropDetay ÖNCESİ '+ref);
  ref.remove();
 //  console.log(' objects keys here delPropDetay SONRASI '+ref);

 //  console.log(' objects keys here refResim ÖNCESİ '+refResim);
  refResim.delete();
 //  console.log(' objects keys here refResim SONRASI '+refResim);

  // image sdelete section
  if (imgDict!==undefined || imgDict !== null) {
    let objKeys = Object.keys(imgDict);
    if (objKeys.length!==0) {
      objKeys.map((item)=>{
        let fileName = item.replace('png','.png').replace('jpg','.jpg').replace('gif','.gif').replace('jpeg','.jpeg');
        let resimRef = storage.ref(`${pathUser}img/${siraNo}/${fileName}`);
       //  console.log(' objects keys here delPropDetay silinen RESİM ********** '+resimRef);
        resimRef.delete();
       //  console.log(' objects keys here delPropDetay silinen RESİM ********** '+resimRef);
      })
    }
  }
 //  console.log(' objects refLnk here delPropDetay ÖNCESİ '+refLnk);
  refLnk.remove();
 //  console.log(' objects refLnk here delPropDetay SONRASI '+refLnk);


}

export function initLocal(){
  localStorage.setItem('dataPropid', 0);

  localStorage.setItem('dataKiralikSatilikSel', '');
  localStorage.setItem('dataKategoriSel', '');
  localStorage.setItem('dataBinaYasiSel', '');
  localStorage.setItem('dataTapuDurumuSel', '');
  localStorage.setItem('dataCepheSel', '');
  localStorage.setItem('dataIsitmaTipiSel', '');
  localStorage.setItem('dataKonutTipiSel', '');
  localStorage.setItem('dataOdaSayisiSel', '');
  localStorage.setItem('dataKatDetaySel', '');
  localStorage.setItem('dataKatSayisiSel', '');
  localStorage.setItem('dataBulunduguKatSel', '');

  localStorage.setItem('dataManzaraSel', '');
  localStorage.setItem('dataIcOzellikSel', '');
  localStorage.setItem('dataDisOzellikSel', '');
  localStorage.setItem('dataMuhitSel', '');
  localStorage.setItem('dataUlasimSel', '');
  localStorage.setItem('dataEngelliyeUygunSel', '');

  localStorage.setItem('propid', 0);
  localStorage.setItem('siraNo', 0);
  localStorage.setItem('sira', 0);

  localStorage.setItem('baslik', '');
  localStorage.setItem('detay', '');
  localStorage.setItem('aidat', '');
  localStorage.setItem('fiyat', '');
  localStorage.setItem('depozito', '');
  localStorage.setItem('netm2', '');
  localStorage.setItem('brutm2', '');
  localStorage.setItem('sahibi', '');
  localStorage.setItem('mahalle', '');
  
  localStorage.setItem('durumu', '');
  localStorage.setItem('bostarih', '');
  localStorage.setItem('sehir', '');
  localStorage.setItem('adresi', '');
  localStorage.setItem('engelliyeUygun', '');
  localStorage.setItem('kiralikSatilik', '');
  localStorage.setItem('kategori', '');
  localStorage.setItem('tapuDurumu', '');
  localStorage.setItem('odaSayisi', '');
  localStorage.setItem('katSayisi', '');
  localStorage.setItem('katDetay', '');

  localStorage.setItem('bulunduguKat', '');
  localStorage.setItem('manzara', '');
  localStorage.setItem('muhit', '');
  localStorage.setItem('ulasim', '');
  localStorage.setItem('disOzellik', '');
  localStorage.setItem('icOzellik', '');

  localStorage.setItem('isitmaTipi', '');
  localStorage.setItem('cephe', '');
  localStorage.setItem('binaYasi', '');
  localStorage.setItem('konutTipi', '');
  
// satış vaadi
localStorage.setItem('vtarihi', '');
localStorage.setItem('vmahkemesehri', '');
localStorage.setItem('vsatiskapora', '');
localStorage.setItem('vvazgecmecezasi', '');
localStorage.setItem('vsatiskomisyon', '');
localStorage.setItem('vfirmaadres', '');
localStorage.setItem('vparsel', '');
localStorage.setItem('vsatisfiyati', '');
localStorage.setItem('vada', '');
localStorage.setItem('vadisoyadi', '');
localStorage.setItem('vturucinsi', '');

localStorage.setItem('vsirano', 0);

// yetki sözleşmesi
localStorage.setItem('ysirano', 0);
localStorage.setItem('ytarihi', '');
localStorage.setItem('ymahksehir', '');
localStorage.setItem('ycezafaizoran', '');
localStorage.setItem('yadresi', '');
localStorage.setItem('ytelefonu', '');
localStorage.setItem('ymulkadres', '');
localStorage.setItem('ytapubilgi', '');
localStorage.setItem('ykomoran', '');
localStorage.setItem('yvazgecoran', '');
localStorage.setItem('yfesihoran', '');
localStorage.setItem('ysatisvazgecoran', '');
localStorage.setItem('ysatisfiyat', '');
localStorage.setItem('yunvanimiz', '');
localStorage.setItem('yadimiz', '');
localStorage.setItem('yadresimiz', '');
localStorage.setItem('ytelnomuz', '');
localStorage.setItem('ymulkunvan', '');
localStorage.setItem('yadisoyadi', '');
localStorage.setItem('ysozsure', '');


 //    ysirano  ysatisfiyat  yunvanimiz yadimiz   yadresimiz  ytelnomuz  ymulkunvan  yadisoyadi  
 // yadresi  ytelefonu  ymulkadres  ytapubilgi ykomoran yvazgecoran  yfesihoran  ysatisvazgecoran 
 // ycezafaizoran ymahksehir  vtarihi

  // const [dataKiralikSatilikSel, setDataKiralikSatilikSel] = useState('');
  // const [dataKategoriSel, setDataKategoriSel] = useState('');
  // const [dataBinaYasiSel, setDataBinaYasiSel] = useState('');
  // const [dataTapuDurumuSel, setDataTapuDurumuSel] = useState('');
  // const [dataCepheSel, setDataCepheSel] = useState('');
  // const [dataIsitmaTipiSel, setDataIsitmaTipiSel] = useState('');
  // const [dataKonutTipiSel, setDataKonutTipiSel] = useState('');
  // const [dataOdaSayisiSel, setDataOdaSayisiSel] = useState('');
  // const [dataKatDetaySel, setDataKatDetaySel] = useState('');
  // const [dataKatSayisiSel, setDataKatSayisiSel] = useState('');
  // const [dataBulunduguKatSel, setDataBulunduguKatSel] = useState('');

  // const [dataManzaraSel, setDataManzaraSel] = useState('');
  // const [dataIcOzellikSel, setDataIcOzellikSel] = useState('');
  // const [dataDisOzellikSel, setDataDisOzellikSel] = useState('');
  // const [dataMuhitSel, setDataMuhitSel] = useState('');
  // const [dataUlasimSel, setDataUlasimSel] = useState('');
  // const [dataEngelliyeUygunSel, setDataEngelliyeUygunSel] = useState('');
}

export function getValFromStorage(item,defaultVal){
 //  console.log(' local storage funct item '+item);
  if (item.length<1) return defaultVal;
  let itemResult = localStorage.getItem(item);
  if (itemResult===undefined || itemResult===null) return defaultVal;
  let itemArr = itemResult.split('_');
  if (itemArr.length<2) return defaultVal;
 //  console.log(' local storege found '+itemArr[2]);
  // return itemArr[2];
  return item;
}




//   export async function delResimTek(siraNo, pathUser, filename){
//     let sira = parseInt(siraNo);        
//     const ref = db.ref(`${pathUser}doc/${sira}/${filename}`) // ("estate/emr/lnk/1"); ${file.name}
//     const refLnk = db.ref(`${pathUser}lnk/${sira}/${filename}`);  // ("estate/emr/lnk/1"); ${file.name}
//     console.log('ref lnk to delete '+refLnk);
//     let fName = filename.replace('png','.png').replace('jpg','.jpg').replace('gif','.gif').replace('jpeg','.jpeg');
//     let resimRef = storage.ref(`${pathUser}img/${siraNo}/${fName}`);
//     console.log('ref img  to delete '+resimRef);
//     resimRef.delete();
//     refLnk.remove();
//     console.log(' I think one picture deleted ');

// };
