

import * as React from "react";
import { useState } from "react";
import moment from "moment";
// import { db } from "../firebase/firebase";


const defaultTest = "white";
const initSiraNo = 0;


type TestContextType = {
  theme: string;
  setTest: (value: string) => void;

  propid: number; 
  parDict: any;
 
  baslik:   string;
  detay:   string;
    siraNo: number; 
    fiyat:   string;
    aidat:   string;
    durumu:   string;
    netm2:   string;
    brutm2:   string;
    depozito:   string;
    mahalle:   string;
    sehir:   string;
    adresi:   string;
    sahibi:   string;
    bostarih:   string;
 
  dataKiralikSatilikSel:   string;
  dataKategoriSel:   string;
  dataBinaYasiSel:   string;
  dataTapuDurumuSel:   string;
  dataCepheSel:   string;
  dataIsitmaTipiSel:   string;
  dataKonutTipiSel:   string;
  dataOdaSayisiSel:   string;
  dataKatDetaySel:   string;
  dataKatSayisiSel:   string;
  dataBulunduguKatSel:   string;
  dataManzaraSel:   string;
  dataIcOzellikSel:   string;
  dataDisOzellikSel:   string;
  dataMuhitSel:   string;
  dataUlasimSel:   string;
  dataEngelliyeUygunSel:   string;
  proProperty: any;
  proPropertyEdit: any;
  setParDict: (item:any) => void;
  setMsg: (item:any) => void;
 
  setFiyat: (item:any) => void;
  setAidat: (item:any) => void;
  setDurumu: (item:any) => void;
  setNetm2: (item:any) => void;
  setBrutm2: (item:any) => void;
  setDepozito: (item:any) => void;
  setMahalle: (item:any) => void;
  setSehir: (item:any) => void;
  setAdresi: (item:any) => void;
  setSahibi: (item:any) => void;
  setSiraNo: (item:any) => void;
  setBostarih: (item:any) => void;
  setBaslik: (item:any) => void;
  setDetay: (item:any) => void;
  setDataKiralikSatilikSel: (item:any) => void;
  setDataKategoriSel: (item:any) => void;
  setDataBinaYasiSel: (item:any) => void;
  setDataTapuDurumuSel: (item:any) => void;
  setDataCepheSel: (item:any) => void;
  setDataIsitmaTipiSel: (item:any) => void;
  setDataKonutTipiSel: (item:any) => void;
  setDataOdaSayisiSel: (item:any) => void;
  setDataManzaraSel: (item:any) => void;
  setDataKatDetaySel: (item:any) => void;
  setDataKatSayisiSel: (item:any) => void;
  setDataBulunduguKatSel: (item:any) => void;
  setDataEngelliyeUygunSel: (item:any) => void;
  setPropid: (item:any) => void;
  setProProperty: (item:any) => void;
  setProPropertyEdit: (item:any) => void;
  initState:any;
  initPath: string;

  propi: number;

  linkUrl: any;
  setLinkUrl: (item:any) => void;

  propDetay: any;
  setPropDetay: (item:any) => void;
  
  imageAsUrlDict: any;
  setImageAsUrlDict: (item:any) => void;

  setDataIcOzellikSel: (item:any) => void;

  setDataDisOzellikSel: (item:any) => void;

  setDataMuhitSel: (item:any) => void;

  setDataUlasimSel: (item:any) => void;

  msg: string;


  propDetayDict: any;
  setPropDetayDict: (item:any) => void;

  vaadiText: any;
  setVaadiText: (item:any) => void;

  yetkiText: any;
  setYetkiText: (item:any) => void;

  isLoading: any;
  setIsLoading: (item:any) => void;

  isError: any;
  setIsError: (item:any) => void;

  saveStateVals: (id: string, val:any) => void;

  dataKiralikSatilikTitle:  string;
  dataKategoriTitle:  string;
  dataBinaYasiTitle:  string; 
  dataTapuDurumuTitle:  string;
  dataCepheTitle:  string; 
  dataIsitmaTipiTitle:  string; 
  dataKonutTipiTitle:  string; 
  dataOdaSayisiTitle:  string; 
  dataKatDetayTitle:  string; 
  dataKatSayisiTitle:  string;
  dataBulunduguKatTitle:  string;
  dataManzaraTitle:  string; 
  dataIcOzellikTitle:  string;
  dataDisOzellikTitle:  string; 
  dataMuhitTitle:  string;
  dataUlasimTitle:  string; 
  dataEngelliyeUygunTitle:  string; 
  vaadiTextTitle:  string; 
  yetkiTextTitle:  string; 

  dataKiralikSatilikAll: any; 
  dataKategoriAll: any;
  dataBinaYasiAll: any; 
  dataTapuDurumuAll: any;
  dataCepheAll: any; 
  dataIsitmaTipiAll: any; 
  dataKonutTipiAll: any; 
  dataOdaSayisiAll: any; 
  dataKatDetayAll: any; 
  dataKatSayisiAll: any;
  dataBulunduguKatAll: any;
  dataManzaraAll: any; 
  dataIcOzellikAll: any;
  dataDisOzellikAll: any; 
  dataMuhitAll: any;
  dataUlasimAll: any; 
  dataEngelliyeUygunAll: any; 
  vaadiTextAll: any; 
  yetkiTextAll: any; 

  setDataKiralikSatilikTitle:     (item:any) => void;
  setDataKategoriTitle:     (item:any) => void;
  setDataBinaYasiTitle:     (item:any) => void; 
  setDataTapuDurumuTitle:     (item:any) => void;
  setDataCepheTitle:     (item:any) => void; 
  setDataIsitmaTipiTitle:     (item:any) => void; 
  setDataKonutTipiTitle:     (item:any) => void; 
  setDataOdaSayisiTitle:     (item:any) => void; 
  setDataKatDetayTitle:     (item:any) => void; 
  setDataKatSayisiTitle:     (item:any) => void;
  setDataBulunduguKatTitle:     (item:any) => void;
  setDataManzaraTitle:     (item:any) => void; 
  setDataIcOzellikTitle:     (item:any) => void;
  setDataDisOzellikTitle:     (item:any) => void; 
  setDataMuhitTitle:     (item:any) => void;
  setDataUlasimTitle:     (item:any) => void; 
  setDataEngelliyeUygunTitle:     (item:any) => void; 
  setVaadiTextTitle:     (item:any) => void; 
  setYetkiTextTitle:     (item:any) => void; 

  setDataKiralikSatilikAll:    (item:any) => void; 
  setDataKategoriAll:    (item:any) => void;
  setDataBinaYasiAll:    (item:any) => void; 
  setDataTapuDurumuAll:    (item:any) => void;
  setDataCepheAll:    (item:any) => void; 
  setDataIsitmaTipiAll:    (item:any) => void; 
  setDataKonutTipiAll:    (item:any) => void; 
  setDataOdaSayisiAll:    (item:any) => void; 
  setDataKatDetayAll:    (item:any) => void; 
  setDataKatSayisiAll:    (item:any) => void;
  setDataBulunduguKatAll:    (item:any) => void;
  setDataManzaraAll:    (item:any) => void; 
  setDataIcOzellikAll:    (item:any) => void;
  setDataDisOzellikAll:    (item:any) => void; 
  setDataMuhitAll:    (item:any) => void;
  setDataUlasimAll:    (item:any) => void; 
  setDataEngelliyeUygunAll:    (item:any) => void; 
  setVaadiTextAll:    (item:any) => void; 
  setYetkiTextAll:    (item:any) => void; 

  dividePars:    (item:any) => void;

  sablon: any;
  setDataSablonAll:    (item:any) => void;
  inputChanged: (e:any) => void;

 
};


const initVals = {
    initState: '', 

    propi: initSiraNo, 
      siraNo: initSiraNo ,

    imageAsUrl: [],
    propDetay: [],
    propDetayDict: {},
    imageAsUrlDict: {},

    theme: '' ,   
    baslik:   '' ,
    detay:   '' ,

      fiyat:   '' ,
      aidat:   '' ,
      durumu:   '' ,
      netm2:   '' ,
      brutm2:   '' ,
      depozito:   '' ,
      mahalle:   '' ,
      sehir:   '' ,
      adresi:   '' ,
      sahibi:   '' ,
      bostarih:   '' ,
   
    dataKiralikSatilikSel:   '' ,
    dataKategoriSel:   '' ,
    dataBinaYasiSel:   '' ,
    dataTapuDurumuSel:   '' ,
    dataCepheSel:   '' ,
    dataIsitmaTipiSel:   '' ,
    dataKonutTipiSel:   '' ,
    dataOdaSayisiSel:   '' ,
    dataKatDetaySel:   '' ,
    dataKatSayisiSel:   '' ,
    dataBulunduguKatSel:   '' ,
    dataManzaraSel:   '' ,
    dataIcOzellikSel:   '' ,
    dataDisOzellikSel:   '' ,
    dataMuhitSel:   '' ,
    dataUlasimSel:   '' ,
    dataEngelliyeUygunSel:   '' ,
    proProperty: [],
    proPropertyEdit: [],
    parDict: {},
    msg:   '' ,
    path:   'estate/' ,
    vaadiText: '' ,
    yetkiText: '',
    isLoading: true,
    isError: false,

    dataKiralikSatilikTitle: '',
    dataKategoriTitle: '',
    dataBinaYasiTitle: '', 
    dataTapuDurumuTitle: '',
    dataCepheTitle: '', 
    dataIsitmaTipiTitle: '', 
    dataKonutTipiTitle: '', 
    dataOdaSayisiTitle: '', 
    dataKatDetayTitle: '', 
    dataKatSayisiTitle: '',
    dataBulunduguKatTitle: '',
    dataManzaraTitle: '', 
    dataIcOzellikTitle: '',
    dataDisOzellikTitle: '', 
    dataMuhitTitle: '',
    dataUlasimTitle: '', 
    dataEngelliyeUygunTitle: '', 
    vaadiTextTitle: '', 
    yetkiTextTitle: '', 

    dataKiralikSatilikAll: {}, 
    dataKategoriAll: {} ,
    dataBinaYasiAll: {}, 
    dataTapuDurumuAll: {},
    dataCepheAll: {}, 
    dataIsitmaTipiAll: {}, 
    dataKonutTipiAll: {}, 
    dataOdaSayisiAll: {}, 
    dataKatDetayAll: {}, 
    dataKatSayisiAll: {},
    dataBulunduguKatAll: {},
    dataManzaraAll: {}, 
    dataIcOzellikAll: {},
    dataDisOzellikAll: {}, 
    dataMuhitAll: {},
    dataUlasimAll: {}, 
    dataEngelliyeUygunAll: {}, 
    vaadiTextAll: {}, 
    yetkiTextAll: {}, 
    sablon: {}, 


    // setParDict: (item:any) => void;
    // setMsg: (item:any) => void;
   
    // setFiyat: (item:any) => void;
    // setAidat: (item:any) => void;
    // setDurumu: (item:any) => void;
    // setNetm2: (item:any) => void;
    // setBrutm2: (item:any) => void;
    // setDepozito: (item:any) => void;
    // setMahalle: (item:any) => void;
    // setSehir: (item:any) => void;
    // setAdresi: (item:any) => void;
   
    // setSahibi: (item:any) => void;
    // setBostarih: (item:any) => void;
    // setBaslik: (item:any) => void;
    // setDetay: (item:any) => void;
    // setDataKiralikSatilikSel: (item:any) => void;
    // setDataKategoriSel: (item:any) => void;
    // setDataBinaYasiSel: (item:any) => void;
    // setDataTapuDurumuSel: (item:any) => void;
    // setDataCepheSel: (item:any) => void;
    // setDataIsitmaTipiSel: (item:any) => void;
    // setDataKonutTipiSel: (item:any) => void;
    // setDataOdaSayisiSel: (item:any) => void;
    // setDataManzaraSel: (item:any) => void;
    // setDataKatDetaySel: (item:any) => void;
    // setDataKatSayisiSel: (item:any) => void;
    // setDataBulunduguKatSel: (item:any) => void;
    // setDataEngelliyeUygunSel: (item:any) => void;
    // setPropid: (item:any) => void;
    // setProProperty: (item:any) => void;
    // setProPropertyEdit: (item:any) => void;

   
       

       // parDict: {};
    // initPath: 'estate/',  
    // isL: true,
    // userS: 'test',
    // userP: 'estate/test/',
    // propi: 0,
   // setTest(''),

}

const TestContext = React.createContext<Partial<TestContextType>>(initVals);
// proProperty
type Props = {
  children: React.ReactNode;
};

export const TestProvider = ({ children }: Props) => {
  const [theme, setTest] = React.useState(defaultTest);

  const initState:any = [];
  // TEST ORTAMINDA KULLANILACAK.

    const propi = initSiraNo;

    const [propid, setPropid] = useState(propi);
    const [siraNo, setSiraNo] = useState(propi);

  const [linkUrl, setLinkUrl] = useState(initState) //const [urls, setUrls] = useState(initState)
  const [propDetay, setPropDetay] = useState([])


  const [msg, setMsg] = useState('Hazır...');


  const [imageAsUrlDict, setImageAsUrlDict] = useState({});
  const [propDetayDict, setPropDetayDict] = useState({});
  const [parDict, setParDict] = useState({});

  // property details

  const [baslik, setBaslik] = useState('');
  const [detay, setDetay] = useState('');

  const [fiyat, setFiyat] = useState('');
  const [aidat, setAidat] = useState('');
  const [durumu, setDurumu] = useState('');
  const [netm2, setNetm2] = useState('');
  const [brutm2, setBrutm2] = useState('');

  const [depozito, setDepozito] = useState('');
  const [mahalle, setMahalle] = useState('');
  const [sehir, setSehir] = useState('');
  const [adresi, setAdresi] = useState('');
  const [sahibi, setSahibi] = useState('');
  const [bostarih, setBostarih] = useState('');

  const [dataKiralikSatilikSel, setDataKiralikSatilikSel] = useState('');
  const [dataKategoriSel, setDataKategoriSel] = useState('');
  const [dataBinaYasiSel, setDataBinaYasiSel] = useState('');
  const [dataTapuDurumuSel, setDataTapuDurumuSel] = useState('');
  const [dataCepheSel, setDataCepheSel] = useState('');
  const [dataIsitmaTipiSel, setDataIsitmaTipiSel] = useState('');
  const [dataKonutTipiSel, setDataKonutTipiSel] = useState('');
  const [dataOdaSayisiSel, setDataOdaSayisiSel] = useState('');
  const [dataKatDetaySel, setDataKatDetaySel] = useState('');
  const [dataKatSayisiSel, setDataKatSayisiSel] = useState('');
  const [dataBulunduguKatSel, setDataBulunduguKatSel] = useState('');

  const [dataManzaraSel, setDataManzaraSel] = useState('');
  const [dataIcOzellikSel, setDataIcOzellikSel] = useState('');
  const [dataDisOzellikSel, setDataDisOzellikSel] = useState('');
  const [dataMuhitSel, setDataMuhitSel] = useState('');
  const [dataUlasimSel, setDataUlasimSel] = useState('');
  const [dataEngelliyeUygunSel, setDataEngelliyeUygunSel] = useState('');
  const [vaadiText, setVaadiText] = useState('');
  const [yetkiText, setYetkiText] = useState(''); 
  const [proProperty, setProProperty] = useState(initState); 
  const [proPropertyEdit, setProPropertyEdit] = useState(initState); 

  const [isLoading, setIsLoading] = useState(true); 
  const [isError, setIsError] = useState(false); 

  const [dataKiralikSatilikTitle, setDataKiralikSatilikTitle] = useState('');
  const [dataKategoriTitle, setDataKategoriTitle] = useState('');
  const [dataBinaYasiTitle, setDataBinaYasiTitle] = useState('');
  const [dataTapuDurumuTitle, setDataTapuDurumuTitle] = useState('');
  const [dataCepheTitle, setDataCepheTitle] = useState('');
  const [dataIsitmaTipiTitle, setDataIsitmaTipiTitle] = useState('');
  const [dataKonutTipiTitle, setDataKonutTipiTitle] = useState('');
  const [dataOdaSayisiTitle, setDataOdaSayisiTitle] = useState('');
  const [dataKatDetayTitle, setDataKatDetayTitle] = useState('');
  const [dataKatSayisiTitle, setDataKatSayisiTitle] = useState('');
  const [dataBulunduguKatTitle, setDataBulunduguKatTitle] = useState('');
  const [dataManzaraTitle, setDataManzaraTitle] = useState('');
  const [dataIcOzellikTitle, setDataIcOzellikTitle] = useState('');
  const [dataDisOzellikTitle, setDataDisOzellikTitle] = useState('');
  const [dataMuhitTitle, setDataMuhitTitle] = useState('');
  const [dataUlasimTitle, setDataUlasimTitle] = useState('');
  const [dataEngelliyeUygunTitle, setDataEngelliyeUygunTitle] = useState('');
  const [vaadiTextTitle, setVaadiTextTitle] = useState('');
  const [yetkiTextTitle, setYetkiTextTitle] = useState(''); 

  const [dataKiralikSatilikAll, setDataKiralikSatilikAll] = useState([]);
  const [dataKategoriAll, setDataKategoriAll] = useState([]);
  const [dataBinaYasiAll, setDataBinaYasiAll] = useState([]);
  const [dataTapuDurumuAll, setDataTapuDurumuAll] = useState([]);
  const [dataCepheAll, setDataCepheAll] = useState([]);
  const [dataIsitmaTipiAll, setDataIsitmaTipiAll] = useState([]);
  const [dataKonutTipiAll, setDataKonutTipiAll] = useState([]);
  const [dataOdaSayisiAll, setDataOdaSayisiAll] = useState([]);
  const [dataKatDetayAll, setDataKatDetayAll] = useState([]);
  const [dataKatSayisiAll, setDataKatSayisiAll] = useState([]);
  const [dataBulunduguKatAll, setDataBulunduguKatAll] = useState([]);
  const [dataManzaraAll, setDataManzaraAll] = useState([]);
  const [dataIcOzellikAll, setDataIcOzellikAll] = useState([]);
  const [dataDisOzellikAll, setDataDisOzellikAll] = useState([]);
  const [dataMuhitAll, setDataMuhitAll] = useState([]);
  const [dataUlasimAll, setDataUlasimAll] = useState([]);
  const [dataEngelliyeUygunAll, setDataEngelliyeUygunAll] = useState([]);
  const [vaadiTextAll, setVaadiTextAll] = useState([]);
  const [yetkiTextAll, setYetkiTextAll] = useState([]); 
  const [sablon, setDataSablonAll] = useState([]); 


  // React.useEffect(() => {  setDataSablonAll
  //   // We'd get the theme from a web API / local storage in a real app
  //   // We've hardcoded the theme in our example
  //   const currentTest = "lightblue";
  //   setTest(currentTest);
  //   // Console.log(' new context test fied ');
  // }, []);

  const saveStateVals = (id: string, val:any) =>{
    // Console.log(' context savestateval fired. ');
    if (id==="siraNo" && parseInt(val) != siraNo) setSiraNo(val);
    // else if (id==="baslik" && val != baslik) setBaslik(val);
    // else if (id==="detay" && val != detay) setDetay(val);
    // else if (id==="fiyat" && val != fiyat) setFiyat(val);
    // else if (id==="aidat" && val != aidat) setAidat(val);
    // else if (id==="durumu" && val != durumu) setDurumu(val);
    // else if (id==="netm2" && val != netm2) setNetm2(val);
    // else if (id==="brutm2" && val != brutm2) setBrutm2(val);
    // else if (id==="depozito" && val != depozito) setDepozito(val);
    // else if (id==="mahalle" && val != mahalle) setMahalle(val);
    // else if (id==="sehir" && val != sehir) setSehir(val);
    // else if (id==="adresi" && val != adresi) setAdresi(val);
    // else if (id==="sahibi" && val != sahibi) setSahibi(val);
    // else if (id==="bostarih" && val != bostarih) setBostarih(val);
    else if (id.indexOf('iro52')>-1 && val != dataKategoriSel) localStorage.setItem('kategori',val);
    else if (id.indexOf('iro51')>-1 && val != dataKiralikSatilikSel) localStorage.setItem('kiralikSatilik',val);
    else if (id.indexOf('iro53')>-1 && val != dataBinaYasiSel)  localStorage.setItem('binaYasi',val);
    else if (id.indexOf('iro54')>-1 && val != dataTapuDurumuSel)  localStorage.setItem('tapuDurumu',val);  
    else if (id.indexOf('iro55')>-1 && val != dataCepheSel)  localStorage.setItem('cephe',val);
    else if (id.indexOf('iro56')>-1 && val != dataIsitmaTipiSel)  localStorage.setItem('isitmaTipi',val);
    else if (id.indexOf('iro57')>-1 && val != dataKonutTipiSel)  localStorage.setItem('konutTipi',val);
    else if (id.indexOf('iro58')>-1 && val != dataOdaSayisiSel)  localStorage.setItem('odaSayisi',val);
    else if (id.indexOf('iro67')>-1 && val != dataKatSayisiSel)  localStorage.setItem('katSayisi',val);
    else if (id.indexOf('iro65')>-1 && val != dataKatDetaySel)  localStorage.setItem('katDetay',val);
    else if (id.indexOf('iro68')>-1 && val != dataBulunduguKatSel)  localStorage.setItem('bulunduguKat',val);
    // else if (id.indexOf('iro52')>-1 && val != dataKategoriSel) setDataKategoriSel(val);
    // else if (id.indexOf('iro51')>-1 && val != dataKiralikSatilikSel) setDataKiralikSatilikSel(val);
    // else if (id.indexOf('iro53')>-1 && val != dataBinaYasiSel) setDataBinaYasiSel(val);
    // else if (id.indexOf('iro54')>-1 && val != dataTapuDurumuSel) setDataTapuDurumuSel(val);  
    // else if (id.indexOf('iro55')>-1 && val != dataCepheSel) setDataCepheSel(val);
    // else if (id.indexOf('iro56')>-1 && val != dataIsitmaTipiSel) setDataIsitmaTipiSel(val);
    // else if (id.indexOf('iro57')>-1 && val != dataKonutTipiSel) setDataKonutTipiSel(val);
    // else if (id.indexOf('iro58')>-1 && val != dataOdaSayisiSel) setDataOdaSayisiSel(val);
    // else if (id.indexOf('iro67')>-1 && val != dataKatSayisiSel) setDataKatSayisiSel(val);
    // else if (id.indexOf('iro65')>-1 && val != dataKatDetaySel) setDataKatDetaySel(val);
    // else if (id.indexOf('iro68')>-1 && val != dataBulunduguKatSel) setDataBulunduguKatSel(val);

    // else if (id.indexOf('iro65')>-1 && val != dataKatDetaySel) setDataKatDetaySel(val);
    // engelli 66 manzara 
  };

  const dividePars = (parArray: any) => {
    if (parArray===undefined || parArray === null) return null;
    let parAll:any = parArray;
    let kirSat1:any = filterPar(parAll,51);    
    let kirSatFirst:any = kirSat1[0];  
    let kategori1:any = filterPar(parAll,52);    
    let kategoriFirst:any = kategori1[0];
    let binaYasi1:any = filterPar(parAll,53);    
    let binaYasiFirst:any = binaYasi1[0];
    let tapuDurumu1:any = filterPar(parAll,54);    
    let tapuDurumuFirst:any = tapuDurumu1[0];
    let cephe1:any = filterPar(parAll,55);    
    let cepheFirst:any = cephe1[0];
    let isitmaTipi1:any = filterPar(parAll,56);    
    let isitmaTipiFirst:any = isitmaTipi1[0];
    let konutTipi1:any = filterPar(parAll,57);    
    let konutTipiFirst:any = konutTipi1[0];
    let odaSayisi1:any = filterPar(parAll,58);    
    let odaSayisiFirst:any = odaSayisi1[0];
    let katSayisi1:any = filterPar(parAll,67);    
    let katSayisiFirst:any = katSayisi1[0];
    let katDetay1:any = filterPar(parAll,65);    
    let katDetayFirst:any = katDetay1[0];
    let bulunduguKat1:any = filterPar(parAll,68);    
    let bulunduguKatFirst:any = bulunduguKat1[0];
    let engelliyeUygun1:any = filterPar(parAll,66);    
    let engelliyeUygunFirst:any = engelliyeUygun1[0];

    let manzara1:any = filterPar(parAll,59);    
    let manzaraFirst:any = manzara1[0];

    let muhit1:any = filterPar(parAll,63);    
    let muhitFirst:any = muhit1[0];

    let ulasim1:any = filterPar(parAll,64);    
    let ulasimFirst:any = ulasim1[0];

    let disOzellik1:any = filterPar(parAll,62);    
    let disOzellikFirst:any = disOzellik1[0];

    let icOzellik1:any = filterPar(parAll,61);    
    let icOzellikFirst:any = icOzellik1[0];

    let sablon:any = filterPar(parAll,69);    

    setDataKiralikSatilikTitle(kirSatFirst);
    setDataKategoriTitle(kategoriFirst);
    setDataBinaYasiTitle(binaYasiFirst); 
    setDataTapuDurumuTitle(  tapuDurumuFirst  );
    setDataCepheTitle(  cepheFirst  ); 
    setDataIsitmaTipiTitle(  isitmaTipiFirst  ); 
    setDataKonutTipiTitle(  konutTipiFirst  ); 
    setDataOdaSayisiTitle(  odaSayisiFirst  ); 
    setDataKatDetayTitle(  katDetayFirst  ); 
    setDataKatSayisiTitle(   katSayisiFirst );
    setDataBulunduguKatTitle(   bulunduguKatFirst );
    setDataManzaraTitle(   manzaraFirst ); 
    setDataIcOzellikTitle(  icOzellikFirst  );
    setDataDisOzellikTitle(  disOzellikFirst  ); 
    setDataMuhitTitle(  muhitFirst  );
    setDataUlasimTitle(  ulasimFirst  ); 
    setDataEngelliyeUygunTitle(  engelliyeUygunFirst  ); 
    // setVaadiTextTitle(    ); 
    // setYetkiTextTitle(    ); 
  
    // console.log(' kirSat1****** : '+JSON.stringify(kirSat1 ));
    // console.log(' kategori1 ****** :  '+JSON.stringify(kategori1 ));
    // console.log(' binaYasi1 ****** :  '+JSON.stringify( binaYasi1));
    // console.log('  tapuDurumu1 ****** : '+JSON.stringify(tapuDurumu1 ));
    // console.log(' cephe1 ****** :  '+JSON.stringify( cephe1));
    // console.log(' isitmaTipi1 ****** :  '+JSON.stringify(isitmaTipi1 ));
    // console.log('  konutTipi1 ****** : '+JSON.stringify( konutTipi1));
    // console.log(' odaSayisi1 ****** :  '+JSON.stringify(odaSayisi1 ));
    // console.log(' katDetay1 ****** :  '+JSON.stringify(katDetay1 ));
    // console.log('  katSayisi1 ****** : '+JSON.stringify(katSayisi1 ));
    // console.log('  bulunduguKat1 ****** : '+JSON.stringify(bulunduguKat1 ));
    // console.log(' engelliyeUygun1  ****** : '+JSON.stringify(engelliyeUygun1 ));

    setDataKiralikSatilikAll( kirSat1   ); 
    setDataKategoriAll(  kategori1  );
    setDataBinaYasiAll(binaYasi1); 
    setDataTapuDurumuAll(  tapuDurumu1  );
    setDataCepheAll(  cephe1  ); 
    setDataIsitmaTipiAll(  isitmaTipi1  ); 
    setDataKonutTipiAll(   konutTipi1 ); 
    setDataOdaSayisiAll(  odaSayisi1  ); 
    setDataKatDetayAll(  katDetay1  ); 
    setDataKatSayisiAll( katSayisi1   );
    setDataBulunduguKatAll(  bulunduguKat1  );
    setDataManzaraAll(  manzara1  ); 
    setDataIcOzellikAll(  icOzellik1  );
    setDataDisOzellikAll( disOzellik1   ); 
    setDataMuhitAll(  muhit1  );
    setDataUlasimAll(  ulasim1  ); 
    setDataEngelliyeUygunAll( engelliyeUygun1   ); 
    setDataSablonAll(sablon); 
    // Console.log(" şablon ************* "+JSON.stringify(sablon));
    // setVaadiTextAll(    ); 
    // setYetkiTextAll(    ); 

  }
  
function filterPar(par:any, GroupID:number){
  const rslt = Object.keys(par).filter((item:any)=> 
     par[item]['GroupID']===GroupID
  );
  return rslt;
}

const inputChanged = (e:any) => {
  e.preventDefault();
  e.stopPropagation(); 
  let id = (e.target as HTMLElement).id;
  // Console.log('inputChanged '+e.target);
  let checkText = (e.target as HTMLInputElement).value;
  if (id.includes('arih')) {
    checkText = moment(checkText).format('DD-MM-YYYY');
    // Console.log(' tarihle ilgili bir alan bulduk.'+checkText);
  }
  // Console.log('inputChanged '+(e.target as HTMLElement).id +  ' : ' +checkText);
  localStorage.setItem((e.target as HTMLElement).id,checkText); 
}

  return (
    <TestContext.Provider value={{ theme, setTest,  linkUrl, setLinkUrl, msg, setMsg,
           propid, setPropid,
         propDetay, setPropDetay, 
        imageAsUrlDict, setImageAsUrlDict, propDetayDict, setPropDetayDict,
        parDict, setParDict,
        siraNo, setSiraNo, 
      
        baslik, setBaslik,
        detay, setDetay,
      
        fiyat, setFiyat,
        aidat, setAidat,
        durumu, setDurumu,
        netm2, setNetm2,
        brutm2, setBrutm2,
      
        depozito, setDepozito,
        mahalle, setMahalle,
        sehir, setSehir,
        adresi, setAdresi,
        sahibi, setSahibi,
        bostarih, setBostarih,
      
        dataKiralikSatilikSel, setDataKiralikSatilikSel,
        dataKategoriSel, setDataKategoriSel,
        dataBinaYasiSel, setDataBinaYasiSel,
        dataTapuDurumuSel, setDataTapuDurumuSel,
        dataCepheSel, setDataCepheSel,
      
        dataIsitmaTipiSel, setDataIsitmaTipiSel,
        dataKonutTipiSel, setDataKonutTipiSel,
        dataOdaSayisiSel, setDataOdaSayisiSel,
        dataKatDetaySel, setDataKatDetaySel,
        dataKatSayisiSel, setDataKatSayisiSel,
        dataBulunduguKatSel, setDataBulunduguKatSel,
        dataManzaraSel, setDataManzaraSel,
        dataIcOzellikSel, setDataIcOzellikSel,
        dataDisOzellikSel, setDataDisOzellikSel,
        dataMuhitSel, setDataMuhitSel,
        dataUlasimSel, setDataUlasimSel,
        dataEngelliyeUygunSel, setDataEngelliyeUygunSel,
        vaadiText, setVaadiText,
        yetkiText, setYetkiText,
        proProperty, setProProperty,
        proPropertyEdit, setProPropertyEdit,
        isLoading, setIsLoading,
        isError, setIsError,
        saveStateVals,
        dividePars,

        dataKiralikSatilikTitle,
        dataKategoriTitle,
        dataBinaYasiTitle, 
        dataTapuDurumuTitle,
        dataCepheTitle, 
        dataIsitmaTipiTitle, 
        dataKonutTipiTitle, 
        dataOdaSayisiTitle, 
        dataKatDetayTitle, 
        dataKatSayisiTitle,
        dataBulunduguKatTitle,
        dataManzaraTitle, 
        dataIcOzellikTitle,
        dataDisOzellikTitle, 
        dataMuhitTitle,
        dataUlasimTitle, 
        dataEngelliyeUygunTitle, 
        vaadiTextTitle, 
        yetkiTextTitle, 
      
        dataKiralikSatilikAll, 
        dataKategoriAll,
        dataBinaYasiAll, 
        dataTapuDurumuAll,
        dataCepheAll, 
        dataIsitmaTipiAll, 
        dataKonutTipiAll, 
        dataOdaSayisiAll, 
        dataKatDetayAll, 
        dataKatSayisiAll,
        dataBulunduguKatAll,
        dataManzaraAll, 
        dataIcOzellikAll,
        dataDisOzellikAll, 
        dataMuhitAll,
        dataUlasimAll, 
        dataEngelliyeUygunAll, 
        vaadiTextAll, 
        yetkiTextAll,
      
        setDataKiralikSatilikTitle,
        setDataKategoriTitle,
        setDataBinaYasiTitle, 
        setDataTapuDurumuTitle,
        setDataCepheTitle, 
        setDataIsitmaTipiTitle, 
        setDataKonutTipiTitle, 
        setDataOdaSayisiTitle, 
        setDataKatDetayTitle, 
        setDataKatSayisiTitle,
        setDataBulunduguKatTitle,
        setDataManzaraTitle, 
        setDataIcOzellikTitle,
        setDataDisOzellikTitle, 
        setDataMuhitTitle,
        setDataUlasimTitle, 
        setDataEngelliyeUygunTitle, 
        setVaadiTextTitle, 
        setYetkiTextTitle,       
        setDataKiralikSatilikAll, 
        setDataKategoriAll,
        setDataBinaYasiAll, 
        setDataTapuDurumuAll,
        setDataCepheAll, 
        setDataIsitmaTipiAll, 
        setDataKonutTipiAll, 
        setDataOdaSayisiAll, 
        setDataKatDetayAll, 
        setDataKatSayisiAll,
        setDataBulunduguKatAll,
        setDataManzaraAll, 
        setDataIcOzellikAll,
        setDataDisOzellikAll, 
        setDataMuhitAll,
        setDataUlasimAll, 
        setDataEngelliyeUygunAll, 
        setVaadiTextAll, 
        setYetkiTextAll,         
        sablon, setDataSablonAll,
        inputChanged,
    
        
        }}>
      {children}
    </TestContext.Provider>
  );
};

export const useTest = () => React.useContext(TestContext);

// const Header = () => {
//   const { theme, setTest } = useTest()!;
//   return (
//     <div style={{ backgroundColor: theme }}>
//       <select value={theme} onChange={e => setTest(e.currentTarget.value)}>
//         <option value="white">White</option>
//         <option value="lightblue">Blue</option>
//         <option value="lightgreen">Green</option>
//       </select>
//       <span>Hello!</span>
//     </div>
//   );
// };

// const App = () => (
//   <TestProvider>
//     <Header />
//   </TestProvider>
// );

// const rootElement = document.getElementById("root");
// render(<App />, rootElement);