import React, { Fragment } from 'react';
import {  RouteComponentProps } from 'react-router-dom';
import {  IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonPage } from '@ionic/react';
import { IonContent } from '@ionic/react';

// import HeaderToolBarPublic from '../HeaderToolBarPublic';
// import {  backspaceSharp } from 'ionicons/icons';

// import { useUser } from '../../../context/UserContext';
import HeaderAll from '../HeaderAll';
// import FooterAll from '../FooterAll';


const TermsAndConditions: React.FC<RouteComponentProps> = ({match, history}) => {

  const siteName = "Alkaemlak.com";

  const metin = () => {
    return <Fragment>
      <p> <b> Sürüm 1.0. Son Güncelleme 01.10.2020</b></p>
      <p>
      {siteName + ' "biz", "biz" veya "bizim tarafımızdan işletilen www.'+siteName+' web sitesini (" Hizmet ") kullanmadan önce, lütfen bu Şartlar ve Koşulları (" Şartlar "," Şartlar ve Koşullar ") dikkatlice okuyun. "'}
      </p><p>
      Hizmete erişiminiz ve bu hizmetlerin kullanımı, bu Koşulları kabul etmeniz ve bunlara uymanız şartına bağlıdır. Bu Koşullar tüm ziyaretçiler, kullanıcılar ve Hizmete erişen veya bu hizmeti kullanan diğerleri için geçerlidir.
      
      </p>
  
      <p>
          Hizmete erişerek veya kullanarak, bu Şartlara bağlı kalmayı kabul edersiniz. Şartların herhangi bir bölümüne katılmıyorsanız, Hizmete erişemeyebilirsiniz. {siteName} için Şartlar ve Koşullar sözleşmesi, Şartlar ve Koşullar Üreticisi'nin yardımıyla oluşturuldu.
     
      </p>
     <p><br/>
       <b>  Hesaplar </b><br/>
      Bizimle bir hesap oluştururken, bize her zaman doğru, eksiksiz ve güncel bilgiler sağlamanız gerekir. Bunu yapmamak, Hizmetimizdeki hesabınızın derhal feshi ile sonuçlanabilecek Şartların ihlali anlamına gelir.
         
        </p>
    <p>
       Hizmete erişmek için kullandığınız şifreyi ve şifrenizin Hizmetimizde veya bir üçüncü taraf hizmetinde olsun, şifrenizdeki tüm faaliyet ve eylemlerden korunmasından siz sorumlusunuz.
           
        </p>
  <p>
  
      Şifrenizi üçüncü şahıslara ifşa etmemeyi kabul edersiniz. Herhangi bir güvenlik ihlali veya hesabınızın yetkisiz kullanıldığının farkına vardıktan sonra bizi derhal bilgilendirmelisiniz.
   
  </p><br/>
    <p>
  <b> Diğer Web Sitelerine Bağlantılar</b><br/>
      Hizmetimiz, üçüncü taraf web sitelerine veya {siteName} tarafından sahip olunmayan veya kontrol edilmeyen hizmetlere bağlantılar içerebilir.
   
    
  </p> 
     <p>
  
    
  </p> 
      {siteName}'un üçüncü taraf web sitelerinin veya hizmetlerinin içeriği, gizlilik politikaları veya uygulamaları üzerinde kontrolü yoktur ve hiçbir sorumluluğu yoktur. Ayrıca, {siteName}’un, bu tür herhangi bir içeriğin, malın veya hizmetlerin kullanımında veya bunlara bağlı olarak kullanılmasından veya bunlarla bağlantılı olarak kullanılmasından kaynaklanacak herhangi bir zarar veya ziyandan doğrudan veya dolaylı olarak sorumlu veya sorumlu olmayacağını kabul ve beyan edersiniz. veya bu tür web siteleri veya hizmetler aracılığıyla.
      
      <p>
      Ziyaret ettiğiniz üçüncü taraf web sitelerinin veya hizmetlerin şart ve koşullarını ve gizlilik politikalarını okumanızı önemle tavsiye ederiz.
   
  </p><br/>
         <p>
         <b> Sonlandırma</b><br/>
      Herhangi bir nedenden ötürü herhangi bir nedenle, Şartları ihlal etmeniz durumunda, sınırlama olmaksızın, derhal Hizmetimize erişimi feshedebilir veya askıya alabiliriz.
         
  </p>
     <p>
        Şartları gereği, fesihlerine dayanması gereken tüm hükümler, mülkiyet hükümleri, garanti feragatnameleri, tazminat ve sorumluluk sınırlamaları da dahil olmak üzere, bunlarla sınırlı olmamak üzere, fesihte kalacaktır.
         
         </p>
    <p>
      Herhangi bir nedenden ötürü herhangi bir nedenle, Şartları ihlal etmeniz durumunda sınırlama olmaksızın da derhal hesabınızı feshedebilir veya askıya alabiliriz.
           
         </p>
    <p>
       Fesih üzerine, Hizmeti kullanma hakkınız derhal sona erecektir. Hesabınızı sonlandırmak istiyorsanız Hizmeti kullanmaya son verebilirsiniz.
         
         </p>
     <p>
        Şartları gereği, fesihlerine dayanması gereken tüm hükümler, mülkiyet hükümleri, garanti feragatnameleri, tazminat ve sorumluluk sınırlamaları da dahil olmak üzere, bunlarla sınırlı olmamak üzere, fesihte kalacaktır.
         
         </p><br/>
    <p>
    <b> Geçerli Kanun</b><br/>
      Bu Koşullar, kanun uyuşmazlığı hükümlerine bakılmaksızın, Türkiye yasalarına uygun olarak yönetilir ve yorumlanır.
         
         </p>
     <p>
       Bu Şartların herhangi bir hakkını ya da hükmünü uygulamamamız, bu haklardan feragat sayılmaz. Bu Şartların herhangi bir hükmünün mahkeme tarafından geçersiz veya uygulanamaz olduğuna karar verilirse, bu Şartların geri kalan hükümleri yürürlükte kalacaktır. Bu Koşullar, Hizmetimizle ilgili olarak aramızdaki anlaşmanın tamamını teşkil eder ve Hizmetle ilgili aramızdaki herhangi bir anlaşmanın yerine geçerek yerine geçer.
          
         </p><br/>
    <p>
    <b>   Değişiklikler</b><br/>
      Kendi takdirimize bağlı olarak, bu Şartları herhangi bir zamanda değiştirme veya değiştirme hakkını saklı tutarız. Bir revizyon maddi ise, yeni şartların yürürlüğe girmesinden en az 30 gün önce bildirimde bulunmaya çalışacağız. Maddi bir değişimi neyin oluşturduğu tamamen kendi takdirimize bağlı olarak belirlenir.
         
         </p>
     <p>
      Bu revizyonların yürürlüğe girmesinden sonra Hizmetimize erişmeye veya kullanmaya devam ederek, revize edilmiş şartlara bağlı kalmayı kabul edersiniz. Yeni şartları kabul etmiyorsanız, lütfen Hizmeti kullanmayı bırakın.
          
         </p><br/>
     <p>
     <b> Bizimle iletişime geçin</b><br/>
      Bu Koşullarla ilgili herhangi bir sorunuz varsa, lütfen bize ulaşın.      
         </p>
  
      </Fragment>
    }


  const goToPage = (e: any, urlPath: any) => {
    e.preventDefault(); 
    history.push(urlPath);
  }

  return (
    <IonPage>
    <HeaderAll goToPage={goToPage}/>
    <IonContent>

      <IonCard>
              <IonCardHeader>
                <IonCardSubtitle>Şartlar</IonCardSubtitle>
                <IonCardTitle>Terms And Conditions</IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
               {metin()}


              </IonCardContent>
        </IonCard>

      </IonContent>

    {/* <FooterAll goToPage={goToPage}/>   */}

  </IonPage>




  );



};

export default TermsAndConditions;

