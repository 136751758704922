

export function toasterMsg(message: string, color = "primary", duration = 8) {
    const toast = document.createElement('ion-toast');
    toast.message = message;
    toast.duration = duration*1000;
    toast.position = 'top';
    toast.color = color;    

    document.body.appendChild(toast);
    return toast.present();
  }

//   "primary", "secondary", "tertiary", "success", "warning", "danger", "light", "medium", and "dark"