import { useState, useEffect } from "react";
import { useCamera } from '@ionic/react-hooks/camera';
import { useFilesystem, base64FromPath } from '@ionic/react-hooks/filesystem';
import { useStorage } from '@ionic/react-hooks/storage';
import { isPlatform } from '@ionic/react';
import { CameraResultType, CameraSource, CameraPhoto, Capacitor, FilesystemDirectory } from "@capacitor/core";

import firebase, { storage, db } from "./../firebase/firebase";
import { useTest } from "../context/TestContext";
import { useUser } from "../context/UserContext";
// import { useUser } from "../context/UserContext";

const PHOTO_STORAGE = "photos";

export function usePhotoGallery() {



  const { propid } = useTest();
  const { pathUser } = useUser()!;  
  
  const [photos, setPhotos] = useState<Photo[]>([]);
  const { getPhoto } = useCamera();
  const { deleteFile, getUri, readFile, writeFile } = useFilesystem();
  const { get, set } = useStorage();

  useEffect(() => {
    const loadSaved = async () => {
      const photosString = await get(PHOTO_STORAGE);
      const photosInStorage = (photosString ? JSON.parse(photosString) : []) as Photo[];
      // If running on the web...
      if (!isPlatform('hybrid')) {
        for (let photo of photosInStorage) {
          const file = await readFile({
            path: photo.filepath,
            directory: FilesystemDirectory.Data
          });
          // Web platform only: Save the photo into the base64 field
          photo.base64 = `data:image/jpeg;base64,${file.data}`;
        }
      }
      setPhotos(photosInStorage);
    };
    loadSaved();
  }, [get, readFile]);

  const takePhoto = async () => {
    const cameraPhoto = await getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 60
    });
    const fileName = new Date().getTime() + '.jpeg';
    const savedFileImage = await savePicture(cameraPhoto, fileName);
    const newPhotos = [savedFileImage, ...photos];
    setPhotos(newPhotos);
    set(PHOTO_STORAGE,
      isPlatform('hybrid')
        ? JSON.stringify(newPhotos)
        : JSON.stringify(newPhotos.map(p => {
          // Don't save the base64 representation of the photo data, 
          // since it's already saved on the Filesystem
          const photoCopy = { ...p };
          delete photoCopy.base64;
          return photoCopy;
        })));
        const uploadedFileImage = await uploadPictureTask(savedFileImage, fileName);
  };

  const savePicture = async (photo: CameraPhoto, fileName: string): Promise<Photo> => {
    let base64Data: string;
    // "hybrid" will detect Cordova or Capacitor;
    if (isPlatform('hybrid')) {
      const file = await readFile({
        path: photo.path!
      });
      base64Data = file.data;
    } else {
      base64Data = await base64FromPath(photo.webPath!);
    }
    const savedFile = await writeFile({
      path: fileName,
      data: base64Data,
      directory: FilesystemDirectory.Data
    });

    if (isPlatform('hybrid')) {
      // Display the new image by rewriting the 'file://' path to HTTP
      // Details: https://ionicframework.com/docs/building/webview#file-protocol
      return {
        base64: base64Data,
        filepath: savedFile.uri,
        webviewPath: Capacitor.convertFileSrc(savedFile.uri),
      };
    }
    else {
      // Use webPath to display the new image instead of base64 since it's 
      // already loaded into memory
      return {
        base64: base64Data,
        filepath: fileName,
        webviewPath: photo.webPath
      };
    }
  };


  
  const uploadPictureTask = (photo: Photo, fName: string): void => {
    var data: any;
    data = photo.base64;
    var storageRef = storage.ref();
  
    var uploadTask = storageRef.child(`${pathUser}img/${propid}/${fName}`).putString(data,'data_url',{contentType:'image/jpg'});
  
    uploadTask.on('state_changed', function(snapshot){
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log('Upload is running');
          break;
      }
    }, function(error) {
      // Handle unsuccessful uploads
    }, function() {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
        console.log('File available at', downloadURL);
        uploadOne(downloadURL, pathUser!, 'lnk', propid, fName);
      });
    });
   }

  const deletePhoto = async (photo: Photo) => {
    // Remove this photo from the Photos reference data array
    const newPhotos = photos.filter(p => p.filepath !== photo.filepath);

    // Update photos array cache by overwriting the existing photo array
    set(PHOTO_STORAGE, JSON.stringify(newPhotos));

    // delete photo file from filesystem
    const filename = photo.filepath.substr(photo.filepath.lastIndexOf('/') + 1);
    await deleteFile({
      path: filename,
      directory: FilesystemDirectory.Data
    });
    setPhotos(newPhotos);
  };

  async function delResimTek(siraNo: string, pathUser: string, filename: string){
    let sira = parseInt(siraNo);  
    let fileN =  filename.replace('.jpg', 'jpg');     
    // const ref = db.ref(`${pathUser}doc/${sira}/${filename}`) // ("estate/emr/lnk/1"); ${file.name}
    const refLnk = db.ref(`${pathUser}lnk/${sira}/${fileN}`);  // ("estate/emr/lnk/1"); ${file.name}
    console.log('ref lnk to delete '+refLnk);
    let fName = fileN.replace('png','.png').replace('jpg','.jpg').replace('gif','.gif').replace('jpeg','.jpeg');
    let resimRef = storage.ref(`${pathUser}img/${siraNo}/${fName}`);
    console.log('ref img  to delete '+resimRef);
    resimRef.delete();
    refLnk.remove();
    console.log(' I think one picture deleted ');

}

  function uploadOne(downloadURL:string, pathUser:any, path:string, propid:any, filename:string){
    let projectlnk = downloadURL;
    let fName = filename.replace('.','');
    let estateObj = {
        sira: 0,
        projectlink: projectlnk,
        marks: "lol",
        filename: filename,
        propid: propid,
        headline: 0
    }   
    db.ref(`${pathUser}${path}/${propid}/`).child(`${fName}`).set(estateObj)
    .then(function() {
        console.log("writing done...");
      })
      .catch(function(error) {
        console.log("writing error : "+error);
      });
   } 

  return {
    deletePhoto,
    photos,
    takePhoto
  };
}

export interface Photo {
  filepath: string;
  webviewPath?: string;
  base64?: string;
}




  // const uploadPicture = async (photo: Photo, fileName: string): Promise<Photo> => {
  //   // var storageRef = storage.ref();
  //   let fName = fileName.replace('.','');
  //   let usrPath = pathUser;
    
  //   //toasterMsg('file name'+fName,2);   // Create a reference to 'images/mountains.jpg'
  //   // var mountainImagesRef = storageRef.child(`imagesestate/${fName}`);
  //   let mountainImagesRef = storage.ref().child(`${pathUser}img/${propid}/${fName}`);
  //   var data: any;
  //   console.log('Uploaded a data!');
  //   data = photo.base64;
  //   console.log('photo.base64String '+data);
  //   const result = mountainImagesRef.putString(data, 'data_url').then(function(snapshot) {
  //     console.log('Uploaded a data_url string!');
  //     // counter = counter + 1;
  //     //  toasterMsg('Yüklenen resim sayısı :'+counter);
  //   });
  //   console.log(' result : '+result);
  //   console.log(photo);
  //   return photo;
  // };