//add useState for handling the image as a file and then the image as a url from firebase
import React, {useState} from 'react'
import firebase, {storage,db} from "./../../firebase/firebase"
import { IonContent, IonGrid, IonRow, IonCol, IonImg, IonButton, IonLabel, IonProgressBar } from '@ionic/react'

import { toasterMsg } from './toast/toasterMsg';
import GirisYap from './GirisYap';
import { useTest } from '../../context/TestContext';
import { useUser } from '../../context/UserContext';


function Upload() {
    const {isLogged, pathUser,  path, userShort} = useUser();

    const { propid} = useTest();

    const initState = [] //const [imageAsFile, setImageAsFile] = useState({})
    const [imageAsUrl, setImageAsUrl] = useState(initState) //const [urls, setUrls] = useState(initState)
    const [files, setFiles] = useState(initState)
    const [progressss, setProgressss] = useState(initState)
   
      const handleImageAsFile = (e) => {

        for (let i = 0; i < e.target.files.length; i++) {
          const newFile = e.target.files[i];
          newFile["id"] = Math.random();
       // add an "id" property to each File object
          setFiles(prevState => [...prevState, newFile]);
          // console.log(newFile.name)
        }
      };

    const onUploadSubmission = e => {
      e.preventDefault(); // prevent page refreshing
        toasterMsg('Yükleme işlemi başladı...','primary',10);

        const promises = [];
        files.forEach(file => {
         

         const uploadTask = 
          storage.ref().child(`${pathUser}img/${propid}/${file.name}`).put(file);
            promises.push(uploadTask);
            
            uploadTask.on('state_changed',
               snapshot => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  /* if (snapshot.state === firebase.storage.TaskState.RUNNING) {*/
                  
                   
                    // let valProgress = (totalProgress / maxProgress)/100;
                     console.log( `Progress: ${progress}%`);
                    setProgressss(progress/100);

                  /* }*/
                 },
                 error => console.log(error.code),
                 async () => {
                  //  const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
                  //  console.log(downloadURL);                    
                  //   setImageAsUrl(prevState => [...prevState, downloadURL]);
                  const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
                  .then(function(downloadURL){
                      // console.log(downloadURL);  
                      uploadOne(downloadURL, path, userShort, 'lnk', propid, file.name);
                      setImageAsUrl(prevState => [...prevState, downloadURL]);   
                      // storage.ref().child(`estate/emr/img/1/${file.name}`).put(file);

                  });

                  }
                 )
                
               });
           Promise.all(promises)
            .then(() => {
              //alert('All files uploaded'))
              // urls.forEach(item=>console.log('item '+item))
              console.log(imageAsUrl.length);
            })
            .catch(err => console.log(err.code));
     }

     function uploadOne(downloadURL, pathmain, usr, path, propid, filename){
      // let projectinf = document.getElementById("projectinfo").value;
      // let name = document.getElementById("studentname").value;
      // let cls = document.getElementById("cls").value;
      // let email = document.getElementById("email").value;
      let projectlnk = downloadURL;
      // let marks = document.getElementById("marks").value;
      // let submitfrm = document.getElementById("submitfrm");
      let fName = filename.replace('.','');
      let estateObj = {
          sira: 0,
          projectlink: projectlnk,
          marks: "lol",
          filename: filename,
          propid: propid,
          headline: 0
      }
     // db.ref(`${pathmain}/${usr}/${path}/${propid}/`).child(`${propid}lnk${fName}`).set(estateObj)
      db.ref(`${pathmain}/${usr}/${path}/${propid}/`).child(`${fName}`).set(estateObj)

      .then(function() {
          console.log("writing done...");
        })
        .catch(function(error) {
          console.log("writing error : "+error);
        });
     } 

// {/* <img src={imageAsUrl.imgUrl} alt="image tag" /> */}

  return (


    (propid === undefined || propid === null || propid === 0) ? 
      (
      <GirisYap />
      ) 
      : 
      (

    <IonContent>
    <IonGrid>
    <IonRow>

      <form>
              <input 
                type="file" multiple 
                onChange={handleImageAsFile}
              />
              <IonButton onClick={onUploadSubmission}>Seçili Resimleri Yükle</IonButton>
            
       </form>
       </IonRow><IonRow>
            {files.map((photo, index) => (
              <IonCol size="6" key={photo.name}>
                <IonLabel /*onClick={() => setPhotoToDelete(photo)} src={photo.base64 ?? photo.webviewPath}*/ 
                > {photo.name}</IonLabel>
              </IonCol>
            ))}
</IonRow><IonRow>
            <IonLabel>{'Seçili dosya adet : '+imageAsUrl.length}</IonLabel>
            </IonRow><IonRow>
            <IonProgressBar value={progressss}/>
            </IonRow><IonRow>
            <IonProgressBar></IonProgressBar><br />

    </IonRow> <IonRow>
            {imageAsUrl.map((photo, index) => (
              <IonCol size="6" key={index}>
                <IonImg /*onClick={() => setPhotoToDelete(photo)} src={photo.base64 ?? photo.webviewPath}*/ 
                src={photo} />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>

    </IonContent>
      )


  );
}

export default Upload;

// Promise.all(
//   //Array.map creates a new array with the results 
// // of calling a function for every array element. 
// //In this case Array of "Promises"
//   this.state.filesToUpload.map(item => 
//    this.uploadFileAsPromise(item))
// )
//   .then(url => {
//     console.log(`All success`);

//     //Handle Success all image upload

//   })
//   .catch(error => {
//     console.log(`Some failed: `, error.message);

//     //Handle Failure some/all image upload failed             
//   });


// //return a promise which upload file & get download URL 
// uploadFileAsPromise(imageFile) {
// // the return value will be a Promise
// return storageRef
// .child("images/users/" + imageFile.name)
// .put(imageFile.file) 
// .then(snapshot => {
//   console.log("Uploaded File:", imageFile.name);
//   return snapshot.ref.getDownloadURL().then(downloadURL => {
//     //promise inside promise to get donloadable URL
//     console.log("File available at", downloadURL);
//     );
//   });
// })
// .catch(error => {
//   console.log("Upload failed:", imageFile.name, error.message);
// });
// }