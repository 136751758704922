import React, { useState } from 'react';
import { IonContent,  IonPage, IonCard, IonCardHeader, 
   IonCardTitle, IonCardContent, IonItem, IonIcon, IonButton, IonModal } from '@ionic/react';
// import ExploreContainer from '../components/ExploreContainer';
import './../../Tab3.css';
import {  RouteComponentProps } from 'react-router-dom';
import HeaderAll from '../HeaderAll';
// import FooterAll from '../FooterAll';
import { atCircleOutline, book, documentsOutline, imageOutline, mapOutline, personCircle, searchCircleOutline } from 'ionicons/icons';
import Login from '../Login';
// import { Geolocation} from '@capacitor/core';

const SideMenu1: React.FC<RouteComponentProps> = ({match, history}) => {

  // let latitude: number = 395174;
  // let longitude: number = 7920743;
  const [showModal, setShowModal] = useState(false);

  const goToPage = (e: any, urlPath: any) => {
    e.preventDefault(); 
    history.push(urlPath);
  }

  const handleLogin = (e: any) => {
    e.preventDefault();
    // console.log("login...........");
    setShowModal(true);             
  }

  return (
    <IonPage>
        <HeaderAll goToPage={goToPage}/>
      <IonContent>
      <IonCard>
          <IonCardHeader>
          <IonCardTitle><h1> Site Haritası </h1></IonCardTitle>   

          </IonCardHeader>

          <IonCardContent>

          <IonItem>   <IonIcon icon={imageOutline} slot="start" />
           <IonButton fill="solid"  size="default"  routerLink='/' >
           <IonIcon icon={imageOutline} slot="start" /> ANA SAYFA </IonButton> 
         </IonItem>

         <IonItem>   <IonIcon icon={searchCircleOutline} slot="start" />
           <IonButton fill="solid"  size="default"  routerLink='/dashboard/ara' >
           <IonIcon icon={searchCircleOutline} slot="start" /> EMLAK ARA </IonButton> 
         </IonItem>

         <IonItem>   <IonIcon icon={mapOutline} slot="start" />
           <IonButton fill="solid"  size="default"  routerLink='/dashboard/terms' >
           <IonIcon icon={mapOutline} slot="start" /> SİTE KULLANIM ŞARTLARI </IonButton> 
         </IonItem>

         <IonItem>   <IonIcon icon={book} slot="start" />
           <IonButton fill="solid"  size="default"  routerLink='/dashboard/tofuse' >
           <IonIcon icon={book} slot="start" /> SİTE KULLANIM KOŞULLARI </IonButton> 
         </IonItem>

         <IonItem>   <IonIcon icon={documentsOutline} slot="start" />
           <IonButton fill="solid"  size="default"  routerLink='/dashboard/privacy' >
           <IonIcon icon={documentsOutline} slot="start" />  GİZLİLİK POLİTİKAMIZ</IonButton> 
         </IonItem>

         <IonItem>   <IonIcon icon={atCircleOutline} slot="start" />
           <IonButton fill="solid"  size="default"  routerLink='/dashboard/contactus' >
           <IonIcon icon={atCircleOutline} slot="start" />  İLETİŞİM </IonButton> 
         </IonItem>

         <IonItem>   <IonIcon icon={personCircle} slot="start" />
           <IonButton fill="solid"  size="default"   onClick={ handleLogin}   >
           <IonIcon icon={personCircle} slot="start" />  KULLANICI GİRİŞİ </IonButton> 
         </IonItem>

      </IonCardContent>

        </IonCard>

      
        
      </IonContent>
      {/* <FooterAll goToPage={goToPage}/> */}

            <IonModal isOpen={showModal} cssClass='my-custom-class'>  
        
        <Login></Login>
        <IonButton size="large" onClick={() => setShowModal(false)}>KAPAT</IonButton>
        </IonModal>


    </IonPage>
  );
};
// 40.395174,27.7920743,17
export default SideMenu1;


// <IonContent>
   
// <IonSlides options={sliderOpts}> 
// <IonSlide>   
  
//   </IonSlide>

// </IonSlides>

// </IonContent> 

// const sliderOpts = {
//   zoom: {
//     maxRatio: 4
//   }
// }