/* eslint-disable @typescript-eslint/no-unused-vars */

import React, {  Fragment ,useState} from 'react';
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon } from '@ionic/react';
import { ellipsisHorizontal, personCircle, ellipsisVertical, search} from 'ionicons/icons';
import { useTest } from '../../context/TestContext';

import { IonModal  } from '@ionic/react';
import  './LoginModal.css';
import Login from './Login';
import { useUser } from '../../context/UserContext';
// import Login from './Login';



export default function HeaderToolBar(props) {
  // const {user, setUser } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);

  const { userShort} = useUser();

  const { propid} = useTest();

  const handleLogin = () => {
    // console.log("login...........");
    setShowModal(true);   
  };

  const handleAra = (e) => {
    // console.log("Search...........");
    e.preventDefault();
    // e.stopPropogation();  history.push('/dashboard/users/1');
    // history.push('/ara');
    
  };

  return (
    <Fragment>
          <IonHeader>
        <IonToolbar color="dark">
        <IonButtons slot="start">
            <IonButton color="danger">
              <IonIcon slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="secondary">
            <IonButton onClick={handleLogin} >
              <IonIcon slot="icon-only" icon={personCircle} />
            </IonButton>
            <IonButton  onClick={handleAra} >
              <IonIcon slot="icon-only" icon={search} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton color="danger">
              <IonIcon slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical} />
            </IonButton>
          </IonButtons>
          <IonTitle>{userShort}{' Merhaba : '+propid}</IonTitle>
        </IonToolbar> 
        </IonHeader>  
        <IonModal isOpen={showModal} cssClass='my-custom-class'>
      

      <Login></Login>
        <IonButton onClick={() => setShowModal(false)}>KAPAT</IonButton>
      </IonModal>
     
 
    </Fragment>
  );
}

  //  <IonButton onClick={() => setShowModal(true)}>Show Modal</IonButton>   props.mainTitle+'. '}