



import React, { useLayoutEffect, useEffect } from 'react';
// import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonLabel, IonGrid, IonRow, IonCol, IonInput, IonButton, IonCardContent } from '@ionic/react';
import { IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonItem, IonIcon,  IonButton, IonInput } from '@ionic/react';
import {  wifi, walk, cameraOutline, imageOutline, pencilOutline, addCircleOutline, searchCircle, search, closeCircleSharp, closeCircleOutline } from 'ionicons/icons';
import './../../Tab3.css';
import { useTest } from '../../../context/TestContext';
import {  initLocal, readFirebase } from '../../../utils';
import { toasterMsg } from '../toast/toasterMsg';
import { useUser } from '../../../context/UserContext';

const Sayfa2: React.FC = (props) => {    

  const { pathUser } = useUser()!; 
    const {siraNo, setSiraNo, propDetay, setPropid, 
         setMsg} = useTest();        

    // useEffect(()=>{
       
    //     console.log(' page 2 use effect ');
    // },[])

    // useLayoutEffect(()=>{
    //     console.log(' page 2 use useLayoutEffect ');
    // },[])

    const inputChanged = (e:any) => {
        e.preventDefault();
        e.stopPropagation(); 
        let checkText = (e.target as HTMLInputElement).value;
        // Console.log('inputChanged '+(e.target as HTMLInputElement).id +  ' : ' +checkText);
        localStorage.setItem((e.target as HTMLInputElement).id,checkText); 
        localStorage.setItem('ysirano',checkText); 
        localStorage.setItem('vsirano',checkText); 
        setSiraNo!(checkText);
        setPropid!(checkText);
        toasterMsg('Seçili sirano ..'+checkText,'primary',10);
    }
    const emlakGir = (e:any) => {
        // e.preventDefault();
        // e.stopPropagation(); 
        // Console.log(' emlakGir sira no '+siraNo);
        // Console.log(' getir ');
        let siraNum = localStorage.getItem('siraNo');
        var data: any;
        propDetay.map((item: any) => {
            data = item['detail'][siraNum+''];
        }  ) 
        if (data === undefined || data === null ) {
            toasterMsg('Emlak verileri gelmedi..','danger',10);
            return;
        }
        let myKeys = Object.keys(data);
        if (myKeys === undefined || myKeys === null ) {
            toasterMsg('Emlak verileri gelmedi..','danger',10);
            return;
        }
        myKeys.map((element:any) => {
            // Console.log('propDetayGetir ************** '+element+ ' : '+data[element]);
            Object.keys(data[element]).map((item:any)=>{
                
                if (item==='muhit' || item==='ulasim'  || item==='manzara' ||  item==='disOzellik' ||  item==='icOzellik'  ||  item==='engelliiyeUygun' ) {
                    console.log(item+ ' : çoklu seçim : '+data[element][item]);
                } else {
                    console.log(item+ ' : '+data[element][item]);
                }
                localStorage.setItem(item,data[element][item]);
            });
           
        });
    }
    const emlakSil = (e:any) => {
        e.preventDefault();
        e.stopPropagation(); 
        // console.log(' emlakGir sira no '+siraNo);
        // console.log(' emlak sil ');
    }

    const yeniKayitAc = () => {
        initLocal();        
        const result = readFirebase(pathUser,setPropid,setSiraNo);
        // console.log('yeni kayıt açıldı : '+result);
        setMsg!('Yeni kayıt açıldı');
        toasterMsg('Yeni Kayıt Açıldı. Detay gir. Kaydet. Resim ekle.','primary',10);
      }

    //   const blurFired = (e:any) => {    
    //     // let id = (e.target as HTMLInputElement).id;
    //     // let val = (e.target as HTMLInputElement).value;
    //     // let siraNoVal = localStorage.getItem('siraNo');
    //     // console.log(' blur fired '+id+ ' val '+val);
    //     // const result = saveStateVals!(id, val);
    // }

  return (

      <IonContent>
         
         <IonCard>
          <IonCardHeader>
            {/* <IonCardSubtitle>EMLAK İŞLEMLERİ PANELİ</IonCardSubtitle> */}
            <IonCardTitle>EMLAK İŞLEMLERİ PANELİ</IonCardTitle>
          </IonCardHeader>

          <IonItem>
          <IonIcon icon={search} slot="start" />
            <IonInput className="txtemlakno" id="siraNo" 
            value={siraNo} onIonChange={inputChanged}
            onIonBlur={emlakGir}
            />
            <IonButton fill="solid"  size="default" onClick={emlakGir}>
            <IonIcon icon={searchCircle} slot="start" />
            EMLAK GETİR</IonButton>
          
          </IonItem>

          <IonItem>
            <IonIcon icon={pencilOutline} slot="start" />
            <IonButton fill="solid"  size="default" 
            disabled={(siraNo===0)}  >
            <IonIcon icon={pencilOutline} slot="start" />  SEÇİLİ EMLAK GÜNCELLENSİN</IonButton>
          </IonItem>
          <IonItem>
            <IonIcon icon={imageOutline} slot="start" />
            <IonButton fill="solid"  size="default" 
            disabled={(siraNo===0)}  >
            <IonIcon icon={imageOutline} slot="start" />  SEÇİLİ EMLAK RESİM EKLE</IonButton> 
          </IonItem>
          <IonItem>
            <IonIcon icon={cameraOutline} slot="start" />
            <IonButton fill="solid" size="default" 
            disabled={(siraNo===0)}  >
            <IonIcon icon={cameraOutline} slot="start" />
                SEÇİLİ EMLAK KAMERA</IonButton>  
          </IonItem>
          <IonItem>
            <IonIcon icon={walk} slot="start" />    
            <IonButton fill="solid"  size="default" 
            disabled={(siraNo===0)}  >
            <IonIcon icon={walk} slot="start" />  SATIŞ VAADİ</IonButton>
          </IonItem>

          <IonItem>
            <IonIcon icon={wifi} slot="start" />
            <IonButton   expand="full" fill="solid"  size="default" disabled={(siraNo===0)}  >
            <IonIcon icon={wifi} slot="start" />  YETKİ BELGESİ</IonButton>      
          </IonItem>

          <IonItem>
            <IonIcon icon={closeCircleSharp} slot="start" />
            <IonButton  onClick={emlakSil} expand="full" fill="solid"  size="default" 
            disabled={(siraNo===0)}  >
            <IonIcon icon={closeCircleOutline} slot="start" /> EMLAK SİL</IonButton>      
          </IonItem>


          <IonItem>
            <IonIcon icon={addCircleOutline} slot="start" />
            <IonButton  onClick={yeniKayitAc} fill="solid" size="default"
             disabled={true}  > YENİ EMLAK EKLEME
            <IonIcon icon={addCircleOutline} slot="start" />
            
            </IonButton>      
          </IonItem>

          <IonCardContent>
            Bu ekrandan emlak güncelleme işlemlerini yapabilirsiniz. 
            Yeni bir emlak giriş, girilen emlak güncellemesi,
            emlak için resim ekleme, kamera ile çekilen resimlerin anında eklenmesi,
            eklenen resimleri silme bu ekrandan yapılabilir.
         </IonCardContent>
        </IonCard>
        

      </IonContent>
    
  );
};

export default Sayfa2;

// {/* <IonGrid>
// <IonRow>
//     <IonCol size="3"></IonCol>
//     <IonCol></IonCol>
//     <IonCol></IonCol>
// </IonRow>
// </IonGrid> */}

        //   {/* <IonItem>
        //     <IonIcon icon={wine} slot="start" />

        //     <IonButton fill="outline" ></IonButton>
        //   </IonItem> */}