import React, { useState, useEffect } from 'react';
import { IonContent, IonFab, IonFabButton, IonIcon, IonGrid, IonRow, IonCol, IonImg, IonActionSheet } from '@ionic/react';
import { camera, trash, close } from 'ionicons/icons';
import { usePhotoGallery, Photo } from '../hooks/usePhotoGallery';
import { useTest } from '../context/TestContext';
import { toasterMsg } from './estate/toast/toasterMsg';
import GirisYap from './estate/GirisYap';
// import HeaderToolBar from './estate/HeaderToolBar';
// import { useUser } from '../context/UserContext';

const Tab2: React.FC = () => {

  // const {isLogged} = useUser();

  const { propid} = useTest();


  const { deletePhoto, photos, takePhoto } = usePhotoGallery();
  const [photoToDelete, setPhotoToDelete] = useState<Photo>();
  const [localMsg, setLocalMsg] = useState('');

  useEffect(() => {
    if (localMsg!='' && localMsg!=null && localMsg!=undefined){
      toasterMsg(localMsg,'primary',10);
    }  
  },[localMsg]);
  return (
    // <IonPage>
    //   <IonHeader>
    //     <IonToolbar>
    //       <IonTitle>Kamera </IonTitle>
    //     </IonToolbar>
    //   </IonHeader>

(propid === undefined || propid === null || propid === 0) ? 
(
<GirisYap />
) 
: 
(
      <IonContent>
      <IonGrid>
          <IonRow>
            {photos.map((photo, index) => (
              <IonCol size="4" key={index}>
                <IonImg onClick={() => setPhotoToDelete(photo)} src={photo.base64 ?? photo.webviewPath} />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
        <IonFab key="bottomtakephoto" vertical="top" horizontal="start" slot="fixed">
          <IonFabButton onClick={() => takePhoto()}>
            <IonIcon icon={camera}></IonIcon>
          </IonFabButton>
        </IonFab>
        <IonActionSheet
          isOpen={!!photoToDelete}
          buttons={[{
            text: 'Delete',
            role: 'destructive',
            icon: trash,
            handler: () => {
              if (photoToDelete) {
                deletePhoto(photoToDelete);
                setPhotoToDelete(undefined);
              }
            }
          }, {
            text: 'Cancel',
            icon: close,
            role: 'cancel'
          }]}
          onDidDismiss={() => setPhotoToDelete(undefined)}
        />
      </IonContent>)



  );

};

export default Tab2;


// return (
//   <IonPage>
//     <IonHeader>
//       <IonToolbar>
//         <IonTitle>Photo Gallery</IonTitle>
//       </IonToolbar>
//     </IonHeader>
//     <IonContent>
//     <IonHeader collapse="condense">
//         <IonToolbar>
//           <IonTitle size="large">Photo Gallery</IonTitle>
//         </IonToolbar>
//       </IonHeader>
//       <IonGrid>
//         <IonRow>
//           {photos.map((photo, index) => (
//             <IonCol size="6" key={index}>
//               <IonImg onClick={() => setPhotoToDelete(photo)} src={photo.base64 ?? photo.webviewPath} />
//             </IonCol>
//           ))}
//         </IonRow>
//       </IonGrid>

//       <IonFab vertical="bottom" horizontal="center" slot="fixed">
//         <IonFabButton onClick={() => takePhoto()}>
//           <IonIcon icon={camera}></IonIcon>
//         </IonFabButton>
//       </IonFab>

//       <IonActionSheet
//         isOpen={!!photoToDelete}
//         buttons={[{
//           text: 'Delete',
//           role: 'destructive',
//           icon: trash,
//           handler: () => {
//             if (photoToDelete) {
//               deletePhoto(photoToDelete);
//               setPhotoToDelete(undefined);
//             }
//           }
//         }, {
//           text: 'Cancel',
//           icon: close,
//           role: 'cancel'
//         }]}
//         onDidDismiss={() => setPhotoToDelete(undefined)}
//       />


//     </IonContent>
//   </IonPage>
// );