
      
import React, {useState, useEffect, Fragment} from 'react'
import  { db} from "../firebase/firebase"
import {  IonGrid, IonRow, IonCol, IonImg,  IonLabel ,IonCard,   IonItem,
  IonThumbnail,  IonCardContent } from '@ionic/react'

import './Tab3.css';
import { useTest } from '../context/TestContext';

// import HeaderToolBarPublic from './estate/HeaderToolBarPublic';
import { useUser } from '../context/UserContext';
import {  isNullMbArray } from '../utils';
import { toasterMsg } from './estate/toast/toasterMsg';

export default function Tab1EmlakList(props) {

  const {pathUser} = useUser();

  const { imageAsUrlDict, setImageAsUrlDict,propDetayDict,setPropDetayDict, propDetay, setPropDetay, setLinkUrl } = useTest();


    const [loading, setLoading] = useState(true)
    const [showDetail, setShowDetail] = useState(false);
    // const [toastQue, setToastQue] = useState(false)
  
  const toggleDetail = ()=> {setShowDetail(!showDetail)};




    
  function Welcome(props) {

    if (propDetay===undefined || propDetay === null) return null;
    return ( 
      <Fragment> 
      {propDetay.map((profile, index) => (
        // <IonCard key={'kard-'+index}>

        <Welcome2 profile={profile['detail']} key={index+'w'}/>  
        // </IonCard>     
      ))}
      </Fragment>      
      )  
  }

  function Welcome2(props) {
    if (props.profile===undefined || props.profile === null) return null;
    let dataSet = Object.keys(props.profile).reverse(); //.sort().reverse();
    console.log(' dataset : '+JSON.stringify(dataSet)); // dataset : ["4","3","1"]
      return ( 
        <div> 
            {dataSet.map((data, index) => (
                <IonCard key={index+'p'}>
                <PropDetails1 profile={propDetayDict[data]} key={index+'wps'}/>   
                <Welcome3 profile={imageAsUrlDict[data]} key={index+'w'}/>
                <IonGrid key={index+'wsigrid'}> 
                  <IonRow  key={index+'wsirow'} >
                    <SingleImage profile={imageAsUrlDict[data]} propData={propDetayDict[data]} key={index+'wsi'}/>
                    {(showDetail) ? <PropDetails2 profile={propDetayDict[data]} key={index+'wpspd2'}/> : null}

                  </IonRow>
                </IonGrid>
                </IonCard>       
            ))}

        </div>      
        )  
 }

 function Welcome3(props) {

  if (props.profile===undefined || props.profile === null) return null;
  let dta = Object.keys(props.profile);
  // Console.log(JSON.stringify(dta));
  if (dta.length===0) return null;

        return ( 
          <IonItem> 
          {Object.keys(props.profile).map((data, index) => (
            <p key={index+'pww'}>
              {/* <IonLabel key={index+'iw'}>{JSON.stringify(props.profile[data].propid)}</IonLabel>  */}
              <IonThumbnail key={data} className="ionthumb" name={props.profile[data].propid+'_'+data+'_thumbnail'}>
                <IonImg src={props.profile[data].projectlink} 
                alt={props.profile[data].projectlink}
                onClick={thumbClicked}
                name={props.profile[data].propid+'_'+data+'_thumb'}
                id={props.profile[data].propid+'_'+data+'_thumb'}
                className="ionthumbpic"
                />
              </IonThumbnail>
              </p>       
          ))}         
          </IonItem>      
          )  
 }



 function SingleImage(props) {
          if (props.profile===undefined || props.profile === null) return null;
          var objKeys = Object.keys(props.profile);
          // console.log(" objKeys : "+JSON.stringify(objKeys)); // resimler var burada 
          if (objKeys.length===0) return null;
          let propDataKeys = Object.keys(props.propData);
         //  console.log(" propDataKeys : "+JSON.stringify(props.propData[propDataKeys])); //  propDataKeys : ["4"]
          return ( 
            (showDetail) ? 
            <IonCol size="6" /*sizeLg="4" offsetLg="2" sizeXl="6" offsetXl="2" */>          
              <IonImg src={props.profile[objKeys[0]].projectlink} heigh={'200px'}
              alt={'mainpic'+props.profile[objKeys[0]].propid} name={'mainpic'+props.profile[objKeys[0]].propid}  />     
            </IonCol>  
            : <Fragment>  
            <IonCol size="12" sizeLg="8" sizeMd="8"  sizeSm="12" sizeXs="12" >     <IonCard>   
              <IonImg src={props.profile[objKeys[0]].projectlink} heigh={'200px'}
              alt={'mainpic'+props.profile[objKeys[0]].propid} name={'mainpic'+props.profile[objKeys[0]].propid}  />   </IonCard>   
            </IonCol> 
            <IonCol className="smallimages" size="12" sizeLg="4" sizeMd="4" sizeSm="12" sizeXs="12" key={'colimg'+props.profile[objKeys[0]].propid}  >

                 <IonCard>


                  <IonImg src={props.profile[objKeys[objKeys.length-1]].projectlink} heigh={'100px'} alt="image" 
                  key={'smallimg'+props.profile[objKeys[objKeys.length-1]].propid+'w12'} />

                <IonCardContent>                      
                     <IonLabel className="baslikLbl"> {' EMLAK DETAYLARI : '}&nbsp; &nbsp;&nbsp;{props.propData[propDataKeys].baslik} &nbsp; &nbsp;&nbsp;{props.propData[propDataKeys].detay}  </IonLabel>
                      <IonGrid>
                        <IonRow>
                       {(props.propData[propDataKeys].sira) &&  <Fragment><IonCol size="3" className='gridcollabel'> SıraNo:</IonCol>     <IonCol size="3" className='gridcolval'> {props.propData[propDataKeys].sira} </IonCol></Fragment>}
                       {(props.propData[propDataKeys].kiralikSatilik) &&  <Fragment><IonCol size="3" className='gridcollabel'> Kira/Sat</IonCol>    <IonCol size="3" className='gridcolval'> {isNullMbArray(props.propData[propDataKeys].kiralikSatilik,'')}  </IonCol></Fragment>}
                       {(props.propData[propDataKeys].mahalle) &&  <Fragment><IonCol size="3" className='gridcollabel'>  Mahalle</IonCol>     <IonCol size="3" className='gridcolval'> {props.propData[propDataKeys].mahalle} </IonCol></Fragment>}
                       {(props.propData[propDataKeys].sehir) &&  <Fragment><IonCol size="3" className='gridcollabel'> Şehir</IonCol>    <IonCol size="3" className='gridcolval'>  {props.propData[propDataKeys].sehir} </IonCol></Fragment>}
                       {(props.propData[propDataKeys].durumu) &&  <Fragment><IonCol size="3" className='gridcollabel'> Durumu</IonCol>    <IonCol size="3" className='gridcolval'>{props.propData[propDataKeys].durumu}   </IonCol></Fragment>}
                       {(props.propData[propDataKeys].fiyat) &&  <Fragment><IonCol size="3" className='gridcollabel'> Fiyat</IonCol>    <IonCol size="3" className='gridcolval'>  {props.propData[propDataKeys].fiyat} </IonCol></Fragment>}
                       {(props.propData[propDataKeys].aidat) &&  <Fragment><IonCol size="3" className='gridcollabel'> Aidat</IonCol>     <IonCol size="3" className='gridcolval'> {props.propData[propDataKeys].aidat} </IonCol></Fragment>}
                       {(props.propData[propDataKeys].depozito) &&  <Fragment><IonCol size="3" className='gridcollabel'> Depozito</IonCol>    <IonCol size="3" className='gridcolval'> {props.propData[propDataKeys].depozito}  </IonCol></Fragment>}
                       {(props.propData[propDataKeys].netm2) &&  <Fragment><IonCol size="3" className='gridcollabel'> NetM2</IonCol>    <IonCol size="3" className='gridcolval'>  {props.propData[propDataKeys].netm2} </IonCol></Fragment>}
                       {(props.propData[propDataKeys].brutm2) &&  <Fragment><IonCol size="3" className='gridcollabel'> BrütM2</IonCol>    <IonCol size="3" className='gridcolval'> {props.propData[propDataKeys].brutm2}  </IonCol></Fragment>}
                       {(props.propData[propDataKeys].binaYasi) &&  <Fragment><IonCol size="3" className='gridcollabel'> BinaYaşı</IonCol>     <IonCol size="3" className='gridcolval'>{isNullMbArray(props.propData[propDataKeys].binaYasi,'')}  </IonCol></Fragment>}
                       {(props.propData[propDataKeys].cephe) &&  <Fragment><IonCol size="3" className='gridcollabel'> Cephe</IonCol>     <IonCol size="3" className='gridcolval'>  {isNullMbArray(props.propData[propDataKeys].cephe,'')} </IonCol></Fragment>}
                       {(props.propData[propDataKeys].isitmaTipi) &&  <Fragment><IonCol size="3" className='gridcollabel'> Isıtma</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.propData[propDataKeys].isitmaTipi,'')}  </IonCol></Fragment>}
                       {(props.propData[propDataKeys].odaSayisi) &&  <Fragment><IonCol size="3" className='gridcollabel'> OdaSay.</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.propData[propDataKeys].odaSayisi,'')}  </IonCol></Fragment>}
                                     
                       {(props.propData[propDataKeys].katSayisi) &&  <Fragment><IonCol size="3" className='gridcollabel'> KatSay.</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.propData[propDataKeys].katSayisi,'')}  </IonCol></Fragment>}
                       {(props.propData[propDataKeys].katDetay) &&  <Fragment><IonCol size="3" className='gridcollabel'> Kat.</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.propData[propDataKeys].katDetay,'')}  </IonCol></Fragment>}
                       {(props.propData[propDataKeys].bulunduguKat) &&  <Fragment><IonCol size="3" className='gridcollabel'> Bul.Kat.</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.propData[propDataKeys].bulunduguKat,'')}  </IonCol></Fragment>}
                       {(props.propData[propDataKeys].kategori) &&  <Fragment><IonCol size="3" className='gridcollabel'> Kategori</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.propData[propDataKeys].kategori,'')}  </IonCol></Fragment>}
                       {(props.propData[propDataKeys].tapuDurumu) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Tapu</IonCol>     <IonCol size="3" className='gridcolval2'> {isNullMbArray(props.propData[propDataKeys].tapuDurumu,'')}  </IonCol></Fragment>}
                       </IonRow> 
                       <IonRow>
                       {(props.propData[propDataKeys].manzara) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Manzara</IonCol>     <IonCol size="9" className='gridcolval2'> {props.propData[propDataKeys].manzara}  </IonCol></Fragment>}
                       {(props.propData[propDataKeys].muhit) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Muhit</IonCol>     <IonCol size="9" className='gridcolval2'> {props.propData[propDataKeys].muhit}  </IonCol></Fragment>}
                       {(props.propData[propDataKeys].ulasim) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Ulaşım</IonCol>     <IonCol size="9" className='gridcolval2'> {props.propData[propDataKeys].ulasim}  </IonCol></Fragment>}
                       {(props.propData[propDataKeys].engelliyeUygun) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Engelli</IonCol>     <IonCol size="9" className='gridcolval2'> {props.propData[propDataKeys].engelliyeUygun}  </IonCol></Fragment>}
                       {(props.propData[propDataKeys].disOzellik) &&  <Fragment><IonCol size="3" className='gridcollabel2'> DışÖz.</IonCol>     <IonCol size="9" className='gridcolval2'> {props.propData[propDataKeys].disOzellik}  </IonCol></Fragment>}
                       {(props.propData[propDataKeys].icOzellik) &&  <Fragment><IonCol size="3" className='gridcollabel2'> İçÖz.</IonCol>     <IonCol size="9" className='gridcolval2'> {props.propData[propDataKeys].icOzellik}  </IonCol></Fragment>}


                        
                        </IonRow>                    
                      </IonGrid>

                 

                </IonCardContent>
                  </IonCard>             
                 
            </IonCol> 
            </Fragment>
            )  
 }


 function detayGoster(props){
  props.preventDefault();
 //  console.log(props);
  toggleDetail(); 
}

 function PropDetails1(props) {
  if (props.profile===undefined || props.profile === null) return null;
  let dta = Object.keys(props.profile);
  if (dta.length===0) return null;
            return ( 
              <div key={'iwdidd'}> 
              {Object.keys(props.profile).map((data, index) => (
                (props.profile[data].baslik!==undefined && props.profile[data].baslik!==null) ?
                (<div key={index+'iwdid'} className="propdetailhead">
                  <button  name={data+'_btndetaygoster'}  onClick={detayGoster} 
                  className="lblsegment detaybutton" key={index+'iw123di'}>{'Detay'}</button> 
                  <IonLabel  className="txtsegment propid" key={index+'iwdiwe'}>{' : '+data+' - '}</IonLabel> 
                  <IonLabel  className="txtsegment baslik" key={index+'ii'}>{props.profile[data].baslik+' - '} </IonLabel> 
                  <IonLabel  className="txtsegment detay" key={index+'iibb'}>{props.profile[data].detay}</IonLabel> 
                  </div>) : null
              ))}
              </div>      
              )  
}

function PropDetails2(props) {
  if (props.profile===undefined || props.profile === null) return null;
  let dta = Object.keys(props.profile);
  if (dta.length===0) return null;
            return ( 
              <IonCol size="6" /*key={'iwdidd'}   sizeLg="4" offsetLg="2" sizeXl="6" offsetXl="2"*/ > 
              {Object.keys(props.profile).map((data, index) => (
                <div key={index+'iwdid'} className="propdetailhead propdetailsdiv">
                 
                  <IonLabel  className="txtsegment propid" key={index+'iwd5iwe'}>{'Emlak No : '+data+''}</IonLabel> <hr/>
                  <IonLabel  className="txtsegment baslik" key={index+'i5i'}>{'Başlık : '+props.profile[data].baslik+' - '} </IonLabel> <hr/>
                  <IonLabel  className="txtsegment detay" key={index+'i5ibb'}>{'Detay : '+props.profile[data].detay}</IonLabel> <hr/>

                  <IonLabel  className="txtsegment detay" key={index+'ii55bb'}>{'Fiyat : '+props.profile[data].fiyat}</IonLabel> <hr/>
                  <IonLabel  className="txtsegment detay" key={index+'i444ibb'}>{'Aidat : '+props.profile[data].aidat}</IonLabel> <hr/>
                  <IonLabel  className="txtsegment detay" key={index+'iib4b'}>{'Depozito : '+props.profile[data].depozito}</IonLabel> <hr/>                  
                  <IonLabel  className="txtsegment detay" key={index+'ii555bb'}>{'Durum : '+props.profile[data].durumu}</IonLabel> <hr/>
                  <IonLabel  className="txtsegment detay" key={index+'i4ibb'}>{'Netm2 : '+props.profile[data].netm2}</IonLabel> <hr/>
                  <IonLabel  className="txtsegment detay" key={index+'i44ibb'}>{'Brutm2 : '+props.profile[data].brutm2}</IonLabel> <hr/>

                  <IonLabel  className="txtsegment detay" key={index+'iib44b'}>{'Mahalle : '+props.profile[data].mahalle}</IonLabel> <hr/>
                  <IonLabel  className="txtsegment detay" key={index+'ii575bb'}>{'Sehir : '+props.profile[data].sehir}</IonLabel> <hr/>
                  <IonLabel  className="txtsegment detay" key={index+'i55ibb'}>{'Detay : '+props.profile[data].bostarih}</IonLabel> <hr/>
  
                  </div>
              ))}
              </IonCol>      
              )  
}

function thumbClicked(e){
    // Console.log(e.currentTarget);
    let alt = e.currentTarget.alt;
    let name = e.currentTarget.id;
    if (name!==undefined && name!==null){

      let ext = 'mainpic'+name.substr(0,name.indexOf('_'));
      // Console.log(' thumbClicked '+name+' '+alt+' '+ext);    

        let control = document.querySelector('ion-img[name="'+ext+'"]')
        if (control!==undefined && control!==null) {
            control.src = alt;
            //console.log(' mainpic '+control.name);
        } 
    } else {
      // Console.log('no name');
    }

    
    
};

useEffect(()=>{  

  let isCancelled = false;
  if (!isCancelled) {
      var urls = []
      var dictLink = {};
      var dictProp= {};
      // var dictAll= {};
      // var sizes = [];
      const ref = db.ref(`${pathUser}`).orderByChild("desc") 
          //console.log(ref)
          ref.on("value", function(snapshot) {
            if (snapshot!==undefined) {
              let result = snapshot.val();
                  if (result !== undefined && result !== null) {   
                    Object.keys(result).map(item=>{

                      urls.push({item: item, detail: result[item]});
                      // sizes = Array.from(Object.keys(result[item]));
                      //  console.table(' sizes '+sizes); 
                      // console.log('keys : '+JSON.stringify(Object.keys(result[item])))
                      Object.keys(result[item]).map((key) => {
                        // console.table('key ' +item+' '+key);
                        if (item==='doc'){
                          dictProp[key+''] = result[item][key];
                        } else {
                          dictLink[key+''] = result[item][key];
                        }
                        // console.table(' ** detail new : '+JSON.stringify(result[item][key]));    
                      })
                    });
                  }
                if (urls.length>0) {
                  if (!isCancelled) {

                    var newArrayLnk = urls.filter(function(item) {
                      return item.item === 'lnk';
                    });
                    var newArrayDoc = urls.filter(function(item) {
                      return item.item === 'doc';
                    });
                    
                    // newArrayLnk.map((item)=> {
                    //   //console.log('*** '+JSON.stringify(item.detail))
                    // }  )
                    // sizes.forEach((val, index)=>
                    //  //  console.log(' val index '+val+' '+ index)
                    // )

                    setLoading(false);
                    setLinkUrl(newArrayLnk);
                    setPropDetay(newArrayDoc);
                    setImageAsUrlDict(dictLink);
                    setPropDetayDict(dictProp);
                    setImageAsUrlDict(dictLink);
                    setPropDetayDict(dictProp);
                    // // store.setImageAsUrl(urls);
                  }
                } else {
                // console.log("veri gelmedi... ")  
                toasterMsg("veri gelmedi... ",10);
              } 
            }
          }, function (errorObject) {
            // console.log("The read failed: " + errorObject.code);
            toasterMsg("The read failed: " + errorObject.code,10);
          });
  }
  return () => {
    isCancelled = true;
    setLoading(false);
   //  console.log('emlak list side effects.');
  }
},[loading]);
  




 // 
  return (
   

       
        <Welcome name={'mustafa'}></Welcome>    


  );
}

 
  // {/* 
  
  //    <IonPage>
  // <HeaderToolBarPublic></HeaderToolBarPublic>  
  //  <IonContent>
  //      </IonContent>
  //    </IonPage> 
  
  // */}
// xs < 540px
// sm > 576px
// md > 768px
// lg > 992px
// xl > 1200px


  // function proDetails(props) {  
  //   return (
  //     <IonLabel className="txtsegment propid">{JSON.stringify(props.name)}</IonLabel>
  //   )
  // }

  

                   // <IonCardHeader>
                   
                  //   <IonCardTitle>{props.propData[propDataKeys].baslik} &nbsp; &nbsp;&nbsp;{props.propData[propDataKeys].detay} </IonCardTitle>
                 
          
                  // </IonCardHeader>  
//  {objKeys.map(item=> 
                
//   <IonImg src={props.profile[item].projectlink} heigh={'100px'} alt="image" 
// key={'smallimg'+props.profile[objKeys[0]].propid+'w'+item} />

//   )}
 //size="12" size-lg="8" offset-lg="2" size-xl="6" offset-xl="3" style="background: var(--ion-color-primary);">
 