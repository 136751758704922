

import React, { useEffect, useLayoutEffect, Fragment } from 'react'
import {  IonItem, IonAvatar, IonImg, IonLabel, IonContent, IonCard, IonCardContent, IonThumbnail, IonGrid, IonRow, IonCol } from '@ionic/react'
import {  isNullMbArray } from '../../../utils'
import { useTest } from '../../../context/TestContext';
import { useUser } from '../../../context/UserContext';
// import { arrowDownCircleSharp, arrowDownOutline } from 'ionicons/icons';
import './DList.scss';
import './../../Tab3.css';
const DList = (props: any) => {

    const { imageAsUrlDict} = useTest();
    const {setIsSearch } = useUser();   

    useEffect(() => {
        // console.log(' dashboard use effect FALSE'+JSON.stringify(props.search));
        if (props.refresh) {          
        // parYetkiGetir();
        // sablonYetkiSablonGetir();
          props.setRefresh(false);
          // console.log(' dlist use effect TRUE search '+JSON.stringify(props.search));
        }
      },[props])   

    useLayoutEffect(()=>{
        // console.log(' dlist use useLayoutEffect FALSE dict '+JSON.stringify(props.search));
      if (props.refresh) {       
        props.setRefresh(false);   
          // console.log(' dlist use useLayoutEffect  search '+JSON.stringify(props.search));
         } 
      },[props])
      //       <IonButton color="danger" onClick={handleDetay} id={'cmdDetay'+profile} fill="outline"><IonIcon slot="icon-only" icon={arrowDownOutline} /></IonButton>     
      
      function thumbClicked(e: any){
        let alt = e.target.alt;
        let name = e.target.id;
        let ext = 'mainpic'+name.substr(0,name.indexOf('_'));
        // console.log(' thumbClicked '+name+' alt '+alt+'  ext '+ext);   
        // document.getElementById(ext).src = ""; 
        // let control: HTMLIonImgElement = null;

        // let control = (<HTMLImageElement>document.querySelector('ion-img[id="'+ext+'"]'));
        let control:  HTMLIonImgElement= document.querySelector('ion-img[id="'+ext+'"]') || new HTMLIonImgElement();
        if (control !== undefined && control !== null ) {
            control.src = alt;
            // console.log(' mainpic '+name);
            }
    }

    function propertyShortLine(profile: any) {
        let myObj = props.propList[profile][profile];
        let imgsKeys = Object.keys(imageAsUrlDict[profile]);
        var objKeys = Object.keys(props.propList[profile]);     
        // console.log(" single line profile: "+profile+ ' objKeys: '+JSON.stringify(objKeys)+ ' myObj: '+
        // JSON.stringify(myObj)+ ' imgs: '+JSON.stringify(imgsKeys));
  
       return ( 
         <details className="default square" key={profile+'iiifidetailsm'}>
        <summary> <span className="open">  </span><span className="close"></span> 
        <IonItem key={profile+'iiifitem'}  className="default square"    >  
        {imgsKeys.map((data: any, index: number) => 
                (index===0) ?  
                <IonAvatar key={data} className="ionthumbavatar">
                    <IonImg src={imageAsUrlDict[profile][data].projectlink} 
                    alt={imageAsUrlDict[profile][data].projectlink}
                    onClick={props.thumbClicked} 
                    id={imageAsUrlDict[profile][data].propid+'_'+data+'_thumb'}  className="ionthumbpic"
                    key={'smalsssdalimg'+imageAsUrlDict[profile][data].propid+'w12wq'}
                    />
                </IonAvatar>
                : null
            )}   
            <IonLabel key={profile+'ilf'}> {'('+profile+') '+myObj.baslik+' '+myObj.detay+' '+myObj.sehir+' '}  </IonLabel>
        </IonItem>  
       </summary>  
          <Welcome2 myObj={myObj} objKeys={objKeys} imgsKeys={imgsKeys} profile={profile}/> 
       </details>
       )
    };


    // <IonLabel>  size 12 </IonLabel>    
     function Welcome2(props: any) {
          return ( 
            <IonItem className="sborder"> 
              <IonGrid>
              <IonRow>
                    <IonCol size="12">                
                          {/* thumbs tam kolon */}
                        <IonItem key="thumbbigg"> 
                        {props.imgsKeys.map((data: any, index: number) => 
                            <IonThumbnail key={data+'it321'} className="ionthumbbigger">
                                <IonImg src={imageAsUrlDict[props.profile][data].projectlink} 
                                alt={imageAsUrlDict[props.profile][data].projectlink}
                                onClick={thumbClicked} 
                                id={imageAsUrlDict[props.profile][data].propid+'_'+data+'_thumb'}  className="ionthumbpic"
                                key={'smalsdslimg'+imageAsUrlDict[props.profile][data].propid+'w12wq'+data}
                                />
                            </IonThumbnail>             
                        )}                            
                          </IonItem>  
                    </IonCol>
                   
                  </IonRow>
                  <IonRow> {/* tekresim 8 lik kolon  <IonLabel>  size 8 </IonLabel>  */}
                    <IonCol  size="12" sizeLg="8" sizeMd="8"  sizeSm="12" sizeXs="12" >

                    {props.imgsKeys.map((data: any, index: number) => 
                            ( index === 0 ) ? <IonCard key="cardmainpic">
                              <img src={imageAsUrlDict[props.profile][data].projectlink} 
                                alt={imageAsUrlDict[props.profile][data].projectlink}
                                id={'mainpic'+props.profile}  className="mainpic8lik"
                                key={'mainpic'+imageAsUrlDict[props.profile][data].propid+'w12wq'}
                                /> </IonCard> : null                                       
                        )}
                    
                    </IonCol> {/* prop detay 4 lik kolon  <IonLabel>  size 4 </IonLabel>   */}
                    <IonCol  size="12" sizeLg="4" sizeMd="4" sizeSm="12" sizeXs="12"> 
                    <IonCard key="cardmainpic2">

                    {props.imgsKeys.map((data: any, index: number) => 
                            ( index === props.imgsKeys.length-1 ) ?
                              <IonImg src={imageAsUrlDict[props.profile][data].projectlink} 
                                alt={imageAsUrlDict[props.profile][data].projectlink}
                                id={'mainpic'+props.profile+'yedek'}  className="mainpic4lik"
                                key={'mainpicyedek'+imageAsUrlDict[props.profile][data].propid+'w1e2wq'+data}
                                />  : null                                       
                        )}

                              <IonCardContent>                      
                                    <IonGrid>
                                        <IonRow>
                                      {(props.myObj.sira) &&  <Fragment><IonCol size="3" className='gridcollabel'> SıraNo:</IonCol>     <IonCol size="3" className='gridcolval'> {props.myObj.sira} </IonCol></Fragment>}
                                      {(props.myObj.kiralikSatilik) &&  <Fragment><IonCol size="3" className='gridcollabel'> Kira/Sat</IonCol>    <IonCol size="3" className='gridcolval'> {isNullMbArray(props.myObj.kiralikSatilik,'')}  </IonCol></Fragment>}
                                      {(props.myObj.mahalle) &&  <Fragment><IonCol size="3" className='gridcollabel'>  Mahalle</IonCol>     <IonCol size="3" className='gridcolval'> {props.myObj.mahalle} </IonCol></Fragment>}
                                      {(props.myObj.sehir) &&  <Fragment><IonCol size="3" className='gridcollabel'> Şehir</IonCol>    <IonCol size="3" className='gridcolval'>  {props.myObj.sehir} </IonCol></Fragment>}
                                      {(props.myObj.durumu) &&  <Fragment><IonCol size="3" className='gridcollabel'> Durumu</IonCol>    <IonCol size="3" className='gridcolval'>{props.myObj.durumu}   </IonCol></Fragment>}
                                      {(props.myObj.fiyat) &&  <Fragment><IonCol size="3" className='gridcollabel'> Fiyat</IonCol>    <IonCol size="3" className='gridcolval'>  {props.myObj.fiyat} </IonCol></Fragment>}
                                      {(props.myObj.aidat) &&  <Fragment><IonCol size="3" className='gridcollabel'> Aidat</IonCol>     <IonCol size="3" className='gridcolval'> {props.myObj.aidat} </IonCol></Fragment>}
                                      {(props.myObj.depozito) &&  <Fragment><IonCol size="3" className='gridcollabel'> Depozito</IonCol>    <IonCol size="3" className='gridcolval'> {props.myObj.depozito}  </IonCol></Fragment>}
                                      {(props.myObj.netm2) &&  <Fragment><IonCol size="3" className='gridcollabel'> NetM2</IonCol>    <IonCol size="3" className='gridcolval'>  {props.myObj.netm2} </IonCol></Fragment>}
                                      {(props.myObj.brutm2) &&  <Fragment><IonCol size="3" className='gridcollabel'> BrütM2</IonCol>    <IonCol size="3" className='gridcolval'> {props.myObj.brutm2}  </IonCol></Fragment>}
                                      {(props.myObj.binaYasi) &&  <Fragment><IonCol size="3" className='gridcollabel'> BinaYaşı</IonCol>     <IonCol size="3" className='gridcolval'>{isNullMbArray(props.myObj.binaYasi,'')}  </IonCol></Fragment>}
                                      {(props.myObj.cephe) &&  <Fragment><IonCol size="3" className='gridcollabel'> Cephe</IonCol>     <IonCol size="3" className='gridcolval'>  {isNullMbArray(props.myObj.cephe,'')} </IonCol></Fragment>}
                                      {(props.myObj.isitmaTipi) &&  <Fragment><IonCol size="3" className='gridcollabel'> Isıtma</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.myObj.isitmaTipi,'')}  </IonCol></Fragment>}
                                      {(props.myObj.odaSayisi) &&  <Fragment><IonCol size="3" className='gridcollabel'> OdaSay.</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.myObj.odaSayisi,'')}  </IonCol></Fragment>}
                                                    
                                      {(props.myObj.katSayisi) &&  <Fragment><IonCol size="3" className='gridcollabel'> KatSay.</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.myObj.katSayisi,'')}  </IonCol></Fragment>}
                                      {(props.myObj.katDetay) &&  <Fragment><IonCol size="3" className='gridcollabel'> Kat.</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.myObj.katDetay,'')}  </IonCol></Fragment>}
                                      {(props.myObj.bulunduguKat) &&  <Fragment><IonCol size="3" className='gridcollabel'> Bul.Kat.</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.myObj.bulunduguKat,'')}  </IonCol></Fragment>}
                                      {(props.myObj.kategori) &&  <Fragment><IonCol size="3" className='gridcollabel'> Kategori</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.myObj.kategori,'')}  </IonCol></Fragment>}
                                      {(props.myObj.tapuDurumu) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Tapu</IonCol>     <IonCol size="3" className='gridcolval2'> {isNullMbArray(props.myObj.tapuDurumu,'')}  </IonCol></Fragment>}
                                      </IonRow> 
                                      <IonRow>
                                      {(props.myObj.manzara) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Manzara</IonCol>     <IonCol size="9" className='gridcolval2'> {props.myObj.manzara}  </IonCol></Fragment>}
                                      {(props.myObj.muhit) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Muhit</IonCol>     <IonCol size="9" className='gridcolval2'> {props.myObj.muhit}  </IonCol></Fragment>}
                                      {(props.myObj.ulasim) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Ulaşım</IonCol>     <IonCol size="9" className='gridcolval2'> {props.myObj.ulasim}  </IonCol></Fragment>}
                                      {(props.myObj.engelliyeUygun) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Engelli</IonCol>     <IonCol size="9" className='gridcolval2'> {props.myObj.engelliyeUygun}  </IonCol></Fragment>}
                                      {(props.myObj.disOzellik) &&  <Fragment><IonCol size="3" className='gridcollabel2'> DışÖz.</IonCol>     <IonCol size="9" className='gridcolval2'> {props.myObj.disOzellik}  </IonCol></Fragment>}
                                      {(props.myObj.icOzellik) &&  <Fragment><IonCol size="3" className='gridcollabel2'> İçÖz.</IonCol>     <IonCol size="9" className='gridcolval2'> {props.myObj.icOzellik}  </IonCol></Fragment>}

                                        </IonRow>                    
                                      </IonGrid>

                                </IonCardContent>
                    
                    </IonCard>
                    </IonCol>
                  </IonRow>
              </IonGrid>    
            </IonItem>      
            )  
     }


    //  {dataSet.map((data, index) => { return (data==props.siraNo) ?
    //   ( 
    //       (
    //       <IonCard key={index+'p8'}> 
    //       {/* <Welcome3 profile={imageAsUrlDict[data]} key={index+'w'}/> */}
    //       <IonGrid key={index+'wsigr8id'}> 
    //         <IonRow  key={index+'wsi8row'} >
    //           <SingleImage profile={imageAsUrlDict[data]} propData={propDetayDict[data]} key={index+'wsi'}/>
    //           {/* {<PropDetails2 profile={propDetayDict[data]} key={index+'wpspd2'}/>}     */}
    //         </IonRow>
    //       </IonGrid>
    //       </IonCard>       
    //   )
    //   ) : null
    //   })} 


     function Welcome3(props: any) {
        if (props.profile===undefined || props.profile === null) return null;
        let dta = Object.keys(props.profile);
        if (dta.length===0) return null;      
              return ( 
                <IonItem> 
                {Object.keys(props.profile).map((data, index) => (
                  <p key={index+'pww'}>
                    <IonThumbnail key={data} className="ionthumb">
                      <IonImg src={props.profile[data].projectlink} 
                      alt={props.profile[data].projectlink}
                      onClick={thumbClicked}
                      id={props.profile[data].propid+'_'+data+'_thumb'}
                      className="ionthumbpic"
                      />
                    </IonThumbnail>
                    </p>       
                ))}         
                </IonItem>      
                )  
     }

     function SingleImage(props: any) {
        if (props.profile===undefined || props.profile === null) return null;
        var objKeys = Object.keys(props.profile);
        if (objKeys.length===0) return null;
        //  let propDataKeys = Object.keys(props.propData);
        return ( 
         <Fragment>  
          <IonCol size="12" sizeLg="8" sizeMd="8"  sizeSm="12" sizeXs="12" >     <IonCard>   
            <IonImg src={props.profile[objKeys[0]].projectlink} 
            alt={'mainpic'+props.profile[objKeys[0]].propid} id={'mainpic'+props.profile[objKeys[0]].propid}  />   </IonCard>   
          </IonCol> 
          <IonCol className="smallimages" size="12" sizeLg="4" sizeMd="4" sizeSm="12" sizeXs="12" key={'colimg'+props.profile[objKeys[0]].propid}  >
               <IonCard>

                <IonImg src={props.profile[objKeys[objKeys.length-1]].projectlink}  alt="image" 
                key={'smallimg'+props.profile[objKeys[objKeys.length-1]].propid+'w12'} />

              <IonCardContent>                      
                   <IonLabel className="baslikLbl"> {' EMLAK DETAYLARI : '}&nbsp; &nbsp;&nbsp;{props.propData[objKeys[0]].baslik} &nbsp; &nbsp;&nbsp;{props.propData[objKeys[0]].detay}  </IonLabel>
                    <IonGrid>
                      <IonRow>
                     {(props.propData[objKeys[0]].sira) &&  <Fragment><IonCol size="3" className='gridcollabel'> SıraNo:</IonCol>     <IonCol size="3" className='gridcolval'> {props.propData[objKeys[0]].sira} </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].kiralikSatilik) &&  <Fragment><IonCol size="3" className='gridcollabel'> Kira/Sat</IonCol>    <IonCol size="3" className='gridcolval'> {isNullMbArray(props.propData[objKeys[0]].kiralikSatilik,'')}  </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].mahalle) &&  <Fragment><IonCol size="3" className='gridcollabel'>  Mahalle</IonCol>     <IonCol size="3" className='gridcolval'> {props.propData[objKeys[0]].mahalle} </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].sehir) &&  <Fragment><IonCol size="3" className='gridcollabel'> Şehir</IonCol>    <IonCol size="3" className='gridcolval'>  {props.propData[objKeys[0]].sehir} </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].durumu) &&  <Fragment><IonCol size="3" className='gridcollabel'> Durumu</IonCol>    <IonCol size="3" className='gridcolval'>{props.propData[objKeys[0]].durumu}   </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].fiyat) &&  <Fragment><IonCol size="3" className='gridcollabel'> Fiyat</IonCol>    <IonCol size="3" className='gridcolval'>  {props.propData[objKeys[0]].fiyat} </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].aidat) &&  <Fragment><IonCol size="3" className='gridcollabel'> Aidat</IonCol>     <IonCol size="3" className='gridcolval'> {props.propData[objKeys[0]].aidat} </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].depozito) &&  <Fragment><IonCol size="3" className='gridcollabel'> Depozito</IonCol>    <IonCol size="3" className='gridcolval'> {props.propData[objKeys[0]].depozito}  </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].netm2) &&  <Fragment><IonCol size="3" className='gridcollabel'> NetM2</IonCol>    <IonCol size="3" className='gridcolval'>  {props.propData[objKeys[0]].netm2} </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].brutm2) &&  <Fragment><IonCol size="3" className='gridcollabel'> BrütM2</IonCol>    <IonCol size="3" className='gridcolval'> {props.propData[objKeys[0]].brutm2}  </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].binaYasi) &&  <Fragment><IonCol size="3" className='gridcollabel'> BinaYaşı</IonCol>     <IonCol size="3" className='gridcolval'>{isNullMbArray(props.propData[objKeys[0]].binaYasi,'')}  </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].cephe) &&  <Fragment><IonCol size="3" className='gridcollabel'> Cephe</IonCol>     <IonCol size="3" className='gridcolval'>  {isNullMbArray(props.propData[objKeys[0]].cephe,'')} </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].isitmaTipi) &&  <Fragment><IonCol size="3" className='gridcollabel'> Isıtma</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.propData[objKeys[0]].isitmaTipi,'')}  </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].odaSayisi) &&  <Fragment><IonCol size="3" className='gridcollabel'> OdaSay.</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.propData[objKeys[0]].odaSayisi,'')}  </IonCol></Fragment>}
                                   
                     {(props.propData[objKeys[0]].katSayisi) &&  <Fragment><IonCol size="3" className='gridcollabel'> KatSay.</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.propData[objKeys[0]].katSayisi,'')}  </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].katDetay) &&  <Fragment><IonCol size="3" className='gridcollabel'> Kat.</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.propData[objKeys[0]].katDetay,'')}  </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].bulunduguKat) &&  <Fragment><IonCol size="3" className='gridcollabel'> Bul.Kat.</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.propData[objKeys[0]].bulunduguKat,'')}  </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].kategori) &&  <Fragment><IonCol size="3" className='gridcollabel'> Kategori</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.propData[objKeys[0]].kategori,'')}  </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].tapuDurumu) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Tapu</IonCol>     <IonCol size="3" className='gridcolval2'> {isNullMbArray(props.propData[objKeys[0]].tapuDurumu,'')}  </IonCol></Fragment>}
                     </IonRow> 
                     <IonRow>
                     {(props.propData[objKeys[0]].manzara) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Manzara</IonCol>     <IonCol size="9" className='gridcolval2'> {props.propData[objKeys[0]].manzara}  </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].muhit) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Muhit</IonCol>     <IonCol size="9" className='gridcolval2'> {props.propData[objKeys[0]].muhit}  </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].ulasim) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Ulaşım</IonCol>     <IonCol size="9" className='gridcolval2'> {props.propData[objKeys[0]].ulasim}  </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].engelliyeUygun) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Engelli</IonCol>     <IonCol size="9" className='gridcolval2'> {props.propData[objKeys[0]].engelliyeUygun}  </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].disOzellik) &&  <Fragment><IonCol size="3" className='gridcollabel2'> DışÖz.</IonCol>     <IonCol size="9" className='gridcolval2'> {props.propData[objKeys[0]].disOzellik}  </IonCol></Fragment>}
                     {(props.propData[objKeys[0]].icOzellik) &&  <Fragment><IonCol size="3" className='gridcollabel2'> İçÖz.</IonCol>     <IonCol size="9" className='gridcolval2'> {props.propData[objKeys[0]].icOzellik}  </IonCol></Fragment>}

                      </IonRow>                    
                    </IonGrid>

              </IonCardContent>
                </IonCard>             
               
          </IonCol> 
          </Fragment>
          )  
 }

// profile 1 sayı geliyor.  item 1 sayı geliyor.   Object.keys(props.propList[profile][profile]).map((item: any) =>  )   
  return (
    <IonContent  className="container">
      {props.propList && Object.keys(props.propList).map((profile: any) => {  //let str = JSON.stringify(props.propList[profile][profile]);
                if (props.search === '') {
                    return propertyShortLine(profile);                              
                } else {
                    let strF = () => Object.keys(props.propList[profile][profile]).map(item =>  props.propList[profile][profile][item]);
                    let str = strF().toString().toLowerCase();
                    let searchArr = props.search.trim().toLowerCase().split(' ');
                    if ((searchArr[0] && str.includes(searchArr[0])) ||(searchArr[1] && str.includes(searchArr[1])) || (searchArr[2] && str.includes(searchArr[2])) || (searchArr[3] && str.includes(searchArr[3]))  )
                    return  propertyShortLine(profile);
                }
            } 
        )}
    </IonContent> 
  )
}

export default DList;





// // single line
// <IonItem key="thumbbigg">
// {imgs.map((data: any, index: number) => 

//     <IonThumbnail key={data+'it'} className="ionthumbbigger">
//         <IonImg src={imageAsUrlDict[profile][data].projectlink} 
//         alt={imageAsUrlDict[profile][data].projectlink}
//         onClick={thumbClicked} 
//         id={imageAsUrlDict[profile][data].propid+'_'+data+'_thumb'}  className="ionthumbpic"
//         key={'smalsdslimg'+imageAsUrlDict[profile][data].propid+'w12wq'}
//         />
//     </IonThumbnail>             
// )}                            
//    </IonItem>



//     <IonGrid>
//         <IonRow>





//    <IonCol size="12" sizeLg="8" sizeMd="8"  sizeSm="12" sizeXs="12" >    
//    <IonCard key="mainimagedlist">  <IonLabel></IonLabel>

//         {imgs.map((data: any, index: number) => 
//         (index===0) ? <IonLabel>{data}{index}</IonLabel>

//          : null          
//     )} 



// </IonCard>   
// </IonCol> 
// <IonCol className="smallimages" size="12" sizeLg="4" sizeMd="4" sizeSm="12" sizeXs="12" key={'colimg'+imgs.propid}  >





//    <IonCard  key={'smallimg'+imgs.propid+'w12wewq'} >


//    {imgs.map((data: any, index: number) => 
//         (index===imgs.length-1) ? 
//         <IonImg src={imageAsUrlDict[profile][data].projectlink}  alt="image" 
//         key={'smallimg'+imageAsUrlDict[profile][data].propid+'w12wq'} />  : null           
//     )}  




// <IonCardContent>                      
// {/* <IonLabel className="baslikLbl"> {' EMLAK DETAYLARI : '}&nbsp; &nbsp;&nbsp;{myObj.baslik} &nbsp; &nbsp;&nbsp;{myObj.detay}  </IonLabel> */}



// <IonGrid>
// <IonRow>
// <IonCol size="6" className='gridcollabel'>  
// <IonLabel className="baslikLbl"> {' EMLAK DETAYLARI : '}&nbsp; &nbsp;&nbsp;{myObj.baslik} &nbsp; &nbsp;&nbsp;{myObj.detay}  </IonLabel>
// </IonCol>

// {(myObj.sira) &&  <Fragment><IonCol size="3" className='gridcollabel'> SıraNo:</IonCol>     <IonCol size="3" className='gridcolval'> {myObj.sira} </IonCol></Fragment>}
// {(myObj.kiralikSatilik) &&  <Fragment><IonCol size="3" className='gridcollabel'> Kira/Sat</IonCol>    <IonCol size="3" className='gridcolval'> {isNullMbArray(myObj.kiralikSatilik,'')}  </IonCol></Fragment>}
// {(myObj.mahalle) &&  <Fragment><IonCol size="3" className='gridcollabel'>  Mahalle</IonCol>     <IonCol size="3" className='gridcolval'> {myObj.mahalle} </IonCol></Fragment>}
// {(myObj.sehir) &&  <Fragment><IonCol size="3" className='gridcollabel'> Şehir</IonCol>    <IonCol size="3" className='gridcolval'>  {myObj.sehir} </IonCol></Fragment>}
// {(myObj.durumu) &&  <Fragment><IonCol size="3" className='gridcollabel'> Durumu</IonCol>    <IonCol size="3" className='gridcolval'>{myObj.durumu}   </IonCol></Fragment>}
// {(myObj.fiyat) &&  <Fragment><IonCol size="3" className='gridcollabel'> Fiyat</IonCol>    <IonCol size="3" className='gridcolval'>  {myObj.fiyat} </IonCol></Fragment>}
// {(myObj.aidat) &&  <Fragment><IonCol size="3" className='gridcollabel'> Aidat</IonCol>     <IonCol size="3" className='gridcolval'> {myObj.aidat} </IonCol></Fragment>}
// {(myObj.depozito) &&  <Fragment><IonCol size="3" className='gridcollabel'> Depozito</IonCol>    <IonCol size="3" className='gridcolval'> {myObj.depozito}  </IonCol></Fragment>}
// {(myObj.netm2) &&  <Fragment><IonCol size="3" className='gridcollabel'> NetM2</IonCol>    <IonCol size="3" className='gridcolval'>  {myObj.netm2} </IonCol></Fragment>}
// {(myObj.brutm2) &&  <Fragment><IonCol size="3" className='gridcollabel'> BrütM2</IonCol>    <IonCol size="3" className='gridcolval'> {myObj.brutm2}  </IonCol></Fragment>}
// {(myObj.binaYasi) &&  <Fragment><IonCol size="3" className='gridcollabel'> BinaYaşı</IonCol>     <IonCol size="3" className='gridcolval'>{isNullMbArray(myObj.binaYasi,'')}  </IonCol></Fragment>}
// {(myObj.cephe) &&  <Fragment><IonCol size="3" className='gridcollabel'> Cephe</IonCol>     <IonCol size="3" className='gridcolval'>  {isNullMbArray(myObj.cephe,'')} </IonCol></Fragment>}
// {(myObj.isitmaTipi) &&  <Fragment><IonCol size="3" className='gridcollabel'> Isıtma</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(myObj.isitmaTipi,'')}  </IonCol></Fragment>}
// {(myObj.odaSayisi) &&  <Fragment><IonCol size="3" className='gridcollabel'> OdaSay.</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(myObj.odaSayisi,'')}  </IonCol></Fragment>}
           
// {(myObj.katSayisi) &&  <Fragment><IonCol size="3" className='gridcollabel'> KatSay.</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(myObj.katSayisi,'')}  </IonCol></Fragment>}
// {(myObj.katDetay) &&  <Fragment><IonCol size="3" className='gridcollabel'> Kat.</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(myObj.katDetay,'')}  </IonCol></Fragment>}
// {(myObj.bulunduguKat) &&  <Fragment><IonCol size="3" className='gridcollabel'> Bul.Kat.</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(myObj.bulunduguKat,'')}  </IonCol></Fragment>}
// {(myObj.kategori) &&  <Fragment><IonCol size="3" className='gridcollabel'> Kategori</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(myObj.kategori,'')}  </IonCol></Fragment>}
// {(myObj.tapuDurumu) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Tapu</IonCol>     <IonCol size="3" className='gridcolval2'> {isNullMbArray(myObj.tapuDurumu,'')}  </IonCol></Fragment>}
// </IonRow> 
// <IonRow>
// {(myObj.manzara) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Manzara</IonCol>     <IonCol size="9" className='gridcolval2'> {myObj.manzara}  </IonCol></Fragment>}
// {(myObj.muhit) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Muhit</IonCol>     <IonCol size="9" className='gridcolval2'> {myObj.muhit}  </IonCol></Fragment>}
// {(myObj.ulasim) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Ulaşım</IonCol>     <IonCol size="9" className='gridcolval2'> {myObj.ulasim}  </IonCol></Fragment>}
// {(myObj.engelliyeUygun) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Engelli</IonCol>     <IonCol size="9" className='gridcolval2'> {myObj.engelliyeUygun}  </IonCol></Fragment>}
// {(myObj.disOzellik) &&  <Fragment><IonCol size="3" className='gridcollabel2'> DışÖz.</IonCol>     <IonCol size="9" className='gridcolval2'> {myObj.disOzellik}  </IonCol></Fragment>}
// {(myObj.icOzellik) &&  <Fragment><IonCol size="3" className='gridcollabel2'> İçÖz.</IonCol>     <IonCol size="9" className='gridcolval2'> {myObj.icOzellik}  </IonCol></Fragment>}



// </IonRow>                    
// </IonGrid>


// </IonCardContent>
// </IonCard> 
// </IonCol>
// </IonRow>
//     </IonGrid>






// 104
    // {/*                              <IonImg src={imageAsUrlDict[profile][data].projectlink} 
    //                             alt={'mainpic'+imageAsUrlDict[profile][data].propid} 
    //                             id={'mainpic'+imageAsUrlDict[profile][data].propid} 
    //                key={'smaererllimg'+imageAsUrlDict[profile][data].propid+'w12wq'}
    //                             />   */}    

// <IonList>
// {propDetay.map((profile: any) =>    
//           Object.keys(profile['detail).map((item: any) => (   
                //   Object.keys(imageAsUrlDict[item]).map((data: any, index: number) => (
                //           (index===0) ?         
                //     <IonItem key={data+'iii'}>
                //     <IonAvatar key={data} className="ionthumbavatar">
                //       <IonImg src={imageAsUrlDict[item][data].projectlink} 
                //       alt={imageAsUrlDict[item][data].projectlink}
                //       onClick={props.thumbClicked}
                //       id={imageAsUrlDict[item][data].propid+'_'+data+'_thumb'}  className="ionthumbpic"
                //       />
                //     </IonAvatar>
                //   <IonLabel key={data+'il'}> {' ('+item + ') : '}
                //   {propDetayDict[item][item].baslik + ' : ' + propDetayDict[item][item].detay+' : '+propDetayDict[item][item].sehir}
                //    </IonLabel>
                //   </IonItem> : null
                //   ))
//         ))        
//   )}
// </IonList> 

// function PropDetails2(props) {
//     if (props.profile===undefined || props.profile === null) return null;
//     let dta = Object.keys(props.profile);
//     if (dta.length===0) return null;
//               return ( 
//                 <IonCol size="6" /*key={'iwdidd'}   sizeLg="4" offsetLg="2" sizeXl="6" offsetXl="2"*/ > 
//                 {Object.keys(props.profile).map((data, index) => (
//                   <div key={index+'iwdid'} className="propdetailhead propdetailsdiv">
                   
//                     <IonLabel  className="txtsegment propid" key={index+'iwd5iwe'}>{'Emlak No : '+data+''}</IonLabel> <hr/>
//                     <IonLabel  className="txtsegment baslik" key={index+'i5i'}>{'Başlık : '+props.profile[data].baslik+' - '} </IonLabel> <hr/>
//                     <IonLabel  className="txtsegment detay" key={index+'i5ibb'}>{'Detay : '+props.profile[data].detay}</IonLabel> <hr/>
  
//                     <IonLabel  className="txtsegment detay" key={index+'ii55bb'}>{'Fiyat : '+props.profile[data].fiyat}</IonLabel> <hr/>
//                     <IonLabel  className="txtsegment detay" key={index+'i444ibb'}>{'Aidat : '+props.profile[data].aidat}</IonLabel> <hr/>
//                     <IonLabel  className="txtsegment detay" key={index+'iib4b'}>{'Depozito : '+props.profile[data].depozito}</IonLabel> <hr/>                  
//                     <IonLabel  className="txtsegment detay" key={index+'ii555bb'}>{'Durum : '+props.profile[data].durumu}</IonLabel> <hr/>
//                     <IonLabel  className="txtsegment detay" key={index+'i4ibb'}>{'Netm2 : '+props.profile[data].netm2}</IonLabel> <hr/>
//                     <IonLabel  className="txtsegment detay" key={index+'i44ibb'}>{'Brutm2 : '+props.profile[data].brutm2}</IonLabel> <hr/>
  
//                     <IonLabel  className="txtsegment detay" key={index+'iib44b'}>{'Mahalle : '+props.profile[data].mahalle}</IonLabel> <hr/>
//                     <IonLabel  className="txtsegment detay" key={index+'ii575bb'}>{'Sehir : '+props.profile[data].sehir}</IonLabel> <hr/>
//                     <IonLabel  className="txtsegment detay" key={index+'i55ibb'}>{'Detay : '+props.profile[data].bostarih}</IonLabel> <hr/>
    
//                     </div>
//                 ))}
//                 </IonCol>      
//                 )  
//   }

//   function SingleImage(props) {
//     if (props.profile===undefined || props.profile === null) return null;
//     var objKeys = Object.keys(props.profile);
//     // console.log(" objKeys : "+JSON.stringify(objKeys)); // resimler var burada 
//     if (objKeys.length===0) return null;
//     let propDataKeys = Object.keys(props.propData);
//    //  console.log(" propDataKeys : "+JSON.stringify(props.propData[propDataKeys])); //  propDataKeys : ["4"]
//     return ( 
//  <Fragment>  
//       <IonCol size="12" sizeLg="8" sizeMd="8"  sizeSm="12" sizeXs="12" >     <IonCard>   
//         <IonImg src={props.profile[objKeys[0]].projectlink} 
//         alt={'mainpic'+props.profile[objKeys[0]].propid} id={'mainpic'+props.profile[objKeys[0]].propid}  />   </IonCard>   
//       </IonCol> 
//       <IonCol className="smallimages" size="12" sizeLg="4" sizeMd="4" sizeSm="12" sizeXs="12" key={'colimg'+props.profile[objKeys[0]].propid}  >

//            <IonCard>


//             <IonImg src={props.profile[objKeys[objKeys.length-1]].projectlink}  alt="image" 
//             key={'smallimg'+props.profile[objKeys[objKeys.length-1]].propid+'w12'} />

//           <IonCardContent>                      
//                <IonLabel className="baslikLbl"> {' EMLAK DETAYLARI : '}&nbsp; &nbsp;&nbsp;{props.propData[propDataKeys].baslik} &nbsp; &nbsp;&nbsp;{props.propData[propDataKeys].detay}  </IonLabel>
//                 <IonGrid>
//                   <IonRow>
//                  {(props.propData[propDataKeys].sira) &&  <Fragment><IonCol size="3" className='gridcollabel'> SıraNo:</IonCol>     <IonCol size="3" className='gridcolval'> {props.propData[propDataKeys].sira} </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].kiralikSatilik) &&  <Fragment><IonCol size="3" className='gridcollabel'> Kira/Sat</IonCol>    <IonCol size="3" className='gridcolval'> {isNullMbArray(props.propData[propDataKeys].kiralikSatilik,'')}  </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].mahalle) &&  <Fragment><IonCol size="3" className='gridcollabel'>  Mahalle</IonCol>     <IonCol size="3" className='gridcolval'> {props.propData[propDataKeys].mahalle} </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].sehir) &&  <Fragment><IonCol size="3" className='gridcollabel'> Şehir</IonCol>    <IonCol size="3" className='gridcolval'>  {props.propData[propDataKeys].sehir} </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].durumu) &&  <Fragment><IonCol size="3" className='gridcollabel'> Durumu</IonCol>    <IonCol size="3" className='gridcolval'>{props.propData[propDataKeys].durumu}   </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].fiyat) &&  <Fragment><IonCol size="3" className='gridcollabel'> Fiyat</IonCol>    <IonCol size="3" className='gridcolval'>  {props.propData[propDataKeys].fiyat} </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].aidat) &&  <Fragment><IonCol size="3" className='gridcollabel'> Aidat</IonCol>     <IonCol size="3" className='gridcolval'> {props.propData[propDataKeys].aidat} </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].depozito) &&  <Fragment><IonCol size="3" className='gridcollabel'> Depozito</IonCol>    <IonCol size="3" className='gridcolval'> {props.propData[propDataKeys].depozito}  </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].netm2) &&  <Fragment><IonCol size="3" className='gridcollabel'> NetM2</IonCol>    <IonCol size="3" className='gridcolval'>  {props.propData[propDataKeys].netm2} </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].brutm2) &&  <Fragment><IonCol size="3" className='gridcollabel'> BrütM2</IonCol>    <IonCol size="3" className='gridcolval'> {props.propData[propDataKeys].brutm2}  </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].binaYasi) &&  <Fragment><IonCol size="3" className='gridcollabel'> BinaYaşı</IonCol>     <IonCol size="3" className='gridcolval'>{isNullMbArray(props.propData[propDataKeys].binaYasi,'')}  </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].cephe) &&  <Fragment><IonCol size="3" className='gridcollabel'> Cephe</IonCol>     <IonCol size="3" className='gridcolval'>  {isNullMbArray(props.propData[propDataKeys].cephe,'')} </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].isitmaTipi) &&  <Fragment><IonCol size="3" className='gridcollabel'> Isıtma</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.propData[propDataKeys].isitmaTipi,'')}  </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].odaSayisi) &&  <Fragment><IonCol size="3" className='gridcollabel'> OdaSay.</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.propData[propDataKeys].odaSayisi,'')}  </IonCol></Fragment>}
                               
//                  {(props.propData[propDataKeys].katSayisi) &&  <Fragment><IonCol size="3" className='gridcollabel'> KatSay.</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.propData[propDataKeys].katSayisi,'')}  </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].katDetay) &&  <Fragment><IonCol size="3" className='gridcollabel'> Kat.</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.propData[propDataKeys].katDetay,'')}  </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].bulunduguKat) &&  <Fragment><IonCol size="3" className='gridcollabel'> Bul.Kat.</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.propData[propDataKeys].bulunduguKat,'')}  </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].kategori) &&  <Fragment><IonCol size="3" className='gridcollabel'> Kategori</IonCol>     <IonCol size="3" className='gridcolval'> {isNullMbArray(props.propData[propDataKeys].kategori,'')}  </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].tapuDurumu) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Tapu</IonCol>     <IonCol size="3" className='gridcolval2'> {isNullMbArray(props.propData[propDataKeys].tapuDurumu,'')}  </IonCol></Fragment>}
//                  </IonRow> 
//                  <IonRow>
//                  {(props.propData[propDataKeys].manzara) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Manzara</IonCol>     <IonCol size="9" className='gridcolval2'> {props.propData[propDataKeys].manzara}  </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].muhit) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Muhit</IonCol>     <IonCol size="9" className='gridcolval2'> {props.propData[propDataKeys].muhit}  </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].ulasim) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Ulaşım</IonCol>     <IonCol size="9" className='gridcolval2'> {props.propData[propDataKeys].ulasim}  </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].engelliyeUygun) &&  <Fragment><IonCol size="3" className='gridcollabel2'> Engelli</IonCol>     <IonCol size="9" className='gridcolval2'> {props.propData[propDataKeys].engelliyeUygun}  </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].disOzellik) &&  <Fragment><IonCol size="3" className='gridcollabel2'> DışÖz.</IonCol>     <IonCol size="9" className='gridcolval2'> {props.propData[propDataKeys].disOzellik}  </IonCol></Fragment>}
//                  {(props.propData[propDataKeys].icOzellik) &&  <Fragment><IonCol size="3" className='gridcollabel2'> İçÖz.</IonCol>     <IonCol size="9" className='gridcolval2'> {props.propData[propDataKeys].icOzellik}  </IonCol></Fragment>}


                  
//                   </IonRow>                    
//                 </IonGrid>

           

//           </IonCardContent>
//             </IonCard>             
           
//       </IonCol> 
//       </Fragment>
//       )  
// }
